.insights {
    overflow: hidden;
    min-height: 30vh;
    &.secondary-navbar {
        .navbar-nav {
            margin-left: 32.7vw;
        }
    }
    .event-precursor{
        .export-content {
            .dropdown-menu{
                .dropdown-item:first-child {
                    border-bottom: transparent !important;
                }
            }
        } 
        .date-icon{
            padding-top: 0.55rem !important;
            padding-bottom: 0.636rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            border-radius: 0 0.3vw 0.3vw 0;
        }
        .vh-45{
            min-height: 45vh;
        }
        .picky{
            width: 100% !important;
        }
        .badge-position{
            position: absolute;
            background: #C53E3E;
            top: 0;
            width: 1.9vh;
            height: 1.9vh;
            padding: 0.12rem 0.38rem 0.12rem 0.38rem;
            right: 3vw;
            border-radius: 50%;
            color: #fff;
            font-size: 1.3vh;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .chartBtn{
            top:"-15px";
            span{
                cursor: pointer;
                color: #d98c14;
                &:hover{
                    text-decoration: underline;
                  }
            }
        }
        .no-disp-text{
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            height: 45vh !important;
            line-height: 0 !important;
        }
        .btn-outline-secondary{
            color: #000 !important;
            border: 1px solid #ccc !important;
            &:hover {
                background: #ccc !important;
                }
        }
        .fa-plus-circle,.fa-eye{cursor: pointer;}
    }
    .msg-succ{
        div{
            margin-bottom:-25px !important;
        }
    }
}
.modal{
    .event-precursor-modal{
        width: 80vw;
        max-width: 80vw;
        .modal-body{
            min-height: 30vh;
            max-height: 30vh;
            padding: 0.8vh 2.5vh 0;
            .event-precursor-table{
                .no-disp-text{
                    line-height: 25vh !important;
                }
            }
            .rt-table{
                border: .1px solid #bdbbbb;
                .rt-thead{
                    position: sticky;
                    top: 0;
                }
                .rt-tbody{
                    max-height: 22vh;
                }
            }
        }
    }
    .site-event-precursor-modal{
        width: 80vw;
        max-width: 80vw;
        .h-80{
            height: 80vh !important;
        }
        .gm-style-iw-chr{
            height: 10px !important;
        }
        .gm-style-iw-d{
            margin: 15px !important;
        }
        .ep-map-tooltip-title{
            margin: 10px 0px;
            font-weight: bold;
            font-size: 14px;
        }
        .ep-map-table{
                border: 1px solid #bdbbbb;
                font-size: 12px;
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;
                th,td{
                    text-align: center;
                    border: 1px solid #bdbbbb !important;
                    padding: 5px;
                }
                th{
                    background-color: #E5E5E5;
                }
        }
        .ep-map-link{
            color: #D98C15;
            cursor: pointer;
            margin: 2px 0px;
            padding: 0 9px;
        }
        .tooltip-links{
            color: #D98C15;
            margin:2px 0px;
            padding: 0 9px;
            border-right:1px solid grey;
        }
    
        .txt-color {
            color: #D98C15;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .addEventPrecrsorModal{
        .date-icon{
            padding: 4px 6px;
            border: 1px solid #ccc;
            &:hover{
                background-color: #ccc;
            }
        }
    }
}
#popover-positioned-event-precursor-helptext{
    max-width: 40vw !important;
    svg{
        position: relative;
        top: -1px;
    }
}