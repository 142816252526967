.site-order-modal {
  max-width: 90vw !important;
}
.site-order-modal .modal-header .popup-title {
  position: relative;
}
.site-order-modal .modal-header .edit-lateral-name {
  display: inline-block;
}
.site-order-modal .modal-header .edit-lateral-name .lateral-name-text-wrapper {
  display: inline-block;
}
.site-order-modal .modal-header .edit-lateral-name .lateral-name-text-wrapper .err-border-red {
  border: 1px solid #a94442 !important;
}
.site-order-modal .modal-header .edit-lateral-name .lateral-name-error {
  color: #a94442;
  display: table-cell;
  font-weight: normal;
  font-size: 0.7vw;
}
.site-order-modal .modal-header .fa-pencil-alt {
  cursor: pointer;
}
.site-order-modal .modal-header .fa-times-circle {
  cursor: pointer;
  margin-left: 10px;
  height: 1.2em;
  width: 1.2em;
}
.site-order-modal .modal-header .fa-check-circle {
  cursor: pointer;
  color: #bf7400;
  margin-left: 10px;
  height: 1.2em;
  width: 1.2em;
}
.site-order-modal .modal-header .fa-check-circle.disable {
  pointer-events: none;
  opacity: 0.5;
}
.site-order-modal .modal-body {
  min-height: 20vh;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
}
.site-order-modal .modal-body .site-list {
  padding: 5px;
  width: 100%;
}
.site-order-modal .modal-body .site-list .site-row {
  background-color: #ffffff;
  border: none;
  margin: 0;
  padding: 7px 10px;
}
.site-order-modal .modal-body .site-list .site-row:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.site-order-modal .modal-body .site-list .site-row.normal-open {
  background-color: #00a877;
}
.site-order-modal .modal-body .site-list .site-row .site-name {
  padding-top: 5px;
  width: 15%;
}
.site-order-modal .modal-body .site-list .site-row .section-id {
  width: 12%;
}
.site-order-modal .modal-body .site-list .site-row .section-id input {
  width: 75%;
}
.site-order-modal .modal-body .site-list .site-row .current-direction {
  width: 7%;
}
.site-order-modal .modal-body .site-list .site-row .cable-profile {
  width: 10%;
}
.site-order-modal .modal-body .site-list .site-row .cable-profile .dropdown {
  width: 70%;
}
.site-order-modal .modal-body .site-list .site-row .cable-profile .single-select-dropdown .dropdown {
  height: 1.9vw;
}
.site-order-modal .modal-body .site-list .site-row .cable-profile .single-select-dropdown .dropdown .dropdown-toggle {
  height: 100%;
}
.site-order-modal .modal-body .site-list .site-row .cable-profile .single-select-dropdown .dropdown .dropdown-toggle[disabled] {
  color: black !important;
}
.site-order-modal .modal-body .site-list .site-row .cable-length {
  width: 11%;
}
.site-order-modal .modal-body .site-list .site-row .cable-length input {
  width: 75%;
}
.site-order-modal .modal-body .site-list .site-row .cable-length .site-error-message {
  padding-right: 2vw;
}
.site-order-modal .modal-body .site-list .site-row .resistance-impedance {
  width: 9.5%;
}
.site-order-modal .modal-body .site-list .site-row .resistance-impedance input {
  width: 85%;
}
.site-order-modal .modal-body .site-list .site-row .reactance {
  margin-left: 1.4vw;
  width: 9%;
}
.site-order-modal .modal-body .site-list .site-row .reactance input {
  width: 90%;
}
.site-order-modal .modal-body .site-list .site-row .no-of-customers {
  margin-left: 1.8vw;
  width: 18%;
}
.site-order-modal .modal-body .site-list .site-row .no-of-customers input {
  width: 100%;
}
.site-order-modal .modal-body .site-list .site-row input {
  padding-left: 10px;
}
.site-order-modal .modal-body .site-list .site-row .site-error-message {
  font-size: 0.7vw;
}
.site-order-modal .modal-body .site-list .site-row .fa-grip-vertical {
  margin: 5px 10px 0 0;
}
.site-order-modal .modal-body .site-list .site-row .row-item.disabled {
  visibility: hidden;
}
.site-order-modal .modal-body .site-list .site-row .row-item.err-border-red .dropdown-toggle, .site-order-modal .modal-body .site-list .site-row .row-item.err-border-red input {
  border: 1px solid #a94442 !important;
}
.site-order-modal .modal-body .site-list .site-row .svg-node {
  height: 17px;
  margin: 0.25vw 0.2vw 0 1vw;
  width: 17px;
}
.site-order-modal .modal-body .site-list .site-row .drag-icon-wrapper {
  margin-left: -5px;
  margin-right: 15px;
  position: relative;
}
.site-order-modal .modal-body .site-list .site-row .drag-icon-wrapper .drag-icon {
  position: absolute;
}
.site-order-modal .modal-body .site-list .site-row .drag-icon-wrapper .drag-icon.fa-caret-up {
  left: 5px;
  width: 6px;
}
.site-order-modal .modal-body .site-list .site-row .drag-icon-wrapper .drag-icon.fa-grip-lines {
  margin-top: 5px;
  width: 17px;
}
.site-order-modal .modal-body .site-list .site-row .drag-icon-wrapper .drag-icon.fa-caret-down {
  margin-top: 16px;
  left: 5px;
  width: 6px;
}
.site-order-modal .modal-body .col-item {
  font-weight: bold;
}
.site-order-modal .modal-body .col-item.site-name {
  margin-left: 2.7vw;
  width: 12%;
}
.site-order-modal .modal-body .col-item.current-direction {
  width: 10.7%;
}
.site-order-modal .modal-body .col-item.section-id {
  width: 11.7%;
}
.site-order-modal .modal-body .col-item.cable-profile {
  width: 9.5%;
}
.site-order-modal .modal-body .col-item.cable-length {
  margin-left: 5px;
  width: 8%;
}
.site-order-modal .modal-body .col-item.phase-impedance {
  width: 20%;
}
.site-order-modal .modal-body .col-item.resistance-impedance {
  margin-left: 2.5vw;
  width: 11%;
}
.site-order-modal .modal-body .col-item.reactance {
  width: 10.8%;
}
.site-order-modal .modal-body .col-item.no-of-customers {
  width: 17%;
}
.site-order-modal .error-class {
  border: 1px solid red;
  border-radius: 2px;
}

