
@import "./resources/css/variables.scss";

* {
  margin: 0;
  padding: 0;
}
  
html, body {
  height: 100%;
  font-family: $fontfamily !important;
  font-size: .8vw !important;
  color: #333 !important;
  font-size-adjust: none;
  -webkit-text-size-adjust: none;
}
.ReactTable{
  .rt-thead .rt-th , .rt-tbody .rt-td{
    font-size: .694vw !important;
  }
}

#root {
  height: 100%;
}

@mixin positionAbsoluteCenter{
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
}

.container, .container-fluid {
  min-height: 100%;
  
  .main {
    padding-bottom: 10vh;

    .content-view{
      position: relative;
      //top: 7.43vh;
      left: 0px;
      clear: both;
      margin: 0px;
      padding: 0px;
      padding-left: 1.03vh;
      padding-right: 1.03vh;
      height: calc(100% - 8.25vh);
    }
  }
}

.content-height{
  height: 86vh;
}
.with-breadcrumb{
  margin-top: 3.3vh;
}

.help-texts {
  height: 5vh;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  span:first-child {
    font-size: 17px;
  }
}

.bg-grey {
  position: absolute;
  //height: 85vh;
  background: #DADADA;
}

.footer {
  //height: 4vh;
  margin-top: -2.5vh;
  .float-right {
    margin-right: 1vh;
  }
}

.center-element{
  @include positionAbsoluteCenter;
  z-index: 999;
}

.time-filter-btn{
  padding-top: ($spacer * .25) !important;
  padding-bottom: ($spacer * .25) !important;
  padding-left: ($spacer * .5) !important;
  padding-right: ($spacer * .5) !important;
  cursor: pointer;
}

.time-filter-btn:hover{
  background: #d98c14;
}
.time-filter-btn{
    text-align: right;
    background: #fff;
    color: #333;
    display: inline-flex;
    border: 0.15vh solid #cccccc;
    border-radius: 4px;
}
.time-filter-btn:not(:last-child){
  border-right: none !important;
}

.hover-underline{
  color: #d98c14;
}
.hover-underline:hover{
  text-decoration: underline;
  cursor: pointer;
}

.button{
  cursor: pointer;
  outline: none;
  border: none;
}

.blank-page {
  text-align: center;
  height: 100%;
  padding: 7vh;
}

.blank-page span{
    @include positionAbsoluteCenter;
    left: 48%;
    z-index: 9;
}

.position-relative{
  position: relative;
}

.text-align-center {
  text-align: center;
}
.cursor-pointer{
  cursor: pointer;
}
.tooltip{
  padding: .5vh;
  line-height: 1.5vh;
  pointer-events: none;
}
.custom-pagination{
  margin: 1.3vh 0!important;
  .pagination-btn{
    cursor: pointer;
    color: #d98c14;
    margin-left: 2.2vw;
  }
  .pagination-disable-btn{
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
    margin-left: 2.2vw;
  }
}

.reset-custom-pagination {
  margin: unset !important;
  height: unset !important;
}

.search-and-select{
    .dropdown-icon{
      display: flex;
      &::after{
        position: absolute;
        top: 0.2vh;
        right: 0.8vw;
        z-index: 9;
        content: " \25BC";
        float: right;
        font-size: 0.75em;
        margin-top: 0.4vh;
    }
    }
    .select-list-section{
      max-height: 30vh;
      width: 97%;
      position: absolute;
      top: 3.2vh;
      z-index: 999999;
      border: 1px solid #ced4da;
      border-radius: 0.5vh;;
      outline: none;
    }
    .select-list-item{
      padding: 0.4vh 2.5vh;
      cursor: pointer;
      &:hover{
        background:#e7e7e7;
      }
    }
}

.sort-number{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.2vh;
  width: 2.2vh;
  border-radius: 50%;
  background: #007bff;
  color: white;
  font-size: 1.5vh;
  font-weight: normal;
}

.form-err-border-red{
    border: 1px solid #a94442!important;
  }
  
  .form-err-color-red{
    color: #a94442!important;
  }

.disabled-toggle{
    pointer-events: none;
    cursor:not-allowed !important;
    opacity: 0.7;
  }
  .toggle-switch {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ccc;
    overflow: hidden;
    
    .switch-on{
        left: 0!important;
    }
    .switch-off{
      left: -50%!important;
    }
    .toggle-switch-animate{
        position: relative;
        width: 150%;
        -webkit-transition: left .5s;
        .span {
            display: inline-block;
            text-align: center;
            width: 33%;
            padding: 0.6vh;
            white-space: nowrap;
          }
        .switch-left {
            background: #D98C15;
            color: #333333;
        }
        .switch-right {
            color: #333;
            background: #f0f0f0
        }
        .switch-mdl {
            border-left: 0.1vh solid #ccc;
            border-right: 0.1vh solid #ccc;
            background-color: #f5f5f5;
            width: 34%;
        }
    }
  }   
// ========================= custom Tooltip
.customTooltip {
  position: relative;
  display: inline-flex!important;
    &:hover{
      .tooltiptext{
        visibility: visible;
        z-index: 999999;
      }
  }
  .tooltiptext {
    visibility: hidden;
    line-height: normal;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.6vh 1vh;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 0%;
      &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
  }
}

div#action{
  bottom: 1.5vh !important;
}
div#mainMenu{
  top: 1vh !important;
}


.checkbox-tree{
  .tree-with-checkbox{
    padding-left: 0 !important;
    padding-right: .4vw !important;
  }
  .rstcustom__rowWrapper:hover { opacity: 1 !important}
  .rstcustom__lineBlock{
    width: 1.2vw !important;
  }
  .rstcustom__lineBlock:first-child{
    width: .5vw !important;
  }
  .rstcustom__row{
    width: 100%;
    height: 15px;
  }
  .rstcustom__row .rstcustom__rowLabel{
  width: 100%;
  // padding: 0 .3vh;
  }
  .WcheckBox{
      padding-right: 2vh;
  }
  .ReactVirtualized__List{
      padding-right: 1vh
  }
  .watchListItemPlusMinus{
    padding: .5vh 0 0 0;
    font-size: 1vh;
    border: 0;
    background: rgb(255, 255, 255);
    outline: none;
    float: left;
  }
  .watchListItemIcon{
    border: 0;
    padding: 0 0 0 .5vw;
    background: rgb(255, 255, 255);
    outline: none;
    
    .svg-node {
      height: 15px;
      width: 15px;
      margin-top: -0.5vh;
    } 

  }
  .watchListItemIconSite{
    border: 0;
    outline: none;
    
    .svg-node {
      height: 15px;
      width: 15px;
      margin-top: -0.5vh;
    } 

  }
  .checkboxList{
    position: absolute!important;
    right: 0!important; 
  }
  .label-deleted{
    font-style: italic;
    color: rgb(140, 140, 140)!important;
    font-size: 1.3vh;
  }
}
.cursor-not-allowed{
  cursor: not-allowed !important;
  img{
    opacity: 0.3;
  }
}
.alert{
  width: fit-content;
 padding-top: .4rem !important;
 padding-bottom: .4rem !important;
}
.alert-dismissible .close {
  padding: .4rem 1rem .4rem 0rem !important;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.onhvr-expnd-clps2{
  // position: absolute;
  // right: 0;
  // top : 50%;
  // z-index: 999;
  // background: #ddd;
  cursor: pointer;
  padding: 1.4vh 1vh;
  display: inline-block;
  background-color: #DDDDDD;
  border: 0.1vh solid #c3c3c3;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999;
}
.modalSaveBtn {
  outline: none!important;
  color: #333333 !important;
  background-color: #D98C15 !important;
  border-color: #BF7400 !important;
  &:focus{
    outline: none!important;
    color: #333333 !important;
    background-color: #D98C15 !important;
    border-color: #BF7400 !important;
    box-shadow: none!important;
  }
  &:hover{
    background-color: #BF7400 !important;
  }
  &:disabled{
    pointer-events: none;
    cursor: not-allowed;
  }
}
.modalCancelBtn {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #ccc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  &:focus{
    outline: none!important;
    color: #333333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
    box-shadow: none!important;
  }
  &:hover{
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
  }
}
.modalLeftMsgDefault{
  position: absolute;
  left:1vh;
}
.multiselect-custom-btn{
    display: block;
    padding: 0.5vh 2.5vh;
    background: rgb(191, 116, 0);
    font-weight: bold;
  }
  .btn-colored{
    color: #333333!important;
    background: #D98C15 !important;
    border: 1px solid #BF7400 !important;
  }
  .btn-colored:hover{
    color: #333333!important;
    background: #BF7400!important;
    border: 1px solid #BF7400!important;
  }
  .btn-non-colored{
    color: #333333!important;
    background: #fff!important;
    border: 1px solid #ccc !important;
  }
  .btn-non-colored:hover{
    color: #333333!important;
    background: #ccc!important;
    border: 1px solid #ccc !important;
  }
  .btn-none{
    background: transparent;
      border: none;
      cursor: pointer;
  }
  .custom-range::-webkit-slider-thumb {
    background:  #D98C15 !important;
    &:focus{
      background:  #D98C15 !important;
    }
  }
  
  .custom-range::-moz-range-thumb {
    background:  #D98C15 !important;
    &:focus{
      background:  #D98C15 !important;
    }
  }
  
  .custom-range::-ms-thumb {
    background:  #D98C15 !important;
    &:focus{
      background:  #D98C15 !important;
    }
  }

  /* Common Model */
.modal{
  overflow: auto !important;
  .modal-header {
    font-size: 1.75vh;
    font-weight: bold;
  }
  .modal-height-sm  .modal-content .modal-body{
    min-height: 15vh!important;
  }
  .modal-height-md  .modal-content .modal-body{
    min-height: 25vh!important;
  }
}

/* Btn */
@mixin btn-default{
	background: #fff !important;
	padding: 0.5vh 1.5vh;
	border: 0.15vh solid #ccc !important;
	color: #333 !important;
	outline: none;
	border-radius: 0.29vw;
	margin: 0 0.2vh;
}

@mixin btn-primary{
	background: #D98C15 !important;
	padding: 0.5vh 1.5vh;
	border: 0.15vh solid #BF7400 !important;
	color: #ccc !important;
	outline: none;
	border-radius: 0.29vw;
	margin: 0 0.2vh;
}
/*
.btn-primary, .btn-primary[disabled], .btn-primary.disabled {
  @include btn-primary;

	&:hover, &:focus {
		color: #ccc !important;
		background-color: #BF7400 !important;
		border-color: #BF7400 !important;
	}
}
*/

.btn-default, .btn-default[disabled], .btn-default.disabled {
  @include btn-default;
	&:hover {
    background: #e6e6e6 !important;
	}
}

button:focus, .btn:focus, .btn-default-focus, table:focus, a:focus {
	outline: none !important;
	box-shadow: none !important;
}

.phase-A.active {
	background-color: $clr-red !important;
	border-color: $clr-red !important;
	color: $clr-gray !important;
}

.phase-B.active {
  background-color: $clr-green !important;
	border-color: $clr-green !important;
	color: $clr-gray !important;
}

.phase-C.active {
  background-color: $clr-blue !important;
	border-color: $clr-blue !important;
	color: $clr-gray !important;
}
.phase-X1.active,.phase-X3.active{
  background-color: rgb(217, 140, 20)!important;
  border-color: rgb(217, 140, 20)!important;
	color: $clr-gray !important;
}

input[type=checkbox], input[type=radio] {
  width: max-content !important;
  height: max-content !important;
}

.remove-close-icon{
  .close{
    display: none!important;
  }
}

.border-1px {
  border: solid 0.15vh #DDDDDD;
}

/* multi select dropdown */
.picky {
  .picky__input {
    height: auto !important;
    padding-right: 3vh !important;
    color: $buttontextcolor !important;
    border: solid 0.15vh #DDDDDD;
    border-radius: 0.43vh;
    .picky__placeholder {
      color: $buttontextcolor !important;
    }
    &:hover{
      background-color: #e6e6e6;
    }
    &::after{
      top: 1.5vh !important;
      right: 0.6vh !important;
      border-top: 0.5vh solid !important;
      border-right: 0.5vh solid transparent !important;
      border-left: 0.5vh solid transparent !important;
    }
  }
  .picky__dropdown{
    width: unset !important;
    top: unset!important;
    left: auto !important;
    right: 0px !important;
    overflow-y: auto !important;
    min-width: 100% !important;
    background: #e5e5e5 !important;
    max-height: 50vh !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      li {
        background: #e5e5e5 !important;
        border-bottom : 0px !important;
        padding-right: 2vh;

        .multiselect-custom-btn{
          display: block;
          padding: 0.5vh 2.5vh;
          background: rgb(191, 116, 0);
          font-weight: bold;
        }
      }
  }  
}

//for single select dropdown global css
.single-select-dropdown {
  .dropdown {
    button {
      background: #ffff;
      border-color: #ccc;
      color: #000;
      padding: 2px 10px;
      width: 100%;
      &::before{
        content: " \25BC";
        float: right;
        font-size: 0.75em;
        margin-top: 0.2vh;
      }
      &:focus{
        background: #fff!important;
        color: #000!important;
        box-shadow:none !important;
        border: 1px solid #ccc !important;
        outline: 0;
      }
      &:hover{
        background: #ccc!important;
      }
      &:active{
          border: thin #cfdadd solid !important;
      }
      &:disabled {
        color: #ccc!important;
        background: transparent !important;
      }
    }
    .single-select{
      background: #fff !important; 
      white-space: nowrap !important;
      overflow: hidden!important;
      text-overflow: ellipsis!important;
      border: 1px solid #ccc!important;
      padding: 0.76vh !important;
      text-align: left;
      cursor: pointer;
      &:disabled {
        cursor: default;
      }
    }
    .dropdown-menu {
      width: 100%!important;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;
      max-height: 30vh;
      overflow: auto;
      .dropdown-item {
        padding: 0.3vh 1.2vh !important;
      } 
    }
  }
  .caret-down {
    display: none;
    // position: relative;
    // margin-top: -2.8vh;
    // right: 2vh;
  }
  .show>.btn-primary.dropdown-toggle{
    color: #333 !important;
  }
}
// Export Data icon
.dropdown-export{
  .export-menu{
    min-width: 7vw !important;
    padding: 1vh;
    cursor: pointer;
    ul{
      list-style-type: none;
      display: inline-block;
      margin: 0px;
      padding: 0px;
      width: 100%;
      li{
        display: block;
        padding: 0.8vh;
        margin: 0px;
      }
      .border-bottom{
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

.inline-center-item{
  display: flex;
  align-items: center;

  .checkbox-label-span{
    margin-left: 1vh;
  }
}

.events-breadcrumb {
  .single-select-dropdown {
    .dropdown {
      min-width: 4vw;
      display: inline-block;
      button {
          border: none !important;
          &:hover {
              background: none !important;
          }
      }
      .dropdown-menu {
          width: unset !important;
      }
    }
  }
}

.text-ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.export-content {
  .dropdown-menu {
    padding: .5rem .2rem;
    .dropdown-item {
      &:hover {
        background-color: transparent;
      }
      &:first-child {
        border-bottom: 1px solid #cacaca;
      }
    }
  }
}
.op-half {
  opacity: 0.5;
}
.pointernone {
  pointer-events: none !important;
}
.borderForLoader {
  min-height: 50vh;
  border: 1px solid #aeaeae;
}

.timeout-dialog-modal{
  width: 40vw !important; 
  max-width: 40vw !important; 
}
