.events-summary .with-breadcrumb-events {
  margin-top: 1.9rem;
}
.events-summary .content-height .help-texts .alink {
  color: #3b73af;
}
.events-summary .content-height .help-texts .alink:hover {
  cursor: pointer;
  text-decoration: underline;
}
.events-summary .dropdown-disable-autohide {
  background: #fff !important;
}
.events-summary .dropdown-filters {
  width: 18vw !important;
  margin-top: 0.2vh !important;
}
.events-summary .groupByLabel .maxCountTxtbox {
  height: 3.5vh;
}
.events-summary .dropdown-badge {
  position: relative;
}
.events-summary .dropdown-badge .badge-position {
  position: absolute;
  background: #c53e3e;
  top: -6px;
  width: 1.9vh;
  height: 1.9vh;
  padding: 0.15rem 0.45rem 0.15rem 0.45rem;
  left: -1vh;
  border-radius: 50%;
  color: #fff;
  z-index: 999;
  font-size: 1.3vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-summary .dropdown-badge .btn-default {
  border-color: #ccc;
}
.events-summary .dropdown-badge .btn-default:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.events-summary .dropdown-badge .btn-default:disabled {
  border-color: #ccc;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem !important;
}
.events-summary .dropdown-badge .btn-default:disabled:hover {
  background-color: #fff !important;
}
.events-summary .clm-style {
  color: #3b73af !important;
}
.events-summary .clm-style:hover {
  cursor: pointer;
  text-decoration: underline;
}
.events-summary .dropdown-filters .picky {
  width: 100% !important;
  white-space: nowrap;
}
.events-summary .dropdown-filters .picky .picky__input {
  text-overflow: ellipsis;
}
.events-summary .loader-wraps {
  min-height: 65vh !important;
}

