.map {
  border: solid 1px #E7E4E4;
}
.map .map-view-header {
  width: 100%;
  background-color: #F0EFEF;
  border-bottom: solid 2px #FFFFFF;
  padding: 1.5vh 0.5vw;
}
.map .map-view-header .map-title {
  font-weight: bold;
  position: relative;
  z-index: 100;
  width: 80%;
}
.map .map-view-header .map-title .map-refresh {
  cursor: pointer;
  background: #D98C15;
  box-shadow: 0px 0px 2px grey;
  padding: 2px 5px 4px 6px;
}
.map .map-view-header .map-title .map-refresh:hover {
  box-shadow: 0px 0px 4px black;
}
.map .map-view-header .map-title .map-refresh:active {
  box-shadow: 0px 0px 1px grey;
}
.map .map-view-header .map-title .map-breadcrumb {
  font-weight: normal;
}
.map .map-view-header .map-switch-btn {
  padding: 2px;
}
.map .map-view {
  height: 92%;
}
.map .map-view .tooltip-title {
  margin: 10px 0px;
  font-weight: bold;
  font-size: 14px;
}
.map .map-view .tooltip-table {
  border: 1px solid #bdbbbb;
  font-size: 12px;
}
.map .map-view .tooltip-table thead th {
  background-color: #E5E5E5;
  text-align: center;
  font-size: 12px;
  border: 1px solid #bdbbbb !important;
  padding: 5px;
}
.map .map-view .tooltip-table tbody td {
  text-align: center;
  font-size: 12px;
  border: 1px solid #bdbbbb !important;
  padding: 5px;
}
.map .map-view .tooltip-wrap {
  width: 200px;
}
.map .map-view .tooltip-data {
  display: inline-block;
  width: 120px;
}
.map .map-view .tooltip-links {
  margin: 2px 0px;
  padding: 0 9px;
  border-right: 1px solid grey;
}
.map .map-view .txt-color {
  color: #D98C15;
  cursor: pointer;
}
.map .map-view .txt-color:hover {
  text-decoration: underline;
}
.map .map-view .tooltip-links:first-child {
  margin-left: 0;
}
.map .map-view .tooltip-links:last-child {
  margin-right: 0;
  border-right: 0;
}

.StreetViewModal {
  width: unset !important;
  max-width: 70vw;
}
.StreetViewModal .modal-body {
  min-height: 60vh;
}

.noDeviceDiv {
  min-height: 15vh;
}

