.form-control, .btn {
  line-height: 1 !important;
}

.logo img {
  margin-bottom: 1vh;
}

.login-view {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  display: table;
  max-width: 100%;
}

.login-content {
  padding: 0;
  vertical-align: middle;
  display: table-cell;
  margin: 0;
}

.login-wrap {
  width: 22vw;
  max-width: 25vw;
  min-height: 40vh;
  margin: 0 auto;
  padding: 2.5vh;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  border: 10px;
  -webkit-box-shadow: 2px 2px 2px 3px rgba(42, 42, 42, 0.7);
  -moz-box-shadow: 2px 2px 2px 3px rgba(42, 42, 42, 0.7);
  -ms-box-shadow: 2px 2px 2px 3px rgba(42, 42, 42, 0.7);
  box-shadow: 2px 2px 2px 3px rgba(42, 42, 42, 0.7);
}
.login-wrap .login-title {
  color: #646464;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.6vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2vh;
}
.login-wrap .form-horizontal {
  margin-bottom: 15px;
  margin-top: 88px;
}
.login-wrap .input-with-icon {
  position: relative;
}
.login-wrap .input-with-icon .account-textbox {
  padding-right: 2vw;
}
.login-wrap .input-with-icon .account-help {
  position: absolute;
  right: 0;
  top: 0.6vh;
  width: 1vw;
}
.login-wrap .keeplogin {
  font-family: "Open Sans", sans-serif !important;
  display: inline-block;
  font-size: 10px;
  color: #aaaaaa;
}
.login-wrap .keeplogin input#loginkeeping {
  margin-right: 5px;
  margin-bottom: 5px;
}
.login-wrap .forgot-password {
  font-family: "Open Sans", sans-serif !important;
  margin-bottom: 3px;
  font-size: 13px;
  font-style: bold;
}
.login-wrap .forgot-password a:hover {
  text-decoration: underline;
}
.login-wrap .alert-dismissible {
  margin-bottom: 2vh !important;
}
.login-wrap .login-btn {
  background: #D98C15 !important;
  color: #333333 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.login-wrap .btn-link, .login-wrap .btn-link:hover {
  color: #BF7400 !important;
  padding: 0;
}
.login-wrap .btn-link a, .login-wrap .btn-link:hover a {
  color: #BF7400 !important;
}
.login-wrap .btn-warning {
  color: #333;
  background-color: #D98C15 !important;
  border-color: #D98C15 !important;
  padding: 1vh !important;
}
.login-wrap .btn-warning:hover {
  border-color: #adadad !important;
}
.login-wrap .btn-warning:disabled {
  opacity: 0.5;
}
.login-wrap .margin-top-4vh {
  margin-top: 4vh;
}
.login-wrap .roundedLabel {
  border: 1px solid #dadce0;
  border-radius: 1vw;
  padding: 0.5vh 0.5vw;
  cursor: pointer;
}
.login-wrap .dspnone {
  display: none !important;
}

.login-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  padding-right: 15px;
}

.login-error-color {
  color: #a94442;
}

.login-error-bordercolor {
  border-color: #a94442 !important;
}

.ample-error-message {
  color: #a94442;
  text-align: right;
}

.orgView {
  width: 100%;
  height: 100%;
  display: table;
}
.orgView .orgContent {
  vertical-align: middle;
  display: table-cell;
}
.orgView .orgContent .single-select-dropdown .dropdown button {
  height: 5vh;
  font-weight: bold;
}
.orgView .orgContent .orgWrap {
  width: 50vw;
  margin: 0 auto;
}
.orgView .orgContent .orgWrap .org-btn {
  width: 10vw;
  padding: 1.5vh;
  margin: auto;
  font-size: 2vh;
  font-weight: bold;
  border-radius: 1vh;
  float: right;
}

