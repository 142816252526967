.manageSchedules {
    .device-management-ajax-loader {
      left: 100vh !important;
    }
    .btn-outline-secondary {
      color: #333 !important;
      border-color: #ccc !important;
      background: #fff !important;
      &:focus {
        box-shadow: none !important;
        background: none !important;
      }
      .dropdown-toggle {
        background: #fff !important;
        border-color: #ccc !important;
        color: #333 !important;
        &:focus {
          box-shadow: none !important;
          background: none !important;
        }
        &:disabled {
          color: #a5aaaf;
          border-color: #ccc;
        }
        &:hover:not([disabled]) {
          background: #e6e6e6 !important;
          color: #333 !important;
          border-color: #ccc !important;
        }
      }
      .show {
        .dropdown-toggle {
          color: #333 !important;
          background-color: #fff !important;
          border-color: #6c757d !important;
        }
        .dropdown-menu.show {
          background: #e6e5e5 !important;
          padding: 1.2vh;
          width: auto;
          min-width: 8vw !important;
          right: 4.2vh !important;
          top: -4vh !important;
          z-index: 990;
          line-height: 3vh;
          overflow: auto;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          max-height: 75vh;
        }
      }
    }
    .show > .btn-outline-secondary.dropdown-toggle {
      color: #333 !important;
      background-color: #fff !important;
      border-color: #6c757d !important;
    }
    .curptr {
        cursor: pointer;
    }
  }
  