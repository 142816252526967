.fault-analysis {
  overflow: hidden;
  min-height: 30vh;
}
.fault-analysis.secondary-navbar .navbar-nav {
  margin-left: 25vw;
}
.fault-analysis .events-details .blank-page span {
  left: 50%;
}
.fault-analysis .load-more {
  background-color: #efefef;
  border: none;
  color: #3b73af;
  font-size: 12px;
  padding: 18px;
}
.fault-analysis .load-more:hover {
  text-decoration: underline;
}

