.lic-accord.card-header:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  right: 2vh;
  top: 2vh;
}

.license-management .custom-file-inputs {
  overflow: hidden;
  margin-bottom: 1vh;
  border-radius: 3px;
  display: inline-block;
  padding: 0.5vh 1vh;
  border: 0.1vh solid #c5c5c5;
  cursor: pointer !important;
}
.license-management .custom-file-inputs:hover {
  background: #eaeaea;
}
.license-management .custom-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5vh 1vh 0vh;
  width: 100%;
  height: 80%;
  font-size: 0 !important;
  cursor: pointer !important;
}
.license-management .disabled-file-inputs {
  overflow: hidden;
  margin-bottom: 1vh;
  border-radius: 3px;
  display: inline-block;
  padding: 0.5vh 1vh;
  border: 0.1vh solid #e9e9e9;
  cursor: not-allowed;
}
.license-management .disabled-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5vh 1vh 0vh;
  width: 100%;
  height: 80%;
  font-size: 0 !important;
  cursor: not-allowed;
}
.license-management .tooltip-btn {
  padding-top: 1vh;
}
.license-management .input-icon-color {
  color: #636363;
}
.license-management .disabled-input-icon-color {
  color: #c3c3c3;
}
.license-management .reload-text-color {
  color: #BF7400;
}
.license-management .licenceMgntTbl {
  min-height: 15vh;
  border: 1px solid #aeaeae;
}
.license-management .tooltiptext-center {
  width: 12vh;
  left: -8vh !important;
  padding: 0.3vh;
}
.license-management .tooltiptext {
  right: 0vh !important;
  bottom: 120%;
}
.license-management .tooltiptext::after {
  left: 80%;
}
.license-management .colorRed {
  color: red;
}
.license-management .colorBlue {
  color: blue;
}
.license-management .colorGreen {
  color: green;
}
.license-management .ReactTable {
  border: none !important;
}
.license-management .ReactTable .rt-thead .rt-th {
  border-right: 0.1vh solid #bdbbbb !important;
}
.license-management .ReactTable .rt-tbody .rt-td {
  border-right: 0px solid #bdbbbb !important;
  border-top: 0px solid #bdbbbb !important;
}
.license-management .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  border-right: 0.1vh solid #bdbbbb !important;
}

