.visibile-hide {
	visibility: hidden;
}
.align-table {
    .ReactTable {
        .rt-td:not(:first-child):not(:last-child){
            text-align: left !important;
        }
    }
}
.status-icons {
    display: inline-block;
    height: 1.8vh;
    width: 0.9vw;
    text-align: center;
    border: solid 0.07vw #000;   
    // line-height: 1vh;
}

.device-status-COMPLETED,
.device-status-SUCCESS {
    background: #5CD65C;
}

.device-status-FAILURE,
.device-status-ABORTED,
.device-status-TIMEOUT,
.device-status-FAILED {
    background: #FF7474;
}

.device-status-INPROGRESS {
    background:#ffffff;
}
.device-status-TRANSFER_COMPLETED{
    background: #0096FF;
}