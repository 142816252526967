.dgw-container{
    overflow-x: hidden;
  @mixin sg-action-btn{
    padding: 0.4vh 1.5vh .4vh;
    border-radius: 0.5vh;
    font-size: 2.5vh;
}
.ceritifcate-view-btn{
    @include sg-action-btn;
    border: .1vh solid #000;
    cursor: pointer;
    padding: 0.3vh .6vh!important;
    font-size: 1.8vh!important;
}
.certificate-section{
    margin-bottom : 0vh;
    margin-top: 1vh;
    .certificate-all{
        // @include customScrolbar;
        padding: 1vh;
        border: 1px solid rgb(229, 229, 229);
        overflow-y: auto;
        .certificate-all-border{
            border: 1px solid #e5e5e5;
        }
        .certificate-all-header{
            border-bottom: 1px solid #e5e5e5;
            padding: 1vh;
            margin-bottom: 1vh;
            .status-color{
                color: #D98C15;
            }
        }
        .certificate-all-body{
            // @include customScrolbar;
            padding: 1vh; 
            height: 80vh;
            overflow-y: auto;
            word-wrap: break-word;
        }
    }
}
@mixin customScrolbar{
    &::-webkit-scrollbar {
      width: 0.8vh!important;
      background: #e5e5e5;

    }
    &::-webkit-scrollbar-thumb {
      background:#9e9e9e; 
      border-radius: 20px;
    }
    &::-webkit-scrollbar-button {
      &:single-button {
        height: 0.5vh;
      }
    }
  }
  @mixin table-area {
    padding: 1vh;
    border: 1px solid #e5e5e5;
    min-height: 36vh;
  }

  @mixin custom-file-upload{
    border: 0.1vh solid #ccc;
    display: inline-block;
    padding: .6vh 1vh;
    border-radius: 0.5vh;
  }

  @mixin cancel-btn {
    padding: 0.5vh 1vw;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 0.5vh;
    background: #e9ecef;
    outline: none;
    font-size: 2vh;
    &:hover{
        background: #d2d2d2;
    }
}

  @mixin create-btn {
    padding: 0.6vh 1vw;
    color: #333;
    border: none;
    border-radius: 0.5vh;
    background: #d98c15;
    outline: none;
    font-size: 2vh;
    &:hover{
        background: #BF7400;
    }
}


    .sg-name{
        white-space: unset!important;
        color: rgb(217, 140, 21)!important;
        &:hover{
            text-decoration: underline;
        }
    }
    .sg-table-section{
        width: 100%; 
        min-height: 15vh;

        .ReactTable{
            .rt-thead{
                .rt-th{
                  &:first-child{
                    min-width: 4vw!important;
                    max-width: 4vw!important;
                  }
                }
            }
            .rt-tbody{
                .rt-td{
                  &:first-child{
                    min-width: 4vw!important;
                    max-width: 4vw!important;
                  }
                }
            }
        }
    }
    .ReactTable{
        // border: none!important;
        border: 0.1vh solid #fff!important;
        .rt-thead{
            .rt-th{
                white-space: unset!important;
                &:nth-last-child(1){
                  border-right: 1px solid #ccc!important;
              }
            }
        }
        .rt-tbody{
            .rt-td{
                white-space: unset!important;
                border-right: 0px!important;
                border-top: 0px!important;
                &:nth-last-child(1){
                  border-right: 1px solid #ccc!important;
              }
            }
        }
    }

    .sensor-getway-table-top{
        margin-bottom: 1.5vh;
        .p-0-m-0{
            margin: 0;
            padding: 0
        }

        .sensor-getway-btn-disabled{
            @include sg-action-btn;
            padding: 0vh 1.2vh 0.1vh !important;
            border: .1vh solid #e5e5e5;
            background: #fff;
            cursor: no-drop;
            color:  #c3c3c3;
            margin-left: 0.5vh;
            img{
                opacity: 0.3;
            }
        }
        .action-padding-btm{
            padding-bottom: .6vh!important
        }
        .sensor-getway-btn-enabled{
            @include sg-action-btn;
            border: .1vh solid #ccc;
            color: #333;
            outline: none!important;
            margin-left: 0.5vh;
            &:hover{
                background: #e6e6e6;
            }
        }
        .sensor-getway-btn-enabled-2{
            @include sg-action-btn;
            border: .1vh solid #ccc;
            color: #333;
            outline: none!important;
            padding: 0vh 1.2vh 0.1vh !important;
            margin-left: 0.5vh;
            cursor: pointer;
            &:hover{
                background: #e6e6e6;
            }
        }

        .filter-icon-btn{
            color: rgb(51, 51, 51);
            border-radius: 0.5vh;
            border: 0.1vh solid rgb(204, 204, 204);
            cursor: pointer;
            padding: 0.8vh 1vh 0.6vh 1vh;
            &:hover{
                background: #e6e6e6;
            }
        }
        .cert-btn-enable{
            border: 0.1vh solid rgb(204, 204, 204);
            width: 2vh;
            height: 2vh;
            border-radius: 0.5vh;
            cursor: pointer;
            padding: 0.8vh 0.4vh;
            &:hover{
                background: #e6e6e6;
            }
        }

        .cert-btn-disable{
            border: 0.1vh solid rgb(204, 204, 204);
            width: 2vh;
            height: 2vh;
            border-radius: 0.5vh;
            padding: 0.8vh 0.4vh;
            opacity: 0.5;
            cursor: not-allowed;
        }

        //for dropdown menu customization css start
        .dropdown-toggle{ 
            background :#fff!important;
            padding: 0!important;
            outline: none!important;
            border: none!important;
            &:focus{
                outline: none!important;
                border: none!important;
                background-color: #fff!important;
                background: #fff!important;
                padding: 0!important;
                box-shadow: none!important;
            }
            &::before{
                display: none;
            }
        }

        .dropdown-menu{
            background: #EDEDED!important;
            width: 22vh;
            padding: 10px;
            line-height: 3vh;
            top: 0.6vh!important;
        }
        //for dropdown menu customization css end
    }

    .modal-close{
        @include sg-action-btn;
            padding: 0.2vh 1vh;
            border: .1vh solid #ccc;
            color: #333;
            outline: none!important;
            margin-left: 0.5vh;
            font-size: 2vh;
            cursor: pointer;
            &:hover{
                background: #e6e6e6;
            }
    }

    .sg-name-modal{
        background: rgb(255, 255, 255);
        position: fixed;
        right: 2.5vh;
        width: 52vw;
        top: 6vh;
        z-index: 10;
        box-shadow: grey 0px 3px 9px 4px;
        height: 93%;

        .network-group-top-section{
            border-bottom: 1px solid rgb(204, 204, 204);
            padding: 1.5vh 0px;

            .ntwrk-top-section-btn{
                @include sg-action-btn;
                    padding: 0.4vh 1vh;
                    border: .1vh solid #ccc;
                    color: #333;
                    outline: none!important;
                    margin-left: 0.5vh;
                    font-size: 2vh;
                    cursor: pointer;
                    &:hover{
                        background: #e6e6e6;
                    }
            }
            .ntwrk-top-section-btn-disabled{
                @include sg-action-btn;
                padding: 0.4vh 0.8vh;
                border: .1vh solid #ccc;
                font-size: 2vh;
                cursor: no-drop;
                color:  #c3c3c3;
            }
        }

        .sg-name-modal-body{
            padding: 1vh 0;
            // @include customScrolbar;
            &::-webkit-scrollbar {
                width: 1.2vh!important;               
              }
            height: 90vh;
            overflow: auto;
            .add-network-group-section{
                margin-bottom : 1vh;
                .delete-section{
                    padding: 5vh 1vh;
                    border: 1px solid rgb(229, 229, 229);
                }
                .form-section{
                    // @include customScrolbar;
                    padding: 1vh;
                    border: 1px solid rgb(229, 229, 229);
                    height: 38vh;
                    overflow-y: auto;

                    .form-title{
                        font-weight: 600 !important;
                        font-size: 1.6vh;
                        padding: 1vh;
                        .dropdown-icon {
                            cursor: pointer;
                            color: #6b6a6a;
                            margin-left: 1vh;
                        }
                    }
                    .form-row{
                        margin-bottom: 1.3vh;
                        .form-row-left{
                            width: 70%;
                            display: inline-block;
                            .margin-left-5{
                                margin-left: 5vh;
                            }
                        }
                        .form-row-left-50{
                            width: 50%;
                            display: inline-block;
                            .margin-left-5{
                                margin-left: 5vh;
                            }
                        }
                        .form-row-right-50{
                            width: 46%;
                            display: inline-block;
                        }

                        .form-row-right{
                            width: 25%;
                            display: inline-block;
                            .sequre-title{
                                font-size: 1.5vh;
                                margin-left: 1vh;
                                position: absolute;
                                top: 0.3vh;
                            }
                        }

                        .dropdown{
                            .dropdown-toggle{
                                text-align: left;
                                position: relative;
                                width: 100%;
                                background: #fff;
                                color: #333;
                                border: 0.1vh solid#ccc;
                                padding: 1vh;
                                &:focus{
                                    box-shadow: none!important;
                                }
                                &::before{
                                    content: "";
                                    border-top: .3em solid;
                                    border-right: .3em solid transparent;
                                    border-bottom: 0;
                                    border-left: .3em solid transparent;
                                    position: absolute;
                                    right: 1vh;
                                    top: 45%;
                                }
                            }
                            .dropdown-menu{
                                width: 100%;
                                line-height: 4vh;
                                .dropdown-item{
                                    padding: 0 1.5vh;
                                    line-height: 3.5vh;
                                }
                            }
                        }
                    }
                }
                .add-network-action-btn{
                    padding: 1vh 0;
                    // font-size: 2vh;
                    // color: #333333;
                    .button-add{
                        background-color: #BF7400;
                        border: none;
                        border: 0.1vh solid #BF7400;
                        padding: 0.3vh 1vh;
                        outline: none;
                        border-radius: 0.4vh;
                        cursor: pointer;
                        margin-left: 0.4vh;
                        &:hover{
                            background: #a96600;
                        }
                     }
                     .disable-button-add{
                        background-color: #BF7400;
                        border: none;
                        border: 0.1vh solid #BF7400;
                        padding: 0.3vh 1vh;
                        outline: none;
                        border-radius: 0.4vh;
                        opacity: 0.5;
                        cursor: auto;
                        margin-left: 0.4vh;
                     }
                     .button-cancel{
                        background-color: #e6e6e6;
                        border: none;
                        border: 0.1vh solid #adadad;
                        padding: 0.3vh 1vh;
                        outline: none;
                        border-radius: 0.4vh;
                        cursor: pointer;
                        &:hover{
                            background: #bfbfbf;
                        }
                    }
                }
            }
            .network-groups-table-section{
                margin-bottom : 1vh;
                .inactive-icon{
                    color:#b9b9b9;
                    cursor: no-drop;
                }
                .active-icon{
                    color: #212529;
                    cursor: pointer;
                }
                .table-section{
                    @include table-area;
                    .ReactTable{
                        max-height: 34vh;
                        overflow: hidden;
                        .rt-tbody{
                            // @include customScrolbar;
                            overflow-x: hidden!important;
                            .rt-tr{
                                .rt-td{

                                    &:last-child{
                                        width: 95px!important;
                                  }
                                }
                            }
                        }
                    }
                }

                .table-section-full{
                    @include table-area;
                    .ReactTable{
                        max-height: 34vh;
                        overflow: hidden;
                        .rt-tbody{
                            // @include customScrolbar;
                            overflow-x: hidden!important;
                        }
                    }
                }
            }

            .accordion-bar{
                padding: 1vh;
                cursor: pointer;
                background: rgb(229, 229, 229);
                border: 0.1vh solid rgb(204, 204, 204);
                font-size: 1.6vhvh;
                font-weight: bold;
                .accordion-icon{
                    font-size : 3vh;
                     display : flex;
                     color: #888888
                }
            }
        }
    }

    .sg-upload-certificates{
        .sensor-getway-btn{
            @include sg-action-btn;
            border: .1vh solid #D98C15;
            background: #D98C15;
            cursor: pointer;
            position: fixed;
            left: 3vh;
            bottom: 4vh;
            padding: 0.3vh 1.1vh!important;
            font-size: 1.8vh!important;
        }
        .sensor-getway-btn:hover{
            background: #BF7400;
        }
        .sg-upload-cert-modal{
            border: 0.1vh solid #bdbbbb;
            width: calc(100% - 3vh);
            position: fixed;
            bottom: 0;
            height: 35%;
            background-color: #fbfbfb;
            -webkit-box-shadow: 0px 3px 9px 4px grey;
            box-shadow: 0px 3px 9px 4px grey;
            left: 4vh;
            padding: 2vh 0;
            z-index: 9;
            .action-button{
                position: absolute;
                z-index: 9;
                right: 2.2vh;
                display: flex;
                top: -0.7vh!important;
            }
            .nav-tabs{
                position: relative;
            }
            .tab-content{
                // font-size: 2.2vh;
                border: 0.1vh solid #dee2e6;
                border-top: none;
                padding-top: 2vh;
                height: 27vh;

                .certificate-table{
                    .ReactTable{
                        max-height: 25vh;
                        overflow: hidden;
                        .rt-thead{
                            position: sticky;
                            top: 0;
                        }
                    }
                }

                .certificate-table-full{
                    .ReactTable{
                        max-height: 25vh;
                        overflow: hidden;
                        .rt-tbody{
                            overflow-x: hidden!important;
                        }
                    }
                }
            }
            .cert-close-icon{
                display: block;
                padding: 0.2vh 1vh .2vh;
            }
            input[type=file] {
                display: none;
              }
            .custom-file-upload{
                @include custom-file-upload;
                cursor: pointer;
                  &:hover{
                    background: #eaeaea;
                  }
              }
              .custom-file-upload-disabled{
                @include custom-file-upload;
                color: #ccc;
                cursor: no-drop;
              }
        }
    }
    .nav-tabs {
        a {
            color: #BF7400;
            // font-size: 2vh;
            &:focus {
                outline: none;
            }
        }
    }
    .flex-content{
        display: inline-flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: flex-end;
    }
    .tooltiptext-center{
        left: -4vh !important;
    }
    .tooltiptext-filter{
        left: -3.5vh !important;
        margin-bottom: 0.8vh!important
    }
    .tooltiptext-addnetwork{
        min-width: 16vh;
        left: -6vh !important;
        padding: 0.4vh 1vh;
        border-radius: 0.4vh;
    }
    .tooltiptext-close{
        left: -1vh !important;
        padding: 0.4vh 1vh;
        border-radius: 0.4vh;
    }
    .customTooltip{
        display: inline-block!important;
    }

.pkey-modal{
    .modal-content{
        .modal-body{
            height: 20vh!important;
        }
        .modal-footer{
            .cancel-btn{
                @include cancel-btn;
            }
            .create-btn{
                @include create-btn;
            }
        }
    }   
}

.push-cert-modal{
    color:#333333!important;
    .modal-content{
        .modal-body{
            padding: 1vh 4vh;
            .section-title{
                padding-bottom: 1vh;
            }
            .margin-top{
                margin-top: 2vh;
            }
            .section-area{
                border: 1px solid rgb(208, 208, 208);
                height: 30vh;
                overflow-y: auto;
                overflow-wrap: break-word;
                // @include customScrolbar;
                .padding-1-0{
                    padding: 1vh 0;
                }
            }
        }
        .modal-footer{
            .cancel-btn{
                @include cancel-btn;
            }
            .create-btn{
                @include create-btn;
            }
        }
    } 
}
    .dgwDataTbl{
        min-height: 50vh;
        border: 1px solid #aeaeae;
      }
}