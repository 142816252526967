@mixin fileInputArea{
  overflow: hidden;
  position: relative;
  border-radius: 0.456vh;
  display: inline-block;
  padding: 0.6vh 1.2vh;
  background: #f9f9f9;
}
@mixin inputTypeFile{
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.6vh 1.2vh;
}
@mixin customScrolbar{
  &::-webkit-scrollbar {
    width: 0.8vh!important;
    background: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    background:#9e9e9e; 
    border-radius: 3.04vh;
  }
  &::-webkit-scrollbar-button {
    &:single-button {
      height: 0.5vh;
    }
  }
}

.system-admin{
  // margin-top: -3vh!important;
  .margin-top-row{
    margin-top: 2vh
  }
  .padding-0{
    padding: 0;
  }
  .custom-file-inputs{
  @include fileInputArea;
    border: 0.1vh solid #c5c5c5;
  cursor: pointer;
    &:hover{
      background: #f9f9f9;
    }
    
    input[type=file] {
      @include inputTypeFile;
      cursor: pointer;
    }
  }
  .disabled-file-inputs{
    @include fileInputArea;
    border: 0.1vh solid #e9e9e9;
    cursor: not-allowed;

    input[type=file] {
      @include inputTypeFile;
      cursor: not-allowed;
    }
  }

  .table-top-section{
    background: #ededed; 
    padding: 0.5vh 1vh;
    border-bottom: 0.1vh solid #bdbbbb;
    display: flex;
    align-items: center;
    
  .font-size-2{
    // font-size: 1.5vh;
    .cursor-pointer{
      cursor: pointer;
      color: #BF7400;
    }
    .cursor-no-drop{
      cursor: no-drop;
      color: #BF7400;
    }
  }
  .download-sample-file{
      padding: 0.6vh 0vh;
      background: rgb(249, 249, 249);
      color: rgb(99, 99, 99);
      border: 0.1vh solid rgb(174, 174, 174);
      border-radius: 0.5vh;
      margin-left: 0.8vh;
      cursor: pointer;
      .padding-download-icon{
        padding: 1vh 1.2vh
      }
    }
  }

  .tooltip-btn{
    padding-top: 1vh;
  }
  .input-icon-color{
    color: #636363;
  }
  .disabled-input-icon-color{
    color: #c3c3c3;
  }

  .ReactTable{
    max-height:33vh!important;
    // border: 0.1vh solid #ccc;
    border: none;
    .rt-thead{
      position: sticky;
      top:0;
        .rt-th{
            &:nth-last-child(1){
              border-right: 0px!important;
          }
        }
    }
    .rt-tbody{
      border: 0.1vh solid #ccc;
        .rt-td{
            white-space: unset!important;
            border-right: 0px!important;
            border-top: 0px!important;
            &:nth-last-child(1){
              border-right: 0px!important;
          }
            &:nth-child(1){
              border-left: 0px!important;
          }
        }
    }
    .pagination-bottom{
      margin-bottom: 0;
    }
  }
  .download-btn{
    color: #bf7400;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .margin-left-1{
    margin-left: 0.1vh;
  }
  // ======================== Manufacturing table start
  .menufacturingFile{
    border: 0.1vh solid #bdbbbb;
    min-height:41vh!important;
    .react-table-manufac{
      padding: 1vh;
      .ReactTable{
        .rt-thead{
            .rt-th{
              &:nth-child(1){
                min-width: 29%!important;
                max-width: 29%!important;
              }
              &:nth-child(2){
                min-width: 17.6%!important;
                max-width: 17.6%!important;
              }
              &:nth-child(3){
                min-width: 22.4%!important;
                max-width: 22.4%!important;
              }
              &:nth-child(4){
                min-width: 14%!important;
                max-width: 14%!important;
              }
              &:nth-child(5){
                min-width: 17%!important;
                max-width: 17%!important;
              }
            }
        }
        .rt-tbody{
          @include customScrolbar;
            .rt-td{
              &:nth-child(1){
                min-width: 29%!important;
                max-width: 29%!important;
              }
              &:nth-child(2){
                min-width: 17.6%!important;
                max-width: 17.6%!important;
              }
              &:nth-child(3){
                min-width: 22.4%!important;
                max-width: 22.4%!important;
              }
              &:nth-child(4){
                min-width: 14%!important;
                max-width: 14%!important;
              }
              &:nth-child(5){
                min-width: 17%!important;
                max-width: 17%!important;
              }
              word-break: break-all;
            }
        }
      }
    }
    .react-table-manufac-full{
      padding: 1vh;
      .ReactTable{
        .rt-thead{
            .rt-th{
              &:nth-child(1){
                min-width: 29%!important;
                max-width: 29%!important;
              }
              &:nth-child(2){
                min-width: 17.6%!important;
                max-width: 17.6%!important;
              }
              &:nth-child(3){
                min-width: 22.4%!important;
                max-width: 22.4%!important;
              }
              &:nth-child(4){
                min-width: 14%!important;
                max-width: 14%!important;
              }
              &:nth-child(5){
                min-width: 17%!important;
                max-width: 17%!important;
              }
            }
        }
        .rt-tbody{
            .rt-td{
              &:nth-child(1){
                min-width: 29%!important;
                max-width: 29%!important;
              }
              &:nth-child(2){
                min-width: 17.6%!important;
                max-width: 17.6%!important;
              }
              &:nth-child(3){
                min-width: 22.4%!important;
                max-width: 22.4%!important;
              }
              &:nth-child(4){
                min-width: 14%!important;
                max-width: 14%!important;
              }
              &:nth-child(5){
                min-width: 17%!important;
                max-width: 17%!important;
              }
            }
        }
      }
    }
  }

   // ======================== Firmware table start
  .firewareFile{
    border: 0.1vh solid #bdbbbb;
    margin-left: 0;
    min-height:41vh!important;
    @media only screen and (max-width: 767px) {
      margin-left: -15px;
      margin-top: 2vh;
    }

    .react-table-firmware{
      padding: 1vh;
      .ReactTable{
        .rt-thead{
            .rt-th{
              &:nth-child(1){
                min-width: 47%!important;
                max-width: 47%!important;
              }
              &:nth-child(2){
                min-width: 31.95%!important;
                max-width: 31.95%!important;
              }
              &:nth-child(3){
                min-width: 21%!important;
                max-width: 21%!important;
              }
            }
        }
        .rt-tbody{
          @include customScrolbar;
            .rt-td{
              &:nth-child(1){
                min-width: 47%!important;
                max-width: 47%!important;
              }
              &:nth-child(2){
                min-width: 32%!important;
                max-width: 32%!important;
              }
              &:nth-child(3){
                min-width: 21%!important;
                max-width: 21%!important;
              }
            }
        }
      }
    }
    .react-table-firmware-full{
      padding: 1vh;
      .ReactTable{
        .rt-thead{
            .rt-th{
              &:nth-child(1){
                min-width: 47%!important;
                max-width: 47%!important;
              }
              &:nth-child(2){
                min-width: 31.95%!important;
                max-width: 31.95%!important;
              }
              &:nth-child(3){
                min-width: 21%!important;
                max-width: 21%!important;
              }
            }
        }
        .rt-tbody{
            .rt-td{
              &:nth-child(1){
                min-width: 47%!important;
                max-width: 47%!important;
              }
              &:nth-child(2){
                min-width: 32%!important;
                max-width: 32%!important;
              }
              &:nth-child(3){
                min-width: 21%!important;
                max-width: 21%!important;
              }
            }
          }
        }
      }
    }
    // ======================== grid mapping table start
    .gridMapping{
      border: 1px solid #bdbbbb;
      min-height:41vh!important;
      .react-table-gridmapping{
        padding: 1vh;
        .ReactTable{
          .rt-thead{
              .rt-th{
                &:nth-child(1){
                  min-width: 40%!important;
                  max-width: 40%!important;
                }
                &:nth-child(2){
                  min-width: 20.15%!important;
                  max-width: 20.15%!important;
                }
                &:nth-child(3){
                  min-width: 24.95%!important;
                  max-width: 24.95%!important;
                }
                &:nth-child(4){
                  min-width: 15%!important;
                  max-width: 15%!important;
                }
              }
          }
          .rt-tbody{
            @include customScrolbar;
              .rt-td{
                &:nth-child(1){
                  min-width: 40%!important;
                  max-width: 40%!important;
                }
                &:nth-child(2){
                  min-width: 20.1%!important;
                  max-width: 20.1%!important;
                }
                &:nth-child(3){
                  min-width: 25%!important;
                  max-width: 25%!important;
                }
                &:nth-child(4){
                  min-width: 15%!important;
                  max-width: 15%!important;
                }
              }
              word-break: break-all;
            }
          }
      }

      .react-table-gridmapping-full{
        padding: 1vh;
        .ReactTable{
          .rt-thead{
              .rt-th{
                &:nth-child(1){
                  min-width: 40%!important;
                  max-width: 40%!important;
                }
                &:nth-child(2){
                  min-width: 20.15%!important;
                  max-width: 20.15%!important;
                }
                &:nth-child(3){
                  min-width: 24.95%!important;
                  max-width: 24.95%!important;
                }
                &:nth-child(4){
                  min-width: 15%!important;
                  max-width: 15%!important;
                }
              }
          }
          .rt-tbody{
              .rt-td{
                &:nth-child(1){
                  min-width: 40%!important;
                  max-width: 40%!important;
                }
                &:nth-child(2){
                  min-width: 20.1%!important;
                  max-width: 20.1%!important;
                }
                &:nth-child(3){
                  min-width: 25%!important;
                  max-width: 25%!important;
                }
                &:nth-child(4){
                  min-width: 15%!important;
                  max-width: 15%!important;
                }
              }
            }
          }
      }
    }
  .margin-top-2vh{
      margin-top: 2vh;
    }
}
.sysAdminErrModal{
  .modal-body {
      height: 34vh!important;
  }
}
