.config-prop-modal{
    color:#333333!important;
    width: 43vw!important;
    .modal-content{
        .modal-header{
            .icon{
                font-size: .9vw;
                margin-top: 0.5vh;
                color:#75C53E;
            }
            .title{
                font-size: 1vw;
            }
        }
        .modal-body{
            min-height: 30vh;
            .form-section{
                padding: 0.5vh 3vh;
                .form-category-section{
                    border-bottom: 0.2vh solid rgb(229, 229, 229);
                    line-height: 0;
                    position: relative;
                    margin: 1.4vh 0vh 1.4vh;
                    .icon-and-label{
                        background: rgb(255, 255, 255);
                        padding-right: 2vh;
                        position: absolute;
                        bottom: -0.9vh;
                        cursor: pointer;
                        .icon{
                            font-size: 2vh;
                            padding-right: 1vh
                        }
                    }
                }
                .label-left{
                    padding: 1.6vh 0 0 1.6vh
                }
            }
            .form-err-border-red{
                border: 0.1vh solid red
            }
        }
        .modal-footer{
            .cancel-btn{
                padding: 0.5vh 1vw;
                color: #333;
                border: 1px solid #ccc;
                border-radius: 0.5vh;
                background: #e9ecef;
                outline: none;
                font-size: 2vh;
                &:hover{
                    background: #d2d2d2;
                }
            }
            .create-btn{
                padding: 0.6vh 1vw;
                color: #333;
                border: none;
                border-radius: 0.5vh;
                background: #d98c15;
                outline: none;
                font-size: 2vh;
                &:hover{
                    background: #BF7400;
                }
            }
        }
    }
}