.events-details {
  padding: 0;
}
.events-details .register-header {
  border: 1px solid #DDDDDD;
  margin-bottom: 10px;
  padding: 0.7vh 0 0.5vh 0;
}
.events-details .register-header .time-filter {
  margin: 0 10px;
}
.events-details .register-header .event-filter {
  position: relative;
  display: inline-block;
}
.events-details .register-header .register-filter .slide-switch {
  top: 3px;
}
.events-details .register-header .show-filter {
  position: absolute;
  width: 350px;
  top: 33px;
  right: 16px;
  background: #fff;
}
.events-details .register-header .picky {
  width: 100% !important;
  white-space: nowrap;
}
.events-details .register-header .picky .picky__input {
  text-overflow: ellipsis;
}
.events-details .register-header .mintomax {
  position: absolute;
  top: 50%;
  right: -0.5vh;
  font-size: 2vh;
  transform: translate(-50%, -50%);
}
.events-details .register-header .dropdown-badge .dropdown-menu {
  background: #fff !important;
  padding: 1.5vh !important;
}
.events-details .register-header .dropdown-badge .badge-position {
  position: absolute;
  background: #C53E3E;
  top: -6px;
  width: 1.9vh;
  height: 1.9vh;
  padding: 0.12rem 0.38rem 0.12rem 0.38rem;
  right: 24px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.3vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-details .register-header .dropdown-badge .btn-outline-secondary {
  color: #333 !important;
  border-color: #ccc !important;
  background: #fff !important;
  padding: 0.75vh 1vh;
}
.events-details .register-header .dropdown-badge .btn-outline-secondary:focus {
  box-shadow: none !important;
  background: none !important;
}
.events-details .register-header .dropdown-badge .btn-outline-secondary:disabled {
  color: #a5aaaf;
  border-color: #ccc;
}
.events-details .register-header .dropdown-badge .btn-outline-secondary:hover {
  background: #e6e6e6 !important;
  color: #333 !important;
  border-color: #ccc !important;
}
.events-details .register-header .dropdown-badge .btn-default {
  border-color: #ccc;
}
.events-details .register-header .dropdown-badge .btn-default:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.events-details .register-header .dropdown-badge .btn-default:disabled {
  border-color: #ccc;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem;
}
.events-details .register-header .dropdown-badge .btn-default:disabled:hover {
  background-color: #fff !important;
}

.events-table {
  min-height: 80vh;
  max-height: 80vh;
  overflow: auto;
}
.events-table .alink {
  color: #3b73af;
}
.events-table .alink:hover {
  cursor: pointer;
  text-decoration: underline;
}
.events-table .ReactTable .rt-thead .rt-th,
.events-table .ReactTable .rt-tbody .rt-td {
  border: none !important;
  text-align: left !important;
  color: #333;
  padding: 1.1vh !important;
}
.events-table .ReactTable .-striped .rt-tr.-odd {
  background: #F0EFEF;
}

