.monitor .loader-wrap {
  margin-top: 3vh;
}
.monitor .content-right .picky .picky__dropdown {
  left: 0px !important;
  right: auto !important;
}
.monitor .content-right .site-View {
  position: relative;
  height: 46vh;
}
.monitor .content-right .site-View .row {
  height: 42vh;
}
.monitor .content-right .site-View .property-view {
  height: 41.7vh;
}
.monitor .content-right .site-View .no-disp-text {
  line-height: 45vh;
}
.monitor .content-right .site-View .onhvr-expnd {
  cursor: pointer;
  padding: 0.7vh;
  display: inline-block;
  background-color: #DDDDDD;
  border: 0.1vh solid #c3c3c3;
  position: absolute;
  top: 45%;
  z-index: 999;
}
.monitor .content-right .site-View .onhvr-expnd-left {
  cursor: pointer;
  padding: 0.7vh;
  display: inline-block;
  background-color: #DDDDDD;
  border: 0.1vh solid #c3c3c3;
  position: absolute;
  top: 51%;
  z-index: 999;
}
.monitor .content-right .site-View .left-0 {
  left: 0;
}
.monitor .content-right .site-View .right-0 {
  right: 0;
}
.monitor .content-right .site-View .property-table {
  text-align: center;
  font-size: 0.8em;
  border: 1px solid #bdbbbb;
  width: 100%;
  display: block;
  max-height: 32vh;
  overflow-y: auto;
  position: relative;
}
.monitor .content-right .site-View .property-table thead, .monitor .content-right .site-View .property-table tbody, .monitor .content-right .site-View .property-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.monitor .content-right .site-View .property-table thead {
  background: #e5e5e5;
  position: sticky;
  top: 0;
}
.monitor .content-right .site-View .property-table thead th {
  border: 1px solid #bdbbbb;
}
.monitor .content-right .site-View .property-table thead th:nth-child(1) {
  width: 6vw;
}
.monitor .content-right .site-View .property-table tbody td {
  border: 1px solid #bdbbbb;
}
.monitor .content-right .site-View .property-table tbody td:nth-child(1) {
  width: 6vw;
}
.monitor .content-right .float-left.col-form-label .btn.btnStyling.date-icon.icon-width.float-right {
  padding-top: 0.55rem !important;
  padding-bottom: 0.636rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0 0.3vw 0.3vw 0;
}

