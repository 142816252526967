.manage-classAssignment {
  margin-top: 1vh;
  .nav-tabs {
    a {
      color: #bf7400 !important;
      &:hover {
        color: #bf7400 !important;
        background-color: #eee !important;
      }
    }
    .nav-link.active {
      color: #555 !important;
      cursor: default !important;
      background-color: #fff !important;
      border-bottom-color: transparent !important;
    }
  }
  .tab-content {
    padding-top: 1.5vh;
    border: 0.1vh solid #dee2e6;
    border-top: none;
    padding-bottom: 2vh;
    height: 42vh;
    .txtarea-wid {
      resize: none;
      cursor: text;
      line-height: 2vh !important;
      &:disabled {
        background-color: #f9f9f9;
      }
    }
    .modalCancelBtn{
     &:disabled{
       pointer-events: none;
    }
  }
  .loader-wrap{
    min-height:50vh;
  }
  }
  .align-alert-center{
    position: absolute !important;
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
  .no-disp-text{
    line-height: 40vh !important;
  }
}
