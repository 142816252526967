@mixin customScrolbar{
    &::-webkit-scrollbar {
      width: 0.8vh!important;
      background: #ccc;
    }
    &::-webkit-scrollbar-thumb {
      background:#9e9e9e;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-button {
      &:single-button {
        height: 0.5vh;
      }
    }
  }

.events-details{
    .ReactTable{
        .rt-table {
            overflow: visible;
        }
        .rt-thead{
            min-width: 100%!important;
            max-width: 100%!important;
            position: sticky;
            top: 0;
            .rt-th{
                border: none!important;
                border-bottom: 0.1vh solid #ccc!important;
                text-align: left;
                padding: 1.1vh !important;
                color: #333;
            }
        }
        .rt-tbody{
            min-width: 100%!important;
            max-width: 100%!important;
            .rt-td{
                border: none!important;
                text-align: left;
                color: #333;
                &:nth-last-child(1){
                    white-space: nowrap !important;
                  }
            }
        }
    }
    .details-table{
        overflow-y: auto;
        min-height: 91vh !important;
        .Interruption_Without_Fault {
            width: 2.5vh;
            height: 2.5vh;
            text-align: center;
            display: inline-block;
            background-repeat: round;
            background-image: url('../../../resources/images/InterruptionWithoutFault.png');
        }
        .Fault_Without_Interruption {
            width: 2.5vh;
            height: 2.5vh;
            text-align: center;
            display: inline-block;
            background-repeat: round;
            background-image: url('../../../resources/images/FaultWithoutInterruption.png');
        }
        .Momentary_Interruption {
            width: 2.5vh;
            height: 2.5vh;
            text-align: center;
            display: inline-block;
            background-repeat: round;
            background-image: url('../../../resources/images/Momentary.png');
        }
        .Sustained_Interruption {
            width: 2.5vh;
            height: 2.5vh;
            text-align: center;
            display: inline-block;
            background-repeat: round;
            background-image: url('../../../resources/images/Sustained.png');
        }
        .LPF {
            width: 2.4vh;
            height: 2.8vh;
            text-align: center;
            display: inline-block;
            background-repeat: round;
            background-image: url('../../../resources/images/LPF.svg');
        }
        .table_SUBSTATION{
            max-height:  90vh;
            @include customScrolbar;
            overflow-x: hidden !important;
            display: inline-block;
            max-width: 100%;
            .ReactTable{
                .rt-thead{
                    .rt-th{
                        max-width: calc(74%/6)!important;
                    }
                    .rt-th:nth-child(1){
                        max-width: 5% !important;
                    }
                    .rt-th:nth-child(2){
                        max-width: 15% !important;
                    }
                    .rt-th:nth-child(5){
                        max-width: 6% !important;
                    }
                }
                .rt-tbody{
                    @include customScrolbar;
                    overflow-x: hidden !important;
                    .rt-td{
                        max-width: calc(74%/6)!important;
                    }
                    .rt-td:nth-child(1){
                        max-width: 5% !important;
                    }
                    .rt-td:nth-child(2){
                        max-width: 15% !important;
                    }
                    .rt-td:nth-child(5){
                        max-width: 6% !important;
                    }
                }
            }
        }
        .table_FEEDER{
            max-height: 90vh;
            @include customScrolbar;
            overflow-x: hidden !important;
            display: inline-block;
            width: 100%;
            .ReactTable{
                .rt-thead{
                    .rt-th{
                        max-width: calc(95%/7)!important;
                    }
                }
                .rt-tbody{
                    @include customScrolbar;
                    overflow-x: hidden !important;
                    .rt-td{
                        max-width: calc(95%/7)!important;
                    }
                }
            }
        }
        .wave-form-status-enable{
           color: #3b73af;
            &:hover{
                text-decoration: underline;
            }
        }
        .wave-form-status-disable{
            color: #b1b1b1;
            cursor: not-allowed;
        }

        // .fault-chart{
            
        // }
    }
    .efl{
        min-height: 75vh !important;
        .table_FEEDER{
            min-height: 74.2vh;
            max-height: 74.2vh;
        }
        .table_SUBSTATION{
            min-height:  74.2vh;
            max-height:  74.2vh;
        }
    }
    .map-with-table{
        min-height: 70vh!important;
        .float-left {float: left};
        .lm-size{
            padding: 1.2vh;
            margin: 1vh;
        }
        .map-section{
            min-height: 47vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .loader-wrap{
                min-height: 50vh!important;
            }
            .map-view {
                width: 100%;
                height: 47vh;
        
                .tooltip-title{
                    margin: 10px 0px;
                    font-weight: bold;
                    font-size: 14px;
                }
                .tooltip-table{
                    border: 1px solid #bdbbbb;
                    font-size:12px;
                    margin-bottom: 0;
                    thead{
                        th{
                            background-color: #E5E5E5;
                            text-align: center;
                            font-size:12px;
                            border: 1px solid #bdbbbb !important;            
                            padding: 5px;
                        }
                    }
                    tbody{
                        td{
                            text-align: center;
                            font-size:12px;
                            border: 1px solid #bdbbbb !important;            
                            padding: 5px;
                        }
                    }
                }
                .tooltip-wrap{
                    width:200px;
                }
            
                .tooltip-data{
                    display:inline-block;
                    width:120px;
                }
            
                .tooltip-links{
                    color: #D98C15;
                    margin:2px 0px;
                    padding: 0 9px;
                    border-right:1px solid grey;
                }
            
                .txt-color {
                    color: #D98C15;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                
                .tooltip-links:first-child{
                    margin-left:0;
                }
                
                .tooltip-links:last-child{
                    margin-right:0;
                    border-right:0;
                }    
            }
        }
        .f-details-table{
            min-height: 27vh;
            height: 27vh;
            position: relative;
            overflow-y: auto;
            text-align: right;
            .ReactTable{
                margin-top: -4vh;
                .rt-thead{
                    z-index: 9;
                    .rt-th{
                    }
                }
                .rt-tbody{
                    @include customScrolbar;
                    overflow-x: hidden !important;
                    .rt-td{
                        white-space: unset!important;
                    }
                }
            }

            .with-lateral-table{
                .ReactTable{
                    .rt-thead{
                        .rt-th{
                            &:nth-child(1){
                                width: 20%!important;
                            }
                            &:nth-child(2){
                                width: 15%!important;
                            }
                            &:nth-child(3){
                                width: 15%!important;
                            }
                            &:nth-child(4){
                                width: 15%!important;
                            }
                            &:nth-child(5){
                                width: 35%!important;
                            }
                        }
                    }
                    .rt-tbody{
                        .rt-td{
                            // max-width: calc(100%/5)!important;
                            // &:nth-last-child(1){
                            //     min-width: 25%!important;
                            // }  
                            &:nth-child(1){
                                width: 20%!important;
                            }
                            &:nth-child(2){
                                width: 15%!important;
                            }
                            &:nth-child(3){
                                width: 15%!important;
                            }
                            &:nth-child(4){
                                width: 15%!important;
                            }
                            &:nth-child(5){
                                width: 35%!important;
                            }             
                        }
                    }
                }
            }

            .without-lateral-table{
                .ReactTable{
                    .rt-thead{
                        .rt-th{
                            // max-width: calc(100%/4)!important;
                            &:nth-child(1){
                                width: 25%!important;
                            }
                            &:nth-child(2){
                                width: 20%!important;
                            }
                            &:nth-child(3){
                                width: 20%!important;
                            }
                            &:nth-child(4){
                                width: 35%!important;
                            }
                        }
                    }
                    .rt-tbody{
                        .rt-td{
                            // max-width: calc(100%/4)!important;
                            &:nth-child(1){
                                width: 25%!important;
                            }
                            &:nth-child(2){
                                width: 20%!important;
                            }
                            &:nth-child(3){
                                width: 20%!important;
                            }
                            &:nth-child(4){
                                width: 35%!important;
                            }                    
                        }
                    }
                }
            }

            .expand-icon{
                box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
                color: slategray;
                position: sticky;
                top: 2.5vh;
                right: 2vh;
                cursor: pointer;
                background: #fff;
                padding: 1vh 1.5vh;
                z-index: 1;
                .tooltiptext-center{
                    bottom: 81%;
                    width: max-content;
                    left: -1vh;
                    z-index: 2;
                }
            }

            .ellips-view{
                width:4vw!important; 
                font-weight: bold;
                 padding:0.4vh
            }
        }
        .f-details-table-expand{
            min-height: 91vh;
            height: 91vh;
            position: relative;
            overflow-y: auto;
            text-align: right;
           
        }
        .f-details-table-expand-efl-expand{
            min-height: 75vh;
            
        }
    }
    
    .details-action{
        padding: 1vh 0;
        .filter-left{

        }
        .filter-right{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            .picky{
                width: 100%!important;
                white-space: nowrap;
                .picky__input{
                    text-overflow: ellipsis;
                }
            }
            .mintomax{
                position: absolute;
                top: 50%;
                right: -0.5vh;
                font-size: 2vh;
                transform: translate(-50%, -50%);
            }
            .filter-badge{
                position: relative;
                .dropdown-badge{
                    .dropdown-menu{
                        background: #fff!important;
                        padding: 1.5vh!important;
                    }
                    .badge-position{
                        position: absolute;
                        background: #C53E3E;
                        top: -6px;
                        width: 1.9vh;
                        height: 1.9vh;
                        padding: 0.12rem 0.38rem 0.12rem 0.38rem;
                        left: -7px;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 1.3vh;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .btn-outline-secondary {
                        color: #333 !important;
                        border-color: #ccc !important;
                        background: #fff !important;
                        padding: 0.75vh 1vh;
                        &:focus{
                          box-shadow: none !important;
                          background: none !important;
                        }
                        &:disabled{
                          color: #a5aaaf;
                          border-color: #ccc;
                        }
                        &:hover{
                          background: #e6e6e6 !important;
                          color: #333 !important;
                          border-color: #ccc !important;
                        }
                    }
                    .btn-default{
                        padding: 0.5vh 1.5vh;
                        border-radius: .25rem!important;
                        &:disabled{
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
        .fault-line-section {
            //height: 150px;
            min-height: 15vh;
    
            .btn-div {
                width: 100%;
    
                .btn {
                    color: #111;
                    background-color: #D98C15;
                }
            }
    
            .tree-ajax-loader {
                top: 9%;
            }
    
            .blank-page {
                span {
                    top: 35px;
                }
            }
            .faultLineParentDiv {
                //height: 119px;
                min-height: 10vh;
                overflow-y: hidden !important;
                width: 100%;
                .faultLineDiv {
                    width:99%;
                    margin-top: 34px;
                    .img-div {
                        min-width: 100px;
                        max-width: 100px;
                        z-index: 100;
    
                        .img {
                            width: 20px;
                            z-index: 100;
                        }
    
                        .name {
                            margin-top: 10px;
                            text-align: center;
                            max-width: 110px;
                            min-width: 110px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
    
                    .connection-div {
                        //margin-top: -8px;
                        margin-top: -1.26%;
                        width: 100%;
                        margin-left: -50px;
                        margin-right: -50px;
                        position: relative;
    
                        .sp-distance {
                            margin-top: -20px;
                            margin-bottom: 20px;
                        }
    
                        .baselineconn {
                            width: 100%;
                            padding-left: 50px;
                            padding-right: 50px;
                        }
    
                        .sp-orange {
                            border: 2px solid #D98C15;
                            background: #D98C15;
                        }
    
                        .sp-red {
                            border: 2px solid red;
                            background: red;
                        }
    
                        .sp-green {
                            border: 2px solid green;
                            background:green;
                        }
    
                        .sp-black-after,.sp-black-before {
                            // border: 1px solid black;
                            // height: 0;
                            // min-width: 40px;
                            // margin-top: 18px;
                           // min-width: 40px;
                            flex-grow: 0.5;
                            height: 1.5px;
                            background: #000;
                            position: relative;
                            margin-top: 10px;
                            left: 0;
                        }
                        .sp-black-before:before{
                            position: absolute;
                            content: '';
                            bottom: -4.4px;
                            // right: -2px;
                            width: 0;
                            height: 0;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-right: 8px solid black;
                        }
                        .sp-black-after:after {
                            position: absolute;
                            content: '';
                            bottom: -4.4px;
                            right: -2px;
                            width: 0;
                            height: 0;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 8px solid black;
                          }
                        .faultarrowsec{
                            width: 100%;
                        }   
                        .faultarrow{
                            width: 100%;
                        }
                        .sp-icon-dis {
                            margin-top: -10.3px;
                            width: 100%;
                        }
                        .avg-dis-arrow-right, .avg-dis-arrow-left{
                            width: 100%;
                            height: 1.5px;
                            background: #000;
                            position: relative;
                            top: 0;
                            left: 0;
                            position: absolute;
                        }
                        .avg-dis-arrow-right:after{
                            position: absolute;
                            content: '';
                            bottom: -4.4px;
                            right: -2px;
                            width: 0;
                            height: 0;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 8px solid black;
                        }
                        .avg-dis-arrow-left:before{
                            position: absolute;
                            content: '';
                            bottom: -4.4px;
                            // right: -2px;
                            width: 0;
                            height: 0;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-right: 8px solid black;
                        }
                        .w-90{
                            width: 90%;
                            min-width: 130px;
                        }
                        .w-100{
                            width: 100%;
                        }
                        .avg-value-parent-span{
                            position: relative;
                            text-align: center;
                            .vertical-child-span{
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                top: 80%;
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
}

.fault-analysis-modal{
    min-width: 90vw!important;
    max-width: 90vw!important;
    .modal-content{
        .active-clr{
            background: #5CD65C!important;
            border-color: #5CD65C!important;
            color: #333333!important;
        }
        .modal-body{
            .fault-chart{
                min-height: 40vh;
            }
            .highcharts-button{
                cursor: pointer;
            }
            .collapse-btn{
                position:absolute;
                right: 0;
                margin-top: 14%;
                z-index: 9999;
                background: rgb(221, 221, 221);
                border: 0.1vh solid rgb(195, 195, 195);
                padding: 1vh 0.8vh;
                cursor: pointer;
            }
            .expand-btn{
                position: absolute;
                left: 0px;
                top: 50%;
                z-index: 9999;
                background: rgb(221, 221, 221);
                border: 0.1vh solid rgb(195, 195, 195);
                padding: 1vh 0.8vh;
                cursor: pointer;
            }
            .highcharts-series {
                opacity: 1 !important;
            }
            .nav-tabs{
                margin-top: 4vh;
            }
            .tab-content{
                min-height: 36vh;
                height: 36vh;
                border: 0.1vh solid #DDDDDD;
                border-top: none;
                @include customScrolbar;
                overflow: hidden !important;
                .tableParentDiv{
                    overflow: auto;
                    min-height: 35vh;
                    height: 35vh;
                .table{
                    thead{
                        position: sticky;
                        top: 0;
                        tr{
                            background: #ddd;
                        }
                    }
                    .phase-download-active{
                        color: #BF7400;
                        cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    .phase-download-disabled{
                        color: #bbb;
                        cursor: not-allowed;
                    }
                }
            }
                table tbody {
                    display: block;
                  }
                  
                  table thead, table tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                  }
                .table-bordered td, .table-bordered th {
                    border: 1px solid #bdbbbb;
                }
            }
            
        }
    }
    hr{
        border-width: 0.2vh !important; 
    }
}

.highcharts-tooltip-container {
    //top: 300px !important;
    z-index: 999999 !important;
  }

  .StreetViewModal{
    width: unset !important;
    max-width: 70vw;
    .modal-body{
      min-height: 60vh;
    }
}

.gm-style .gm-style-iw-c, .gm-style-iw-d{
    max-height: 50vh !important;
}