.events-details .ReactTable .rt-table {
  overflow: visible;
}
.events-details .ReactTable .rt-thead {
  min-width: 100% !important;
  max-width: 100% !important;
  position: sticky;
  top: 0;
}
.events-details .ReactTable .rt-thead .rt-th {
  border: none !important;
  border-bottom: 0.1vh solid #ccc !important;
  text-align: left;
  padding: 1.1vh !important;
  color: #333;
}
.events-details .ReactTable .rt-tbody {
  min-width: 100% !important;
  max-width: 100% !important;
}
.events-details .ReactTable .rt-tbody .rt-td {
  border: none !important;
  text-align: left;
  color: #333;
}
.events-details .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  white-space: nowrap !important;
}
.events-details .details-table {
  overflow-y: auto;
  min-height: 91vh !important;
}
.events-details .details-table .Interruption_Without_Fault {
  width: 2.5vh;
  height: 2.5vh;
  text-align: center;
  display: inline-block;
  background-repeat: round;
  background-image: url("../../../resources/images/InterruptionWithoutFault.png");
}
.events-details .details-table .Fault_Without_Interruption {
  width: 2.5vh;
  height: 2.5vh;
  text-align: center;
  display: inline-block;
  background-repeat: round;
  background-image: url("../../../resources/images/FaultWithoutInterruption.png");
}
.events-details .details-table .Momentary_Interruption {
  width: 2.5vh;
  height: 2.5vh;
  text-align: center;
  display: inline-block;
  background-repeat: round;
  background-image: url("../../../resources/images/Momentary.png");
}
.events-details .details-table .Sustained_Interruption {
  width: 2.5vh;
  height: 2.5vh;
  text-align: center;
  display: inline-block;
  background-repeat: round;
  background-image: url("../../../resources/images/Sustained.png");
}
.events-details .details-table .LPF {
  width: 2.4vh;
  height: 2.8vh;
  text-align: center;
  display: inline-block;
  background-repeat: round;
  background-image: url("../../../resources/images/LPF.svg");
}
.events-details .details-table .table_SUBSTATION {
  max-height: 90vh;
  overflow-x: hidden !important;
  display: inline-block;
  max-width: 100%;
}
.events-details .details-table .table_SUBSTATION::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.events-details .details-table .table_SUBSTATION::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.events-details .details-table .table_SUBSTATION::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-thead .rt-th {
  max-width: 12.3333333333% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-thead .rt-th:nth-child(1) {
  max-width: 5% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-thead .rt-th:nth-child(2) {
  max-width: 15% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-thead .rt-th:nth-child(5) {
  max-width: 6% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody .rt-td {
  max-width: 12.3333333333% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody .rt-td:nth-child(1) {
  max-width: 5% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody .rt-td:nth-child(2) {
  max-width: 15% !important;
}
.events-details .details-table .table_SUBSTATION .ReactTable .rt-tbody .rt-td:nth-child(5) {
  max-width: 6% !important;
}
.events-details .details-table .table_FEEDER {
  max-height: 90vh;
  overflow-x: hidden !important;
  display: inline-block;
  width: 100%;
}
.events-details .details-table .table_FEEDER::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.events-details .details-table .table_FEEDER::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.events-details .details-table .table_FEEDER::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-thead .rt-th {
  max-width: 13.5714285714% !important;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.events-details .details-table .table_FEEDER .ReactTable .rt-tbody .rt-td {
  max-width: 13.5714285714% !important;
}
.events-details .details-table .wave-form-status-enable {
  color: #3b73af;
}
.events-details .details-table .wave-form-status-enable:hover {
  text-decoration: underline;
}
.events-details .details-table .wave-form-status-disable {
  color: #b1b1b1;
  cursor: not-allowed;
}
.events-details .efl {
  min-height: 75vh !important;
}
.events-details .efl .table_FEEDER {
  min-height: 74.2vh;
  max-height: 74.2vh;
}
.events-details .efl .table_SUBSTATION {
  min-height: 74.2vh;
  max-height: 74.2vh;
}
.events-details .map-with-table {
  min-height: 70vh !important;
}
.events-details .map-with-table .float-left {
  float: left;
}
.events-details .map-with-table .lm-size {
  padding: 1.2vh;
  margin: 1vh;
}
.events-details .map-with-table .map-section {
  min-height: 47vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-details .map-with-table .map-section .loader-wrap {
  min-height: 50vh !important;
}
.events-details .map-with-table .map-section .map-view {
  width: 100%;
  height: 47vh;
}
.events-details .map-with-table .map-section .map-view .tooltip-title {
  margin: 10px 0px;
  font-weight: bold;
  font-size: 14px;
}
.events-details .map-with-table .map-section .map-view .tooltip-table {
  border: 1px solid #bdbbbb;
  font-size: 12px;
  margin-bottom: 0;
}
.events-details .map-with-table .map-section .map-view .tooltip-table thead th {
  background-color: #E5E5E5;
  text-align: center;
  font-size: 12px;
  border: 1px solid #bdbbbb !important;
  padding: 5px;
}
.events-details .map-with-table .map-section .map-view .tooltip-table tbody td {
  text-align: center;
  font-size: 12px;
  border: 1px solid #bdbbbb !important;
  padding: 5px;
}
.events-details .map-with-table .map-section .map-view .tooltip-wrap {
  width: 200px;
}
.events-details .map-with-table .map-section .map-view .tooltip-data {
  display: inline-block;
  width: 120px;
}
.events-details .map-with-table .map-section .map-view .tooltip-links {
  color: #D98C15;
  margin: 2px 0px;
  padding: 0 9px;
  border-right: 1px solid grey;
}
.events-details .map-with-table .map-section .map-view .txt-color {
  color: #D98C15;
  cursor: pointer;
}
.events-details .map-with-table .map-section .map-view .txt-color:hover {
  text-decoration: underline;
}
.events-details .map-with-table .map-section .map-view .tooltip-links:first-child {
  margin-left: 0;
}
.events-details .map-with-table .map-section .map-view .tooltip-links:last-child {
  margin-right: 0;
  border-right: 0;
}
.events-details .map-with-table .f-details-table {
  min-height: 27vh;
  height: 27vh;
  position: relative;
  overflow-y: auto;
  text-align: right;
}
.events-details .map-with-table .f-details-table .ReactTable {
  margin-top: -4vh;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-thead {
  z-index: 9;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.events-details .map-with-table .f-details-table .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-thead .rt-th:nth-child(1) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-thead .rt-th:nth-child(2) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-thead .rt-th:nth-child(3) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-thead .rt-th:nth-child(4) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-thead .rt-th:nth-child(5) {
  width: 35% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(1) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(2) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(3) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(4) {
  width: 15% !important;
}
.events-details .map-with-table .f-details-table .with-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(5) {
  width: 35% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-thead .rt-th:nth-child(1) {
  width: 25% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-thead .rt-th:nth-child(2) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-thead .rt-th:nth-child(3) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-thead .rt-th:nth-child(4) {
  width: 35% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(1) {
  width: 25% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(2) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(3) {
  width: 20% !important;
}
.events-details .map-with-table .f-details-table .without-lateral-table .ReactTable .rt-tbody .rt-td:nth-child(4) {
  width: 35% !important;
}
.events-details .map-with-table .f-details-table .expand-icon {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: slategray;
  position: sticky;
  top: 2.5vh;
  right: 2vh;
  cursor: pointer;
  background: #fff;
  padding: 1vh 1.5vh;
  z-index: 1;
}
.events-details .map-with-table .f-details-table .expand-icon .tooltiptext-center {
  bottom: 81%;
  width: max-content;
  left: -1vh;
  z-index: 2;
}
.events-details .map-with-table .f-details-table .ellips-view {
  width: 4vw !important;
  font-weight: bold;
  padding: 0.4vh;
}
.events-details .map-with-table .f-details-table-expand {
  min-height: 91vh;
  height: 91vh;
  position: relative;
  overflow-y: auto;
  text-align: right;
}
.events-details .map-with-table .f-details-table-expand-efl-expand {
  min-height: 75vh;
}
.events-details .details-action {
  padding: 1vh 0;
}
.events-details .details-action .filter-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.events-details .details-action .filter-right .picky {
  width: 100% !important;
  white-space: nowrap;
}
.events-details .details-action .filter-right .picky .picky__input {
  text-overflow: ellipsis;
}
.events-details .details-action .filter-right .mintomax {
  position: absolute;
  top: 50%;
  right: -0.5vh;
  font-size: 2vh;
  transform: translate(-50%, -50%);
}
.events-details .details-action .filter-right .filter-badge {
  position: relative;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .dropdown-menu {
  background: #fff !important;
  padding: 1.5vh !important;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .badge-position {
  position: absolute;
  background: #C53E3E;
  top: -6px;
  width: 1.9vh;
  height: 1.9vh;
  padding: 0.12rem 0.38rem 0.12rem 0.38rem;
  left: -7px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.3vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-outline-secondary {
  color: #333 !important;
  border-color: #ccc !important;
  background: #fff !important;
  padding: 0.75vh 1vh;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-outline-secondary:focus {
  box-shadow: none !important;
  background: none !important;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-outline-secondary:disabled {
  color: #a5aaaf;
  border-color: #ccc;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-outline-secondary:hover {
  background: #e6e6e6 !important;
  color: #333 !important;
  border-color: #ccc !important;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-default {
  padding: 0.5vh 1.5vh;
  border-radius: 0.25rem !important;
}
.events-details .details-action .filter-right .filter-badge .dropdown-badge .btn-default:disabled {
  pointer-events: none;
}
.events-details .fault-line-section {
  min-height: 15vh;
}
.events-details .fault-line-section .btn-div {
  width: 100%;
}
.events-details .fault-line-section .btn-div .btn {
  color: #111;
  background-color: #D98C15;
}
.events-details .fault-line-section .tree-ajax-loader {
  top: 9%;
}
.events-details .fault-line-section .blank-page span {
  top: 35px;
}
.events-details .fault-line-section .faultLineParentDiv {
  min-height: 10vh;
  overflow-y: hidden !important;
  width: 100%;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv {
  width: 99%;
  margin-top: 34px;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .img-div {
  min-width: 100px;
  max-width: 100px;
  z-index: 100;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .img-div .img {
  width: 20px;
  z-index: 100;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .img-div .name {
  margin-top: 10px;
  text-align: center;
  max-width: 110px;
  min-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div {
  margin-top: -1.26%;
  width: 100%;
  margin-left: -50px;
  margin-right: -50px;
  position: relative;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-distance {
  margin-top: -20px;
  margin-bottom: 20px;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .baselineconn {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-orange {
  border: 2px solid #D98C15;
  background: #D98C15;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-red {
  border: 2px solid red;
  background: red;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-green {
  border: 2px solid green;
  background: green;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-black-after, .events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-black-before {
  flex-grow: 0.5;
  height: 1.5px;
  background: #000;
  position: relative;
  margin-top: 10px;
  left: 0;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-black-before:before {
  position: absolute;
  content: "";
  bottom: -4.4px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8px solid black;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-black-after:after {
  position: absolute;
  content: "";
  bottom: -4.4px;
  right: -2px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid black;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .faultarrowsec {
  width: 100%;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .faultarrow {
  width: 100%;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .sp-icon-dis {
  margin-top: -10.3px;
  width: 100%;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-dis-arrow-right, .events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-dis-arrow-left {
  width: 100%;
  height: 1.5px;
  background: #000;
  position: relative;
  top: 0;
  left: 0;
  position: absolute;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-dis-arrow-right:after {
  position: absolute;
  content: "";
  bottom: -4.4px;
  right: -2px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid black;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-dis-arrow-left:before {
  position: absolute;
  content: "";
  bottom: -4.4px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8px solid black;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .w-90 {
  width: 90%;
  min-width: 130px;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .w-100 {
  width: 100%;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-value-parent-span {
  position: relative;
  text-align: center;
}
.events-details .fault-line-section .faultLineParentDiv .faultLineDiv .connection-div .avg-value-parent-span .vertical-child-span {
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 80%;
  font-size: 8px;
}

.fault-analysis-modal {
  min-width: 90vw !important;
  max-width: 90vw !important;
}
.fault-analysis-modal .modal-content .active-clr {
  background: #5CD65C !important;
  border-color: #5CD65C !important;
  color: #333333 !important;
}
.fault-analysis-modal .modal-content .modal-body .fault-chart {
  min-height: 40vh;
}
.fault-analysis-modal .modal-content .modal-body .highcharts-button {
  cursor: pointer;
}
.fault-analysis-modal .modal-content .modal-body .collapse-btn {
  position: absolute;
  right: 0;
  margin-top: 14%;
  z-index: 9999;
  background: rgb(221, 221, 221);
  border: 0.1vh solid rgb(195, 195, 195);
  padding: 1vh 0.8vh;
  cursor: pointer;
}
.fault-analysis-modal .modal-content .modal-body .expand-btn {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 9999;
  background: rgb(221, 221, 221);
  border: 0.1vh solid rgb(195, 195, 195);
  padding: 1vh 0.8vh;
  cursor: pointer;
}
.fault-analysis-modal .modal-content .modal-body .highcharts-series {
  opacity: 1 !important;
}
.fault-analysis-modal .modal-content .modal-body .nav-tabs {
  margin-top: 4vh;
}
.fault-analysis-modal .modal-content .modal-body .tab-content {
  min-height: 36vh;
  height: 36vh;
  border: 0.1vh solid #DDDDDD;
  border-top: none;
  overflow: hidden !important;
}
.fault-analysis-modal .modal-content .modal-body .tab-content::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.fault-analysis-modal .modal-content .modal-body .tab-content::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.fault-analysis-modal .modal-content .modal-body .tab-content::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv {
  overflow: auto;
  min-height: 35vh;
  height: 35vh;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv .table thead {
  position: sticky;
  top: 0;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv .table thead tr {
  background: #ddd;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv .table .phase-download-active {
  color: #BF7400;
  cursor: pointer;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv .table .phase-download-active:hover {
  text-decoration: underline;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .tableParentDiv .table .phase-download-disabled {
  color: #bbb;
  cursor: not-allowed;
}
.fault-analysis-modal .modal-content .modal-body .tab-content table tbody {
  display: block;
}
.fault-analysis-modal .modal-content .modal-body .tab-content table thead, .fault-analysis-modal .modal-content .modal-body .tab-content table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.fault-analysis-modal .modal-content .modal-body .tab-content .table-bordered td, .fault-analysis-modal .modal-content .modal-body .tab-content .table-bordered th {
  border: 1px solid #bdbbbb;
}
.fault-analysis-modal hr {
  border-width: 0.2vh !important;
}

.highcharts-tooltip-container {
  z-index: 999999 !important;
}

.StreetViewModal {
  width: unset !important;
  max-width: 70vw;
}
.StreetViewModal .modal-body {
  min-height: 60vh;
}

.gm-style .gm-style-iw-c, .gm-style-iw-d {
  max-height: 50vh !important;
}

