.manageSchedules .device-management-ajax-loader {
  left: 100vh !important;
}
.manageSchedules .btn-outline-secondary {
  color: #333 !important;
  border-color: #ccc !important;
  background: #fff !important;
}
.manageSchedules .btn-outline-secondary:focus {
  box-shadow: none !important;
  background: none !important;
}
.manageSchedules .btn-outline-secondary .dropdown-toggle {
  background: #fff !important;
  border-color: #ccc !important;
  color: #333 !important;
}
.manageSchedules .btn-outline-secondary .dropdown-toggle:focus {
  box-shadow: none !important;
  background: none !important;
}
.manageSchedules .btn-outline-secondary .dropdown-toggle:disabled {
  color: #a5aaaf;
  border-color: #ccc;
}
.manageSchedules .btn-outline-secondary .dropdown-toggle:hover:not([disabled]) {
  background: #e6e6e6 !important;
  color: #333 !important;
  border-color: #ccc !important;
}
.manageSchedules .btn-outline-secondary .show .dropdown-toggle {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #6c757d !important;
}
.manageSchedules .btn-outline-secondary .show .dropdown-menu.show {
  background: #e6e5e5 !important;
  padding: 1.2vh;
  width: auto;
  min-width: 8vw !important;
  right: 4.2vh !important;
  top: -4vh !important;
  z-index: 990;
  line-height: 3vh;
  overflow: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  max-height: 75vh;
}
.manageSchedules .show > .btn-outline-secondary.dropdown-toggle {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #6c757d !important;
}
.manageSchedules .curptr {
  cursor: pointer;
}

