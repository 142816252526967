@mixin ep-action-btn{
  padding: 0.4vh 1.5vh .4vh;
  border-radius: 0.5vh;
  font-size: 1.5vh;
}
@mixin customScrolbar{
  &::-webkit-scrollbar {
    width: 0.8vh!important;
    background: #e5e5e5;
   
  }
  &::-webkit-scrollbar-thumb {
    background:#9e9e9e; 
    border-radius: 20px;
  }
  &::-webkit-scrollbar-button {
    &:single-button {
      height: 0.5vh;
    }
  }
}
@mixin table-area {
  padding: 1vh;
  border: 1px solid #e5e5e5;
  min-height: 36vh;
}

@mixin custom-file-upload{
  border: 0.1vh solid #ccc;
  display: inline-block;
  padding: .4vh 1vh;
  border-radius: 0.5vh;
  margin-left: 0.5vh;
}

@mixin cancel-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 0.5vh;
  background: #e9ecef;
  outline: none;
  font-size: 2vh;
  &:hover{
      background: #d2d2d2;
  }
}

@mixin create-btn {
  padding: 0.6vh 1vw;
  color: #333;
  border: none;
  border-radius: 0.5vh;
  background: #d98c15;
  outline: none;
  font-size: 2vh;
  &:hover{
      background: #BF7400;
  }
}

.e-profile{
  .ep-name{
      white-space: unset!important;
      color: rgb(217, 140, 21)!important;
      &:hover{
          text-decoration: underline;
      }
  }
  .blankPageborder {
    border: 1px solid #E5E5E5;
  }
  .ep-table-section{
      width: 100%; 
      min-height: 30vh;
      position: relative;

      .ReactTable{
        .rt-table {
            border: 1px solid rgba(0,0,0,0.1) !important;
        }
        .rt-thead{
            .rt-th{
                white-space: unset!important;
                &:nth-last-child(1){
                  border-right: 1px solid #ccc!important;
              }
            }
        }
        .rt-tbody{
            max-height: 30vh;
            .rt-td{
                white-space: unset!important;
                border-right: 0px!important;
                border-top: 0px!important;
                &:nth-last-child(1){
                  border-right: 1px solid #ccc!important;
              }
              }
            }
        }
        .pagination-bottom {
            margin-bottom: 1vh !important;
        }
    }

  .cprofile-table .ReactTable .rt-tbody {
    max-height: 20vh !important;
  }

  .e-profile-table-top{
      margin-bottom: 1.5vh;
      .p-0-m-0{
          margin: 0;
          padding: 0
      }

      .e-profile-btn-disabled{
          @include ep-action-btn;
          padding: 0vh 1.2vh 0.1vh !important;
          border: .1vh solid #e5e5e5;
          background: #fff;
          cursor: no-drop;
          color:  #c3c3c3;
          margin-left: 0.5vh;
          img{
              opacity: 0.3;
          }
      }
      .action-padding-btm{
          padding-bottom: .6vh!important
      }
      .e-profile-btn-enabled{
          @include ep-action-btn;
          border: .1vh solid #ccc;
          color: #333;
          outline: none!important;
          margin-left: 0.5vh;
          &:hover{
              background: #e6e6e6;
          }
      }
      .e-profile-btn-enabled-2{
          @include ep-action-btn;
          border: .1vh solid #ccc;
          color: #333;
          outline: none!important;
          padding: 0vh 1.2vh 0.1vh !important;
          margin-left: 0.5vh;
          cursor: pointer;
          &:hover{
              background: #e6e6e6;
          }
      }

      .filter-icon-btn{
          color: rgb(51, 51, 51);
          border-radius: 0.5vh;
          border: 0.1vh solid rgb(204, 204, 204);
          cursor: pointer;
          padding: 0.8vh 1vh 0.6vh 1vh;
          &:hover{
              background: #e6e6e6;
          }
      }

      .delete-icon-btn{
        color: rgb(51, 51, 51);
        border-radius: 0.5vh;
        border: 0.1vh solid rgb(204, 204, 204);
        cursor: pointer;
        padding: 0.9vh 1vh 0.8vh 1vh;
        &:hover{
            background: #e6e6e6;
        }
      }
      //for dropdown menu customization css start
      .dropdown-toggle{ 
          background :#fff!important;
          padding: 0!important;
          outline: none!important;
          border: none!important;
          &:focus{
              outline: none!important;
              border: none!important;
              background-color: #fff!important;
              background: #fff!important;
              padding: 0!important;
              box-shadow: none!important;
          }
          &::before{
              display: none;
          }
      }

      .dropdown-menu{
          background: #EDEDED!important;
          width: 22vh;
          padding: 10px;
          line-height: 3vh;
          top: 0.6vh!important;
      }
      //for dropdown menu customization css end
  }

  .modal-close{
      @include ep-action-btn;
            border: 0.1vh solid #ccc;
            display: inline-block;
            padding: .4vh 1vh;
            border-radius: 0.5vh;
            margin-left: 0.5vh;
            cursor: pointer;
            height: 2rem;
            margin-top: -0.2vh;
          &:hover{
              background: #e6e6e6;
          }
  }

  .nav-tabs {
      a {
          color: #BF7400;
          // font-size: 2vh;
          &:focus {
              outline: none;
          }
      }
  }
  .flex-content{
      display: inline-flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-end;
  }
  .tooltiptext-center{
      left: -4vh !important;
  }
  .tooltiptext-filter{
      left: -3.5vh !important;
      margin-bottom: 0.8vh!important
  }
  .tooltiptext-addnetwork{
      min-width: 16vh;
      left: -6vh !important;
      padding: 0.4vh 1vh;
      border-radius: 0.4vh;
  }
  .tooltiptext-close{
      left: -1vh !important;
      padding: 0.4vh 1vh;
      border-radius: 0.4vh;
  }
  .customTooltip{
      display: inline-block!important;
  }
}
.add-edit-profile {
    .formatDate {
        border-radius: 0.3vh 0 0 0.3vh;
        width: 80% !important;
        padding: 0.6vh;
        border: 0.1vh solid #ccc;
        cursor: not-allowed;
        background-color: #eee;
    }

    .date-icon {
        border: 0.1vh solid rgb(204, 204, 204);
        border-left: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 0px 0.3vh 0.3vh 0px;
        padding: 0.7vh 0;

        &:hover {
            background: #DDDDDD;
        }
    }

    .icon-width {
        width: 20% !important;
    }

    .date-input-field {
        width: 80% !important;

        input[type=text] {
            border-radius: 0.3vh 0 0 0.3vh !important;
        }
    }
}