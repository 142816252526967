.odd-row {
  background: #FAF9F9;
}

.even-row {
  background: #FFFFFF;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.row-active {
  background: #F1D7AD !important;
}

.otapDetailsModal {
  max-width: 70vw !important;
}
.otapDetailsModal .otapStatusModal {
  margin: 4.5vh;
}
.otapDetailsModal .otapStatusModal .allStatus {
  display: flex;
  justify-content: center;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList {
  text-align: center;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .faIcons {
  float: none;
  font-size: 5vw;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .faIcons svg {
  background: #fff;
  vertical-align: -1.3rem;
  border-radius: 50%;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .success {
  color: #5CD65C;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .error {
  color: #FF7474;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .inprogress {
  color: #D98C15;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .pending {
  color: #828282;
}
.otapDetailsModal .otapStatusModal .allStatus .statusList .redFont {
  color: #f00;
  padding-top: 1.5vh;
}
.otapDetailsModal .otapStatusModal .line {
  margin: 5.9rem -5.9rem;
  background: #828282;
  height: 1.5vh;
  flex-grow: 2;
}

.currentJobs .title {
  padding: 0.76vh;
  margin-bottom: 1.5vh;
  font-weight: bold;
  border: solid 0.15vh #DDDDDD;
  text-align: center;
}
.currentJobs .jobs-list {
  height: 100%;
  padding-left: 0;
}
.currentJobs .jobs-list .section-filters {
  height: 74vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.currentJobs .jobs-list .title {
  padding: 1.5vh;
  margin-top: 1.5vh;
  font-weight: bold;
  border: solid 0.15vh #DDDDDD;
  background-color: #F0EFEF;
}
.currentJobs .jobs-list .grouping-data {
  border: solid 0.15vh #DDDDDD;
  margin-top: 1.5vh;
}
.currentJobs .jobs-list .item-active {
  box-shadow: 0 0 0.4vh 0.3vh #A8A6A6;
}
.currentJobs .jobs-list .listview-ol .listview-li {
  padding: 1.2vh 1.5vh;
  margin: 0.6vh 0.3vh;
  cursor: pointer;
}
.currentJobs .jobs-list .listview-ol .listview-li .icons {
  display: table-cell;
  vertical-align: middle;
}
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-INPROGRESS {
  border: 0.15vh solid #000;
  height: 1.8vh;
  width: 0.9vw;
  margin-right: 1.5vh;
  background: #ffffff;
}
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-COMPLETED,
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-SUCCESS {
  border: 0.15vh solid #000;
  height: 1.8vh;
  width: 0.9vw;
  margin-right: 1.5vh;
  background: #5CD65C;
}
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-TRANSFER_COMPLETED {
  border: 0.15vh solid #000;
  height: 1.8vh;
  width: 0.9vw;
  margin-right: 1.5vh;
  background: #0096FF;
}
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-FAILED,
.currentJobs .jobs-list .listview-ol .listview-li .icons .job-status-FAILURE {
  border: 0.15vh solid #000;
  height: 1.8vh;
  width: 0.9vw;
  margin-right: 1.5vh;
  background: #FF7474;
}
.currentJobs .jobs-list .listview-ol .listview-li .name {
  display: table-cell;
  word-break: break-all;
}
.currentJobs .jobs-list .listview-ol .listview-li {
  margin-top: 0.3vh;
}
.currentJobs .Details {
  padding: 0;
}
.currentJobs .Details .title-header .job-name {
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  padding: 0.76vh 0;
  display: inline-block;
}
.currentJobs .Details .title-header .job-legends {
  float: right;
  padding: 1.2vh 0;
}
.currentJobs .Details .title-header .job-legends .list {
  float: left;
  list-style-type: none;
  margin: -0.76vh 1.5vh;
}
.currentJobs .Details .title-header .job-legends .list:last-of-type {
  margin: -0.76vh 0;
}
.currentJobs .Details .title-header .job-legends .list .device-status {
  border: solid 0.15vh #ddd;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  margin: -0.3vh 0.76vh 0 0;
  vertical-align: middle;
}
.currentJobs .Details .title-header .job-legends .list .device-status-COMPLETED,
.currentJobs .Details .title-header .job-legends .list .device-status-SUCCESS {
  background: #5CD65C;
}
.currentJobs .Details .title-header .job-legends .list .device-status-FAILURE,
.currentJobs .Details .title-header .job-legends .list .device-status-FAILED {
  background: #FF7474;
}
.currentJobs .Details .title-header .job-legends .list .device-status-INPROGRESS {
  background: #ffffff;
}
.currentJobs .Details .title-header .job-legends .list .device-status-TRANSFER_COMPLETED {
  background: #0096FF;
}
.currentJobs .Details .job-status {
  width: 100%;
  border: 0.15vh solid #DDDDDD;
  padding: 0.76vh 0vh;
  margin: 0;
}
.currentJobs .Details .job-status .failure {
  color: #FF7474;
}
.currentJobs .Details .job-status .success {
  color: #5CD65C;
}
.currentJobs .Details .job-status .status-tems {
  padding: 0vh 1.5vh;
}
.currentJobs .Details .job-status .status-tems .word-break-all {
  word-break: break-all;
}
.currentJobs .Details .job-status label {
  margin-bottom: 0;
  font-weight: 700;
}
.currentJobs .Details .ReactTable .rt-td {
  word-break: break-all;
  white-space: normal;
}
.currentJobs .Details .top-header {
  width: 100%;
  background-color: #F0EFEF;
  border: solid 0.15vh #E7E4E4;
  margin-top: 1vh;
}
.currentJobs .Details .top-header .device-heading {
  padding: 1.5vh;
}
.currentJobs .Details .top-header .device-button {
  padding: 0.7vh;
}
.currentJobs .Details .top-header .add-btn {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 0.15vh solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0.29vw;
  margin: 0 0.2vh;
  margin-left: 0.2vw;
}
.currentJobs .Details .top-header .add-btn:hover {
  background: #e6e6e6;
}
.currentJobs .Details .top-header .dropdown-toggle {
  background: none;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
  position: relative;
  top: 4px;
}
.currentJobs .Details .top-header .dropdown-toggle:focus {
  outline: none !important;
  border: none !important;
  background-color: none !important;
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.currentJobs .Details .top-header .dropdown-toggle::before {
  display: none;
}
.currentJobs .Details .top-header .dropdown-menu {
  background: #EDEDED !important;
  width: 13rem;
  padding: 1.5vh;
  font-size: 1rem;
  line-height: 2rem;
}
.currentJobs .Details .top-header .column-filter {
  padding: 0.27rem 0.78rem;
  font-size: 1.4rem;
}
.currentJobs .Details .device-table {
  overflow-y: auto;
  height: 59vh;
  border: 0.15vh solid #E7E4E4;
  padding-bottom: 0.5vh;
}

.dropdown .search-dropdown {
  min-width: 100%;
  max-height: 30vh;
  overflow: auto;
}
.dropdown .search-dropdown li .ddl-items {
  display: block;
  padding: 0.3vh 2vh;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
}
.dropdown .search-dropdown li .ddl-items span:hover {
  cursor: pointer;
}
.dropdown .search-dropdown li .ddl-items .margin-l-5 {
  margin-left: 0.7vh;
}

