.navbar {
  height: 4.25vh;
  min-height: 4.25vh;
  background: #333333;
  border: transparent;
  padding: 0.5vh 0.5vw !important;
}
.navbar .nav-item {
  cursor: pointer;
}
.navbar .nav-item .nav-link {
  padding: 0.85vh 1vw !important;
  color: #D98C15 !important;
  font-weight: bold;
  font-size: 0.85vw !important;
  line-height: 2.6vh;
}
.navbar .nav-item .nav-link:hover {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar .nav-item:focus {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar .nav-item .activeClass {
  background: #D98C15 !important;
  color: #333333 !important;
  pointer-events: none;
}
.navbar .nav-item .disabled {
  opacity: 0.5 !important;
  pointer-events: none;
  background: none !important;
  box-shadow: unset !important;
}
.navbar .nav-item .main-menu-disabled {
  pointer-events: unset;
  cursor: not-allowed !important;
}
.navbar .navbar-brand {
  width: 2.5vw;
}
.navbar .navbar-brand .logo {
  background-image: url("../resources/images/logo.png");
  width: 2.4rem;
  height: 2rem;
  max-height: 4vh;
  max-width: 4.4vh;
  background-size: 100% 100%;
  background-position: 0, 0;
}
.navbar .dropdown-menu {
  background: #333333;
  min-width: max-content !important;
  border: none;
  border-radius: 0 0 0.5vh 0.5vh;
}
.navbar .dropdown-menu a {
  line-height: 1vh !important;
  font-size: 0.8vw !important;
  font-weight: normal !important;
}
.navbar .dropdown-menu .dropdown-item {
  color: #D98C15;
  font-family: "Open Sans", sans-serif !important;
}
.navbar .dropdown-menu .dropdown-item:hover, .navbar .dropdown-menu .dropdown-item:focus {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar .dropdown-menu .dropdown-item.active {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar.secondary-navbar {
  top: 4.25vh;
  z-index: 999;
  min-height: 2.8vh !important;
  height: 2.8vh !important;
  background: #E5E5E5;
  border: transparent;
  white-space: nowrap;
}
.navbar.secondary-navbar .navbar {
  background: transparent;
}
.navbar.secondary-navbar .navbar-nav {
  margin-left: 14vw;
}
.navbar.secondary-navbar .nav-item {
  margin-right: 0.5vw;
}
.navbar.secondary-navbar .nav-item .nav-link {
  padding: 0.3vh 0.7vw !important;
  color: #333333 !important;
  font-size: 0.75vw !important;
  line-height: 2vh;
}
.navbar.secondary-navbar .nav-item .nav-link:hover, .navbar.secondary-navbar .nav-item .nav-link.active {
  background: transparent !important;
  box-shadow: 0 0.5px 3px 1px #A8A6A6 !important;
}
.navbar.secondary-navbar .nav-item .nav-link.active {
  pointer-events: none;
}
.navbar .rightSideMenu .nav-item .nav-link:hover {
  background: transparent !important;
  color: #D98C15 !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show {
  background: #000000;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .nav-item a.disabledClass {
  opacity: 0.5;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .nav-item:hover a {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .nav-item:hover a.disabledClass {
  pointer-events: none;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right .nav-item .nav-link {
  padding: 1vh 2vh !important;
  font-size: 0.8vw !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right .nav-item .nav-link:hover {
  background: #D98C15 !important;
  color: #333333 !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right .nav-item a {
  padding: 0 !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right .nav-item .activeClass {
  background: transparent !important;
  color: #D98C15 !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right a {
  padding: 0 !important;
  font-size: 0.8vw !important;
}
.navbar .rightSideMenu .nav-dropdown-menu.show .dropdown-menu-right .noNavigationItemLink {
  padding: 1vh 2vh !important;
}
.navbar .rightSideMenu .update-profile-menu .dropdown-menu.show a {
  padding: 0 !important;
  font-size: 0.8vw !important;
}
.navbar .rightSideMenu .update-profile-menu .dropdown-menu.show a .noNavigationItemLink {
  padding: 1vh 2vh !important;
}
.navbar .rightSideMenu .update-profile-menu .dropdown-menu.show .customlink {
  padding: 1vh 2vh !important;
}

.bg-dark {
  background-color: #333333 !important;
}

.hr-color {
  background-color: #D98C15;
}

