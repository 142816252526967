.contextMenu {
  position: fixed;
  z-index: 10000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 400;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.contextMenu ul {
  padding: 0.5vh;
  width: 7vw;
  margin-bottom: 0px !important;
}

.contextMenu li {
  list-style: none;
  cursor: pointer;
  display: block;
  padding: 0.1vh 0.7vh;
  clear: both;
  font-size: 1.2vh;
  font-weight: 400;
  line-height: 2.5vh;
  color: #000000;
  white-space: nowrap;
}

.rstcustom__collapseButton, .rstcustom__expandButton {
  display: none;
}

.left-col .left-nav {
  display: flex;
  flex-direction: column;
}
.left-col .dropdown .node-search {
  border: 1px solid #ccc;
  border-radius: 0.4vh;
  padding: 0.5vh 1vh;
  cursor: text;
  height: 4vh;
}
.left-col .dropdown .search-dropdown {
  min-width: 100%;
  max-height: 30vh;
  overflow: auto;
}
.left-col .dropdown .search-dropdown li .ddl-items {
  display: block;
  padding: 0.3vh 2vh;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
}
.left-col .dropdown .search-dropdown li .ddl-items span:hover {
  cursor: pointer;
}
.left-col .dropdown .search-dropdown li .ddl-items .margin-l-5 {
  margin-left: 5px;
}
.left-col .rst__virtualScrollOverride {
  overflow: overlay !important;
  scroll-behavior: smooth;
}
.left-col .tree {
  flex: 1 0 50%;
  padding: 0;
  border: 1px solid #ddd;
  margin-top: 1.5vh;
  border-radius: 4px;
  position: relative;
}
.left-col .tree .treeHeight {
  height: 80vh;
  position: relative;
}
.left-col .tree .dragIcons {
  display: inline-block;
  position: absolute;
  z-index: 999;
  right: 0;
}
.left-col .tree .fa-times-circle {
  margin: 5px 5px 0 0;
}
.left-col .tree .fa-check-circle {
  color: #BF7400;
  margin: 5px 17px 0 0;
}
.left-col .tree .onhvr-expnd-clps {
  cursor: pointer;
  padding: 1.4vh 1vh;
  display: inline-block;
  background-color: #DDDDDD;
  border: 1px solid #c3c3c3;
  position: absolute;
  top: 45%;
  right: 0;
  z-index: 999;
}
.left-col .tree .rst__tree {
  /* .rstcustom__node:not(:first-child) {
      position: relative !important;
      height: 3.8vh !important;
      top: 1.8vw !important;
      min-width: 100% !important;
  }
  .rstcustom__lineBlock:not(:first-child){
      width: 1.8vw !important;
  } */
}
.left-col .tree .rst__tree .ReactVirtualized__Grid__innerScrollContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.left-col .tree .rst__tree .rstcustom__lineBlock:first-child {
  width: 0.2vw !important;
}
.left-col .tree .fas {
  display: flex !important;
  padding-left: 0.5vh;
  -webkit-text-stroke: 1px white;
  font-size: 14px !important;
}
.left-col .tree .rstcustom__rowLabel {
  position: absolute;
  padding-left: 1vh;
  top: 0px;
  line-height: 1;
  font-size: 14px !important;
}
.left-col .tree .rstcustom__rowLabel span {
  cursor: pointer;
  font-weight: 400;
}
.left-col .tree .svg-node {
  height: 15px;
  width: 15px;
  margin-top: -0.5vh;
}
.left-col .tree .fa-chevron-right:before, .left-col .tree .fa-chevron-down:before {
  padding-right: 0.6vh;
  cursor: pointer;
  margin-top: 0.2vh;
  color: #333;
}
.left-col .tree .rstcustom__rowToolbar {
  cursor: pointer;
  position: relative;
}
.left-col .tree .ReactVirtualized__Grid {
  will-change: unset !important;
}
.left-col .tree .rstcustom__rowWrapper:hover {
  opacity: 1;
}
.left-col .tree .rstcustom__collapseButton {
  display: none;
}
.left-col .tree .rstcustom__rowWrapper .rstcustom__row.rstcustom__rowLandingPad {
  display: none;
}
.left-col .form-group .btn {
  margin-left: 2px;
  padding: 3px 3px 7px 2px;
}

.tree-ajax-loader, .tree-loader {
  left: 100px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  opacity: 1;
  top: 200px;
  background-image: url("../resources/images/ajax-loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.tree-loader {
  left: 4%;
  top: 50%;
  width: 10%;
  height: 10%;
}

.modal-dialog {
  width: 600px;
  margin-top: 30px !important;
}

.rstcustom__rowSubtitle {
  display: none;
}

.selected-feature-node .rstcustom__toolbarButton:nth-child(2) span {
  box-shadow: 0px 0px 3px 2px #A8A6A6;
  padding: 2px;
}

.breadCrumb-style {
  width: 45vw;
}

.min-vh-25 {
  min-height: 25vh !important;
}
.min-vh-25 .border-ccc {
  border: 1px solid #ccc;
}
.min-vh-25 .border-ccc:focus {
  outline-color: #F1D7AD !important;
  border-color: #F1D7AD !important;
  box-shadow: inset 0 1px 1px #F1D7AD, 0 0 8px #F1D7AD !important;
}
.min-vh-25 .txt-color {
  color: #a94442;
}
.min-vh-25 .disabled-field {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.node-changes-modal {
  max-width: 35vw;
}

