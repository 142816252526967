.sites .loader-wrap {
  margin-top: 3.3vh;
}
.sites .actionDiv {
  margin-top: 4vh;
}
.sites .actionDiv .btn {
  min-width: 2vw !important;
  min-height: 3vh !important;
}
.sites .actionDiv .btn-outline-secondary {
  color: #333 !important;
  border-color: #ccc !important;
  background: #fff !important;
}
.sites .actionDiv .btn-outline-secondary:focus {
  box-shadow: none !important;
  background: none !important;
}
.sites .actionDiv .btn-outline-secondary.disabled {
  color: #a5aaaf;
  border-color: #ccc;
  pointer-events: none;
}
.sites .actionDiv .btn-outline-secondary:hover {
  background: #e6e6e6 !important;
  color: #333 !important;
  border-color: #ccc !important;
}
.sites .siteList {
  padding-top: 1.6vh !important;
}
.sites .siteList .ReactTable .rt-thead .rt-th:nth-last-child(2) {
  min-width: 13vw !important;
  max-width: 13vw !important;
}
.sites .siteList .ReactTable .rt-tbody {
  overflow: hidden;
}
.sites .siteList .ReactTable .rt-tbody .rt-td {
  white-space: normal !important;
}
.sites .siteList .ReactTable .rt-tbody .rt-td:nth-last-child(2) {
  min-width: 13vw !important;
  max-width: 13vw !important;
}
.sites .curptr {
  cursor: pointer;
}
.sites .badge-notify {
  background-color: #C53E3E;
  border-radius: 50% !important;
  width: 1.3rem;
  height: 1.3rem;
  top: -0.6rem;
  left: -2rem;
  z-index: 1;
}

.siteContainerModal .modal-body {
  height: 15vh;
}
.siteContainerModal .modal-content {
  min-width: 35vw !important;
}

