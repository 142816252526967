@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.8vw !important;
  color: #333 !important;
  font-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-tbody .rt-td {
  font-size: 0.694vw !important;
}

#root {
  height: 100%;
}

.container, .container-fluid {
  min-height: 100%;
}
.container .main, .container-fluid .main {
  padding-bottom: 10vh;
}
.container .main .content-view, .container-fluid .main .content-view {
  position: relative;
  left: 0px;
  clear: both;
  margin: 0px;
  padding: 0px;
  padding-left: 1.03vh;
  padding-right: 1.03vh;
  height: calc(100% - 8.25vh);
}

.content-height {
  height: 86vh;
}

.with-breadcrumb {
  margin-top: 3.3vh;
}

.help-texts {
  height: 5vh;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.help-texts span:first-child {
  font-size: 17px;
}

.bg-grey {
  position: absolute;
  background: #DADADA;
}

.footer {
  margin-top: -2.5vh;
}
.footer .float-right {
  margin-right: 1vh;
}

.center-element {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
  z-index: 999;
}

.time-filter-btn {
  padding-top: 0.425rem !important;
  padding-bottom: 0.425rem !important;
  padding-left: 0.85rem !important;
  padding-right: 0.85rem !important;
  cursor: pointer;
}

.time-filter-btn:hover {
  background: #d98c14;
}

.time-filter-btn {
  text-align: right;
  background: #fff;
  color: #333;
  display: inline-flex;
  border: 0.15vh solid #cccccc;
  border-radius: 4px;
}

.time-filter-btn:not(:last-child) {
  border-right: none !important;
}

.hover-underline {
  color: #d98c14;
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button {
  cursor: pointer;
  outline: none;
  border: none;
}

.blank-page {
  text-align: center;
  height: 100%;
  padding: 7vh;
}

.blank-page span {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
  left: 48%;
  z-index: 9;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip {
  padding: 0.5vh;
  line-height: 1.5vh;
  pointer-events: none;
}

.custom-pagination {
  margin: 1.3vh 0 !important;
}
.custom-pagination .pagination-btn {
  cursor: pointer;
  color: #d98c14;
  margin-left: 2.2vw;
}
.custom-pagination .pagination-disable-btn {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
  margin-left: 2.2vw;
}

.reset-custom-pagination {
  margin: unset !important;
  height: unset !important;
}

.search-and-select .dropdown-icon {
  display: flex;
}
.search-and-select .dropdown-icon::after {
  position: absolute;
  top: 0.2vh;
  right: 0.8vw;
  z-index: 9;
  content: " ▼";
  float: right;
  font-size: 0.75em;
  margin-top: 0.4vh;
}
.search-and-select .select-list-section {
  max-height: 30vh;
  width: 97%;
  position: absolute;
  top: 3.2vh;
  z-index: 999999;
  border: 1px solid #ced4da;
  border-radius: 0.5vh;
  outline: none;
}
.search-and-select .select-list-item {
  padding: 0.4vh 2.5vh;
  cursor: pointer;
}
.search-and-select .select-list-item:hover {
  background: #e7e7e7;
}

.sort-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.2vh;
  width: 2.2vh;
  border-radius: 50%;
  background: #007bff;
  color: white;
  font-size: 1.5vh;
  font-weight: normal;
}

.form-err-border-red {
  border: 1px solid #a94442 !important;
}

.form-err-color-red {
  color: #a94442 !important;
}

.disabled-toggle {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.7;
}

.toggle-switch {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #ccc;
  overflow: hidden;
}
.toggle-switch .switch-on {
  left: 0 !important;
}
.toggle-switch .switch-off {
  left: -50% !important;
}
.toggle-switch .toggle-switch-animate {
  position: relative;
  width: 150%;
  -webkit-transition: left 0.5s;
}
.toggle-switch .toggle-switch-animate .span {
  display: inline-block;
  text-align: center;
  width: 33%;
  padding: 0.6vh;
  white-space: nowrap;
}
.toggle-switch .toggle-switch-animate .switch-left {
  background: #D98C15;
  color: #333333;
}
.toggle-switch .toggle-switch-animate .switch-right {
  color: #333;
  background: #f0f0f0;
}
.toggle-switch .toggle-switch-animate .switch-mdl {
  border-left: 0.1vh solid #ccc;
  border-right: 0.1vh solid #ccc;
  background-color: #f5f5f5;
  width: 34%;
}

.customTooltip {
  position: relative;
  display: inline-flex !important;
}
.customTooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 999999;
}
.customTooltip .tooltiptext {
  visibility: hidden;
  line-height: normal;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.6vh 1vh;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
}
.customTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

div#action {
  bottom: 1.5vh !important;
}

div#mainMenu {
  top: 1vh !important;
}

.checkbox-tree .tree-with-checkbox {
  padding-left: 0 !important;
  padding-right: 0.4vw !important;
}
.checkbox-tree .rstcustom__rowWrapper:hover {
  opacity: 1 !important;
}
.checkbox-tree .rstcustom__lineBlock {
  width: 1.2vw !important;
}
.checkbox-tree .rstcustom__lineBlock:first-child {
  width: 0.5vw !important;
}
.checkbox-tree .rstcustom__row {
  width: 100%;
  height: 15px;
}
.checkbox-tree .rstcustom__row .rstcustom__rowLabel {
  width: 100%;
}
.checkbox-tree .WcheckBox {
  padding-right: 2vh;
}
.checkbox-tree .ReactVirtualized__List {
  padding-right: 1vh;
}
.checkbox-tree .watchListItemPlusMinus {
  padding: 0.5vh 0 0 0;
  font-size: 1vh;
  border: 0;
  background: rgb(255, 255, 255);
  outline: none;
  float: left;
}
.checkbox-tree .watchListItemIcon {
  border: 0;
  padding: 0 0 0 0.5vw;
  background: rgb(255, 255, 255);
  outline: none;
}
.checkbox-tree .watchListItemIcon .svg-node {
  height: 15px;
  width: 15px;
  margin-top: -0.5vh;
}
.checkbox-tree .watchListItemIconSite {
  border: 0;
  outline: none;
}
.checkbox-tree .watchListItemIconSite .svg-node {
  height: 15px;
  width: 15px;
  margin-top: -0.5vh;
}
.checkbox-tree .checkboxList {
  position: absolute !important;
  right: 0 !important;
}
.checkbox-tree .label-deleted {
  font-style: italic;
  color: rgb(140, 140, 140) !important;
  font-size: 1.3vh;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-not-allowed img {
  opacity: 0.3;
}

.alert {
  width: fit-content;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.alert-dismissible .close {
  padding: 0.4rem 1rem 0.4rem 0rem !important;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.onhvr-expnd-clps2 {
  cursor: pointer;
  padding: 1.4vh 1vh;
  display: inline-block;
  background-color: #DDDDDD;
  border: 0.1vh solid #c3c3c3;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999;
}

.modalSaveBtn {
  outline: none !important;
  color: #333333 !important;
  background-color: #D98C15 !important;
  border-color: #BF7400 !important;
}
.modalSaveBtn:focus {
  outline: none !important;
  color: #333333 !important;
  background-color: #D98C15 !important;
  border-color: #BF7400 !important;
  box-shadow: none !important;
}
.modalSaveBtn:hover {
  background-color: #BF7400 !important;
}
.modalSaveBtn:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.modalCancelBtn {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #ccc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.modalCancelBtn:focus {
  outline: none !important;
  color: #333333 !important;
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
  box-shadow: none !important;
}
.modalCancelBtn:hover {
  color: #333 !important;
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
}

.modalLeftMsgDefault {
  position: absolute;
  left: 1vh;
}

.multiselect-custom-btn {
  display: block;
  padding: 0.5vh 2.5vh;
  background: rgb(191, 116, 0);
  font-weight: bold;
}

.btn-colored {
  color: #333333 !important;
  background: #D98C15 !important;
  border: 1px solid #BF7400 !important;
}

.btn-colored:hover {
  color: #333333 !important;
  background: #BF7400 !important;
  border: 1px solid #BF7400 !important;
}

.btn-non-colored {
  color: #333333 !important;
  background: #fff !important;
  border: 1px solid #ccc !important;
}

.btn-non-colored:hover {
  color: #333333 !important;
  background: #ccc !important;
  border: 1px solid #ccc !important;
}

.btn-none {
  background: transparent;
  border: none;
  cursor: pointer;
}

.custom-range::-webkit-slider-thumb {
  background: #D98C15 !important;
}
.custom-range::-webkit-slider-thumb:focus {
  background: #D98C15 !important;
}

.custom-range::-moz-range-thumb {
  background: #D98C15 !important;
}
.custom-range::-moz-range-thumb:focus {
  background: #D98C15 !important;
}

.custom-range::-ms-thumb {
  background: #D98C15 !important;
}
.custom-range::-ms-thumb:focus {
  background: #D98C15 !important;
}

/* Common Model */
.modal {
  overflow: auto !important;
}
.modal .modal-header {
  font-size: 1.75vh;
  font-weight: bold;
}
.modal .modal-height-sm .modal-content .modal-body {
  min-height: 15vh !important;
}
.modal .modal-height-md .modal-content .modal-body {
  min-height: 25vh !important;
}

/* Btn */
/*
.btn-primary, .btn-primary[disabled], .btn-primary.disabled {
  @include btn-primary;

	&:hover, &:focus {
		color: #ccc !important;
		background-color: #BF7400 !important;
		border-color: #BF7400 !important;
	}
}
*/
.btn-default, .btn-default[disabled], .btn-default.disabled {
  background: #fff !important;
  padding: 0.5vh 1.5vh;
  border: 0.15vh solid #ccc !important;
  color: #333 !important;
  outline: none;
  border-radius: 0.29vw;
  margin: 0 0.2vh;
}
.btn-default:hover, .btn-default[disabled]:hover, .btn-default.disabled:hover {
  background: #e6e6e6 !important;
}

button:focus, .btn:focus, .btn-default-focus, table:focus, a:focus {
  outline: none !important;
  box-shadow: none !important;
}

.phase-A.active {
  background-color: #FF7474 !important;
  border-color: #FF7474 !important;
  color: #333333 !important;
}

.phase-B.active {
  background-color: #5CD65C !important;
  border-color: #5CD65C !important;
  color: #333333 !important;
}

.phase-C.active {
  background-color: #7495BE !important;
  border-color: #7495BE !important;
  color: #333333 !important;
}

.phase-X1.active, .phase-X3.active {
  background-color: rgb(217, 140, 20) !important;
  border-color: rgb(217, 140, 20) !important;
  color: #333333 !important;
}

input[type=checkbox], input[type=radio] {
  width: max-content !important;
  height: max-content !important;
}

.remove-close-icon .close {
  display: none !important;
}

.border-1px {
  border: solid 0.15vh #DDDDDD;
}

/* multi select dropdown */
.picky .picky__input {
  height: auto !important;
  padding-right: 3vh !important;
  color: #333333 !important;
  border: solid 0.15vh #DDDDDD;
  border-radius: 0.43vh;
}
.picky .picky__input .picky__placeholder {
  color: #333333 !important;
}
.picky .picky__input:hover {
  background-color: #e6e6e6;
}
.picky .picky__input::after {
  top: 1.5vh !important;
  right: 0.6vh !important;
  border-top: 0.5vh solid !important;
  border-right: 0.5vh solid transparent !important;
  border-left: 0.5vh solid transparent !important;
}
.picky .picky__dropdown {
  width: unset !important;
  top: unset !important;
  left: auto !important;
  right: 0px !important;
  overflow-y: auto !important;
  min-width: 100% !important;
  background: #e5e5e5 !important;
  max-height: 50vh !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.picky .picky__dropdown li {
  background: #e5e5e5 !important;
  border-bottom: 0px !important;
  padding-right: 2vh;
}
.picky .picky__dropdown li .multiselect-custom-btn {
  display: block;
  padding: 0.5vh 2.5vh;
  background: rgb(191, 116, 0);
  font-weight: bold;
}

.single-select-dropdown .dropdown button {
  background: white;
  border-color: #ccc;
  color: #000;
  padding: 2px 10px;
  width: 100%;
}
.single-select-dropdown .dropdown button::before {
  content: " ▼";
  float: right;
  font-size: 0.75em;
  margin-top: 0.2vh;
}
.single-select-dropdown .dropdown button:focus {
  background: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  outline: 0;
}
.single-select-dropdown .dropdown button:hover {
  background: #ccc !important;
}
.single-select-dropdown .dropdown button:active {
  border: thin #cfdadd solid !important;
}
.single-select-dropdown .dropdown button:disabled {
  color: #ccc !important;
  background: transparent !important;
}
.single-select-dropdown .dropdown .single-select {
  background: #fff !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  border: 1px solid #ccc !important;
  padding: 0.76vh !important;
  text-align: left;
  cursor: pointer;
}
.single-select-dropdown .dropdown .single-select:disabled {
  cursor: default;
}
.single-select-dropdown .dropdown .dropdown-menu {
  width: 100% !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;
  max-height: 30vh;
  overflow: auto;
}
.single-select-dropdown .dropdown .dropdown-menu .dropdown-item {
  padding: 0.3vh 1.2vh !important;
}
.single-select-dropdown .caret-down {
  display: none;
}
.single-select-dropdown .show > .btn-primary.dropdown-toggle {
  color: #333 !important;
}

.dropdown-export .export-menu {
  min-width: 7vw !important;
  padding: 1vh;
  cursor: pointer;
}
.dropdown-export .export-menu ul {
  list-style-type: none;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.dropdown-export .export-menu ul li {
  display: block;
  padding: 0.8vh;
  margin: 0px;
}
.dropdown-export .export-menu ul .border-bottom {
  border-bottom: 1px solid #ddd;
}

.inline-center-item {
  display: flex;
  align-items: center;
}
.inline-center-item .checkbox-label-span {
  margin-left: 1vh;
}

.events-breadcrumb .single-select-dropdown .dropdown {
  min-width: 4vw;
  display: inline-block;
}
.events-breadcrumb .single-select-dropdown .dropdown button {
  border: none !important;
}
.events-breadcrumb .single-select-dropdown .dropdown button:hover {
  background: none !important;
}
.events-breadcrumb .single-select-dropdown .dropdown .dropdown-menu {
  width: unset !important;
}

.text-ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.export-content .dropdown-menu {
  padding: 0.5rem 0.2rem;
}
.export-content .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.export-content .dropdown-menu .dropdown-item:first-child {
  border-bottom: 1px solid #cacaca;
}

.op-half {
  opacity: 0.5;
}

.pointernone {
  pointer-events: none !important;
}

.borderForLoader {
  min-height: 50vh;
  border: 1px solid #aeaeae;
}

.timeout-dialog-modal {
  width: 40vw !important;
  max-width: 40vw !important;
}

