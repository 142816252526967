.profiles .title-div1 {
  padding-left: 1vh;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  background-color: #F0EFEF;
  border: solid 1px #DDDDDD;
}
.profiles .title-div1 .new {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  margin: 0.5vh;
  border-radius: 0.4vw;
  cursor: pointer;
  border-color: #ccc;
}
.profiles .title-div1 .new:hover {
  background: #ececec;
}
.profiles .title-view {
  display: inline-block;
  background-color: #F0EFEF;
  border: solid 1px #DDDDDD;
}
.profiles .title-view .padding-10 {
  padding: 1.03vh;
}
.profiles .title-view .btns {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  margin: 0.5vh;
  border-radius: 0.4vw;
  cursor: pointer;
  border-color: #ccc;
}
.profiles .device-type-div {
  padding-top: 0.5vh;
  margin: 0.5vh 0;
}
.profiles .profile-list-div {
  flex: 1 0 50%;
  border: 1px solid #ddd;
  height: 74vh;
  overflow: auto;
}
.profiles .lsNone {
  list-style: none;
}
.profiles .lsNone .lsNoneli {
  margin: 0.2vh;
  padding: 1.03vh;
  cursor: pointer !important;
  word-break: break-all;
}
.profiles .active-profile {
  box-shadow: 0px 0px 3px 2px #A8A6A6;
}
.profiles .padding-l-0 {
  padding-left: 0;
}
.profiles .padding-r-0 {
  padding-right: 0;
}
.profiles .tab-content {
  padding-top: 2vh;
  border: 0.1vh solid #dee2e6;
  height: 75vh;
  border-top: none;
  overflow: auto;
}
.profiles .nav-tabs a {
  color: #BF7400;
}
.profiles .nav-tabs a:focus {
  outline: none;
}
.profiles .inline-error {
  color: #ff0000 !important;
}
.profiles .inline-success {
  color: #00A259 !important;
}
.profiles .form-section {
  padding-top: 0.5vh;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 0.5vh;
}
.profiles .form-section .form-section-content-l {
  width: 50%;
  display: inline-block;
}
.profiles .form-section .form-section-content-r {
  width: 50%;
  display: inline-block;
}
.profiles .form-section .form-section-content-l-70 {
  width: 50%;
  display: inline-block;
}
.profiles .form-section .form-section-content-l-50 {
  width: 50%;
  display: inline-block;
}
.profiles .form-section .form-section-content-r-50 {
  width: 50%;
  display: inline-block;
}
.profiles .form-section .form-section-content-r-50 .dispInline {
  display: inline;
}
.profiles .form-section .form-section-content-r-50 .dispInline .sz-49 {
  width: 49%;
  float: left;
}
.profiles .form-section .form-section-content-r-50 .dispInline .ml-2p {
  margin-left: 2%;
}
.profiles .form-section .form-section-content-r-50 .rangeCls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profiles .form-section .form-section-content-r-50 .rangeCls .sz-49 {
  width: 49%;
}
.profiles .form-section .form-section-content-r-30 {
  width: 30%;
  display: inline-block;
}
.profiles .form-section .form-section-content-r-30 .dispInline {
  display: inline;
}
.profiles .form-section .form-section-content-r-30 .dispInline .sz-49 {
  width: 49%;
  float: left;
}
.profiles .form-section .form-section-content-r-30 .dispInline .ml-2p {
  margin-left: 2%;
}
.profiles .form-section .form-section-content-r-30 .rangeCls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profiles .form-section .form-section-content-r-30 .rangeCls .m0 {
  margin: 0 !important;
}
.profiles .form-section .form-header {
  font-weight: bold;
}
.profiles .form-section .form-header.LogIThreshold {
  font-size: 1vw;
}
.profiles .form-section .form-check {
  padding: 1rem 1.25rem;
}
.profiles .form-section .form-check .form-check-label span {
  font-size: 0.8vw;
}
.profiles .form-err-border-red {
  border: 1px solid red !important;
}
.profiles .bg-grey-clr {
  background-color: #E5E5E5;
  padding: 0.8vh !important;
}
.profiles .p-t-5 {
  padding-top: 1vh;
}

