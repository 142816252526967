@import '../../../resources/css/variables.scss';

.reports {
    min-height: 83.6vh;
    margin-bottom: 5vh;
    
    .report-tree-list{
        padding-top: 1vh;
        border: 0.1vh solid rgb(218, 218, 218);
        height: 30vh;
        display: grid;
        color: $wrapper-header-color !important;
    }
}

.report-status-modal {
    .modal-content {
        height: 32vh;
        .modal-header {
            .fa-exclamation-triangle {
                margin-right: 1vh;
                margin-top: .5vh;
            }
        }
    }
}
.text-color {
    color: #333 !important;
}

.p-10 {
    padding: 1.98vh;
}
.p-l-10 {
    padding-left: 1.98vh !important;
}
.p-r-10 {
    padding-right: 1.98vh !important;
}
.p-h-10 {
    padding-left: 1.98vh;
    padding-right: 1.98vh;
}

.m-t-1 {
    margin-top: 1vh !important;
}
.m-b-1 {
    margin-bottom: 1vh !important;
}
.alert-p-b-1{
    padding-bottom: 1vh;
}

.report-tree-list {
    .rstcustom__rowToolbar {
        .tree-wth-checkbox {
            cursor: pointer;
        }
    }
    .rstcustom__rowLabel span{
        cursor: pointer;
    }
}