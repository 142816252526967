.disturbances .content-right .date-input-field {
  width: 80% !important;
}
.disturbances .content-right .disturbance-filter .formatDate {
  border-radius: 0.3vh 0 0 0.3vh;
  width: 100% !important;
  padding: 0.4vh;
  border: 0.1vh solid #ccc;
  cursor: not-allowed;
  background-color: #eee;
  height: 3.4vh;
}
.disturbances .content-right .disturbance-filter .date-icon {
  border: 0.1vh solid rgb(204, 204, 204);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0.3vh 0.3vh 0;
  padding: 0.55vh 0;
  height: 3.4vh;
}
.disturbances .content-right .disturbance-filter .date-icon:hover {
  background: #DDDDDD;
}
.disturbances .content-right .disturbance-filter .icon-width {
  width: 20% !important;
}
.disturbances .content-right .disturbance-filter .apply-btn-area {
  display: flex;
  justify-content: flex-end;
}
.disturbances .content-right .disturbance-filter .apply-btn-area .apply-btn {
  padding: 0vh 1.5vh;
  height: 3.4vh;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5vh;
  outline: none;
}
.disturbances .content-right .disturbance-filter .apply-btn-area .apply-btn:hover {
  background: #ddd;
  border: 0.1vh solid #ccc;
}
.disturbances .content-right .option-list .single-select {
  width: auto !important;
}
.disturbances .content-right .option-list .dropdown-menu {
  width: auto !important;
}
.disturbances .content-right .phase-size {
  width: 1.4vh;
  height: 1.4vh;
  display: inline-flex;
  margin: 0 0.5vh;
}
.disturbances .content-right .tab-content {
  border: 0.1vh solid #ddd;
  border-top: none;
  padding: 1.2vh;
}
.disturbances .content-right .tab-content .counters-view-height {
  min-height: 8vh;
}
.disturbances .content-right .tab-content .counters-view-height .loader-wrap {
  position: relative;
  min-height: 25vh;
}
.disturbances .content-right .tab-content .ReactTable .rt-th:first-child {
  min-width: none !important;
  max-width: none !important;
}
.disturbances .content-right .tab-content .ReactTable .rt-td:first-child {
  min-width: none !important;
  max-width: none !important;
}
.disturbances .content-right .tab-content .column-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.disturbances .content-right .nav-tabs {
  flex-direction: row !important;
}
.disturbances .content-right .nav-tabs a {
  color: #BF7400 !important;
}
.disturbances .content-right .nav-tabs a:focus {
  outline: none;
}
.disturbances .content-right .nav-tabs .active {
  color: #495057 !important;
}
.disturbances .content-right .center-alighn-text {
  display: flex;
  align-items: center;
}
.disturbances .highcharts-column-series {
  opacity: 1 !important;
}
.disturbances .inline-block {
  display: inline-block;
}
.disturbances .bar-chart-tooltip table {
  width: 100%;
}
.disturbances .bar-chart-tooltip table tr td {
  padding: 3px 5px;
  border: solid 1px #bdbbbb;
}
.disturbances .chart-stack-Label-top {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-transform: rotate(270deg) translateX(50%) !important;
  -webkit-transform: rotate(270deg) translateX(50%) !important;
  transform: rotate(270deg) translateX(50%) !important;
  color: #5b3d3d;
  font-size: 1.4vh !important;
}
.disturbances .disturbance-chart .highcharts-container {
  height: 55vh !important;
  width: 100% !important;
}
.disturbances .disturbance-chart .set-width-height {
  width: 100%;
  height: 100%;
}

