.audit-trail .at-top-section {
  border: 0.1vh solid #DDDDDD;
  margin-top: 1vh;
  padding: 1.5vh 0;
}
.audit-trail .at-top-section .each-row-gap {
  margin-bottom: 2vh;
}
.audit-trail .at-top-section .picky__input {
  border: 0.1vh solid #ccc !important;
  height: 3.8vh !important;
}
.audit-trail .at-top-section .picky__input::after {
  right: 10px !important;
  top: 13px !important;
}
.audit-trail .at-top-section .picky__input:focus {
  outline: none !important;
}
.audit-trail .at-top-section .picky__input:hover {
  background: #eee;
}
.audit-trail .at-top-section .picky__dropdown {
  width: unset !important;
  /* bottom: 28px !important; */
  top: unset !important;
  left: 0px !important;
  right: 0px !important;
  overflow-y: auto !important;
  background: #e5e5e5 !important;
  max-height: 50vh !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.audit-trail .at-top-section .picky__dropdown li {
  background: #e5e5e5 !important;
  border-bottom: 0px !important;
}
.audit-trail .at-top-section input[type=text] {
  border-radius: 0.3vh;
  width: 100% !important;
  padding: 0.6vh;
  border: 0.1vh solid #ccc;
}
.audit-trail .at-top-section .formatDate {
  border-radius: 0.3vh 0 0 0.3vh;
  width: 100% !important;
  padding: 0.6vh;
  border: 0.1vh solid #ccc;
  cursor: not-allowed;
  background-color: #eee;
}
.audit-trail .at-top-section .date-icon {
  border: 0.1vh solid rgb(204, 204, 204);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0px 0.3vh 0.3vh 0px;
  padding: 0.75vh 0;
}
.audit-trail .at-top-section .date-icon:hover {
  background: #DDDDDD;
}
.audit-trail .at-top-section .icon-width {
  width: 20% !important;
}
.audit-trail .at-top-section .date-input-field {
  width: 80% !important;
}
.audit-trail .at-top-section .date-input-field input[type=text] {
  border-radius: 0.3vh 0 0 0.3vh !important;
}
.audit-trail .at-top-section .apply-btn-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.audit-trail .at-top-section .apply-btn-area .apply-btn {
  padding: 0.6vh 1.5vh;
  font-size: 1.5vh;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5vh;
  outline: none;
}
.audit-trail .at-top-section .apply-btn-area .apply-btn:hover {
  background: #ddd;
  border: 0.1vh solid #ccc;
}
.audit-trail .at-top-section .top-label {
  margin-top: 0.8vh;
}
.audit-trail .ReactTable {
  margin-bottom: 4vh !important;
}
.audit-trail .ReactTable .rt-th {
  color: #333;
}
.audit-trail .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
  color: #333;
}
.audit-trail .at-blankpage {
  height: 60vh;
}

