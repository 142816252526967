.system-admin .margin-top-row {
  margin-top: 2vh;
}
.system-admin .padding-0 {
  padding: 0;
}
.system-admin .custom-file-inputs {
  overflow: hidden;
  position: relative;
  border-radius: 0.456vh;
  display: inline-block;
  padding: 0.6vh 1.2vh;
  background: #f9f9f9;
  border: 0.1vh solid #c5c5c5;
  cursor: pointer;
}
.system-admin .custom-file-inputs:hover {
  background: #f9f9f9;
}
.system-admin .custom-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.6vh 1.2vh;
  cursor: pointer;
}
.system-admin .disabled-file-inputs {
  overflow: hidden;
  position: relative;
  border-radius: 0.456vh;
  display: inline-block;
  padding: 0.6vh 1.2vh;
  background: #f9f9f9;
  border: 0.1vh solid #e9e9e9;
  cursor: not-allowed;
}
.system-admin .disabled-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.6vh 1.2vh;
  cursor: not-allowed;
}
.system-admin .table-top-section {
  background: #ededed;
  padding: 0.5vh 1vh;
  border-bottom: 0.1vh solid #bdbbbb;
  display: flex;
  align-items: center;
}
.system-admin .table-top-section .font-size-2 .cursor-pointer {
  cursor: pointer;
  color: #BF7400;
}
.system-admin .table-top-section .font-size-2 .cursor-no-drop {
  cursor: no-drop;
  color: #BF7400;
}
.system-admin .table-top-section .download-sample-file {
  padding: 0.6vh 0vh;
  background: rgb(249, 249, 249);
  color: rgb(99, 99, 99);
  border: 0.1vh solid rgb(174, 174, 174);
  border-radius: 0.5vh;
  margin-left: 0.8vh;
  cursor: pointer;
}
.system-admin .table-top-section .download-sample-file .padding-download-icon {
  padding: 1vh 1.2vh;
}
.system-admin .tooltip-btn {
  padding-top: 1vh;
}
.system-admin .input-icon-color {
  color: #636363;
}
.system-admin .disabled-input-icon-color {
  color: #c3c3c3;
}
.system-admin .ReactTable {
  max-height: 33vh !important;
  border: none;
}
.system-admin .ReactTable .rt-thead {
  position: sticky;
  top: 0;
}
.system-admin .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  border-right: 0px !important;
}
.system-admin .ReactTable .rt-tbody {
  border: 0.1vh solid #ccc;
}
.system-admin .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
  border-right: 0px !important;
  border-top: 0px !important;
}
.system-admin .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  border-right: 0px !important;
}
.system-admin .ReactTable .rt-tbody .rt-td:nth-child(1) {
  border-left: 0px !important;
}
.system-admin .ReactTable .pagination-bottom {
  margin-bottom: 0;
}
.system-admin .download-btn {
  color: #bf7400;
  cursor: pointer;
}
.system-admin .download-btn:hover {
  text-decoration: underline;
}
.system-admin .margin-left-1 {
  margin-left: 0.1vh;
}
.system-admin .menufacturingFile {
  border: 0.1vh solid #bdbbbb;
  min-height: 41vh !important;
}
.system-admin .menufacturingFile .react-table-manufac {
  padding: 1vh;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 29% !important;
  max-width: 29% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 17.6% !important;
  max-width: 17.6% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 22.4% !important;
  max-width: 22.4% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-thead .rt-th:nth-child(4) {
  min-width: 14% !important;
  max-width: 14% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-thead .rt-th:nth-child(5) {
  min-width: 17% !important;
  max-width: 17% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 3.04vh;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td {
  word-break: break-all;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 29% !important;
  max-width: 29% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 17.6% !important;
  max-width: 17.6% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 22.4% !important;
  max-width: 22.4% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td:nth-child(4) {
  min-width: 14% !important;
  max-width: 14% !important;
}
.system-admin .menufacturingFile .react-table-manufac .ReactTable .rt-tbody .rt-td:nth-child(5) {
  min-width: 17% !important;
  max-width: 17% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full {
  padding: 1vh;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 29% !important;
  max-width: 29% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 17.6% !important;
  max-width: 17.6% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 22.4% !important;
  max-width: 22.4% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-thead .rt-th:nth-child(4) {
  min-width: 14% !important;
  max-width: 14% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-thead .rt-th:nth-child(5) {
  min-width: 17% !important;
  max-width: 17% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 29% !important;
  max-width: 29% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 17.6% !important;
  max-width: 17.6% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 22.4% !important;
  max-width: 22.4% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-tbody .rt-td:nth-child(4) {
  min-width: 14% !important;
  max-width: 14% !important;
}
.system-admin .menufacturingFile .react-table-manufac-full .ReactTable .rt-tbody .rt-td:nth-child(5) {
  min-width: 17% !important;
  max-width: 17% !important;
}
.system-admin .firewareFile {
  border: 0.1vh solid #bdbbbb;
  margin-left: 0;
  min-height: 41vh !important;
}
@media only screen and (max-width: 767px) {
  .system-admin .firewareFile {
    margin-left: -15px;
    margin-top: 2vh;
  }
}
.system-admin .firewareFile .react-table-firmware {
  padding: 1vh;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 47% !important;
  max-width: 47% !important;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 31.95% !important;
  max-width: 31.95% !important;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 21% !important;
  max-width: 21% !important;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 3.04vh;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 47% !important;
  max-width: 47% !important;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 32% !important;
  max-width: 32% !important;
}
.system-admin .firewareFile .react-table-firmware .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 21% !important;
  max-width: 21% !important;
}
.system-admin .firewareFile .react-table-firmware-full {
  padding: 1vh;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 47% !important;
  max-width: 47% !important;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 31.95% !important;
  max-width: 31.95% !important;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 21% !important;
  max-width: 21% !important;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 47% !important;
  max-width: 47% !important;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 32% !important;
  max-width: 32% !important;
}
.system-admin .firewareFile .react-table-firmware-full .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 21% !important;
  max-width: 21% !important;
}
.system-admin .gridMapping {
  border: 1px solid #bdbbbb;
  min-height: 41vh !important;
}
.system-admin .gridMapping .react-table-gridmapping {
  padding: 1vh;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 40% !important;
  max-width: 40% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 20.15% !important;
  max-width: 20.15% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 24.95% !important;
  max-width: 24.95% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-thead .rt-th:nth-child(4) {
  min-width: 15% !important;
  max-width: 15% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody {
  word-break: break-all;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 3.04vh;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 40% !important;
  max-width: 40% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 20.1% !important;
  max-width: 20.1% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 25% !important;
  max-width: 25% !important;
}
.system-admin .gridMapping .react-table-gridmapping .ReactTable .rt-tbody .rt-td:nth-child(4) {
  min-width: 15% !important;
  max-width: 15% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full {
  padding: 1vh;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-thead .rt-th:nth-child(1) {
  min-width: 40% !important;
  max-width: 40% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-thead .rt-th:nth-child(2) {
  min-width: 20.15% !important;
  max-width: 20.15% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-thead .rt-th:nth-child(3) {
  min-width: 24.95% !important;
  max-width: 24.95% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-thead .rt-th:nth-child(4) {
  min-width: 15% !important;
  max-width: 15% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-tbody .rt-td:nth-child(1) {
  min-width: 40% !important;
  max-width: 40% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-tbody .rt-td:nth-child(2) {
  min-width: 20.1% !important;
  max-width: 20.1% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-tbody .rt-td:nth-child(3) {
  min-width: 25% !important;
  max-width: 25% !important;
}
.system-admin .gridMapping .react-table-gridmapping-full .ReactTable .rt-tbody .rt-td:nth-child(4) {
  min-width: 15% !important;
  max-width: 15% !important;
}
.system-admin .margin-top-2vh {
  margin-top: 2vh;
}

.sysAdminErrModal .modal-body {
  height: 34vh !important;
}

