.map {
    border: solid 1px #E7E4E4;
	.map-view-header{
		width: 100%;
		background-color: #F0EFEF;
		border-bottom: solid 2px #FFFFFF;
        padding: 1.5vh 0.5vw;

		.map-title{
            .map-refresh{
                cursor: pointer;
                background: #D98C15;
                box-shadow: 0px 0px 2px grey;
                padding: 2px 5px 4px 6px;
            }
            .map-refresh:hover{
                box-shadow: 0px 0px 4px black;
            }
            .map-refresh:active{
                box-shadow: 0px 0px 1px grey;
            }

			.map-breadcrumb{
				font-weight:normal;
			}
			font-weight:bold;
			position: relative;
    		z-index: 100;
    		width: 80%;
		}

		.map-switch-btn{
			padding:2px;
		}
	}
    .map-view {
        height: 92%;

        .tooltip-title{
            margin: 10px 0px;
            font-weight: bold;
            font-size: 14px;
        }
        .tooltip-table{
            border: 1px solid #bdbbbb;
            font-size:12px;
            thead{
                th{
                    background-color: #E5E5E5;
                    text-align: center;
                    font-size:12px;
                    border: 1px solid #bdbbbb !important;            
                    padding: 5px;
                }
            }
            tbody{
                td{
                    text-align: center;
                    font-size:12px;
                    border: 1px solid #bdbbbb !important;            
                    padding: 5px;
                }
            }
        }
        .tooltip-wrap{
            width:200px;
        }
    
        .tooltip-data{
            display:inline-block;
            width:120px;
        }
    
        .tooltip-links{
            margin:2px 0px;
            padding: 0 9px;
            border-right:1px solid grey;
        }
    
        .txt-color {
            color: #D98C15;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        
        .tooltip-links:first-child{
            margin-left:0;
        }
        
        .tooltip-links:last-child{
            margin-right:0;
            border-right:0;
        }    
    }
}
.StreetViewModal{
    width: unset !important;
    max-width: 70vw;
    .modal-body{
      min-height: 60vh;
    }
}
.noDeviceDiv{
    min-height: 15vh;
}