@mixin btn{
	background: #fff;
	padding: 0.5vh 1.5vh;
	border: 0.15vh solid #ccc;
	color: #333;
	outline: none;
	border-radius: 0.29vw;
	margin: 0 0.2vh;
}
@mixin status {
	border: 0.15vh solid #000;
	height: 1.8vh;
	width: 0.9vw;
	margin-right: 1.5vh;	
}
.odd-row{
	background: #FAF9F9;
}

.even-row{
	background: #FFFFFF;
}
.disabled {
	opacity: 0.5 !important;
    pointer-events: none !important;
}
.row-active{
	background:#F1D7AD !important;
}
.otapDetailsModal{
	max-width: 70vw !important;

	.otapStatusModal{
		margin: 4.5vh;

		.allStatus{
			display: flex;
			justify-content: center;

			.statusList{
				text-align: center;
				.faIcons{
					float: none;
					font-size: 5vw;
					svg {
						background: #fff;
						vertical-align: -1.3rem;
						border-radius: 50%;
					}
				}
				.success{
					color: #5CD65C;
				}
				.error{
					color: #FF7474;
				}
				.inprogress{
					color: #D98C15;
				}
				.pending{
					color: #828282;
				}			
				.redFont{
					color: #f00;
					padding-top: 1.5vh;
				}
			}		
		}		
		.line{
			margin:5.9rem -5.9rem;
			background: #828282;
			height: 1.5vh;
			flex-grow: 2;
		}
	}
}
.currentJobs{
	// margin-top: 1.2vh;
    .title{
        padding: 0.76vh;
        margin-bottom: 1.5vh;
        font-weight: bold;
        border: solid 0.15vh #DDDDDD;
        text-align: center;
    }

    .jobs-list{
		height: 100%;
		padding-left: 0;

		.section-filters{
		    height: 74vh;			
			// height: calc(100% - 15.2vh);
            overflow-y: auto;
            overflow-x: hidden;
    	}
		
		.title{
			padding: 1.5vh;
			margin-top:1.5vh;
			font-weight: bold;
			border: solid 0.15vh #DDDDDD;
			background-color: #F0EFEF;
		}

        .grouping-data{
            border: solid 0.15vh #DDDDDD;
            margin-top:1.5vh;
        }
		.item-active{
			box-shadow: 0 0 0.4vh 0.3vh #A8A6A6;
		}
  
		.listview-ol{			
			.listview-li{
				padding: 1.2vh 1.5vh;
				margin: 0.6vh 0.3vh;
				cursor: pointer;
				.icons{
					display: table-cell;
					vertical-align: middle;
					
					.job-status-INPROGRESS{
						@include status;
						background: #ffffff;
					}
					.job-status-COMPLETED,
					.job-status-SUCCESS{
						@include status;
						background: #5CD65C;
					}
					.job-status-TRANSFER_COMPLETED{
						@include status;
						background: #0096FF;
					}
					.job-status-FAILED,
					.job-status-FAILURE{
						@include status;
						background: #FF7474;
					}
				}
				.name{
					display: table-cell;
					word-break: break-all;
				}
			}
			.listview-li{
				margin-top: 0.3vh;
			}
		}
	}
    .Details {
		padding: 0;

		.title-header{			
			.job-name{
				font-weight: bold;
				text-align: left;
				vertical-align: middle;
				padding: 0.76vh 0;
				display:inline-block
			}
			.job-legends{
				float:right;
				padding:1.2vh 0;
				
				.list{
					float:left;
					list-style-type:none;
					margin:-0.76vh 1.5vh;
					&:last-of-type{
						margin:-0.76vh 0;
					}
					.device-status{
						border: solid 0.15vh #ddd;
						height:1rem;
						width:1rem;
						display:inline-block;
						margin:-0.3vh 0.76vh 0 0;
						vertical-align: middle;
					}
					.device-status-COMPLETED,
					.device-status-SUCCESS{
						background: #5CD65C;
					}
					
					.device-status-FAILURE,
					.device-status-FAILED{
						background: #FF7474;
					}
					
					.device-status-INPROGRESS{
                        background:#ffffff;
                    }

					.device-status-TRANSFER_COMPLETED{
						background: #0096FF;
					}
				}
			}
		}

		.job-status{
			// float: left;
			width: 100%;
			border:0.15vh solid #DDDDDD;
			padding: 0.76vh 0vh;
            margin: 0;
			
			.failure{
				color: #FF7474;
			}
			.success{
				color: #5CD65C;
			}
			.status-tems{
				padding: 0vh 1.5vh;
				.word-break-all{
					word-break: break-all;
				}
			}
			label {
				margin-bottom: 0;
				font-weight: 700;
			}
		}

		.ReactTable .rt-td {			 
		word-break: break-all;
		white-space: normal;
		}

		.top-header{
			width: 100%;
			background-color: #F0EFEF;
			border: solid 0.15vh #E7E4E4;			
			margin-top: 1vh;

			.device-heading {
				padding: 1.5vh;
			}

			.device-button {
				padding: 0.7vh;
			}
			.add-btn{
				@include btn;
				margin-left: 0.2vw;
				&:hover{
					background: #e6e6e6;
				}
			}
			//for dropdown menu customization css start
			.dropdown-toggle{ 
				background :none;
				padding: 0!important;
				outline: none!important;
				border: none!important;
				position: relative;
				top: 4px;
				&:focus{
					outline: none!important;
					border: none!important;
					background-color: none!important;
					background: none!important;
					padding: 0!important;
					box-shadow: none!important;
				}
				&::before{
					display: none;
				}
			}
			.dropdown-menu{
				background: #EDEDED!important;
				width: 13rem;
				padding: 1.5vh;
				font-size: 1rem;
				line-height: 2rem;
			}
			.column-filter{
				padding: 0.27rem 0.78rem;;
				font-size: 1.4rem;
			}
		}
		.device-table {
			overflow-y: auto;
			height: 59vh;
			border: 0.15vh solid #E7E4E4;
			padding-bottom: 0.5vh;
		}
	}
}
.dropdown {
	.search-dropdown {
		min-width: 100%;
		max-height: 30vh;
		overflow: auto;
		li {
			.ddl-items {
				display: block;
				padding: 0.3vh 2vh;
				clear: both;
				font-weight: 400;
				line-height: 1.42857143;
				white-space: nowrap;

				span:hover {
					cursor: pointer;
				}
				.margin-l-5 {
					margin-left: 0.7vh;
				}
			}
		}
	}
}