@mixin customScrolbar{
    &::-webkit-scrollbar {
      width: 0.8vh!important;
      background: #ccc;
    }
    &::-webkit-scrollbar-thumb {
      background:#9e9e9e; 
      border-radius: 20px;
    }
    &::-webkit-scrollbar-button {
      &:single-button {
        height: 0.5vh;
      }
    }
  }

  @mixin mainModalTop{
    width: 100vw;
    max-width: 100vw;
    transition: transform 0s ease-out!important;
        .live-view-error {
            color: rgb(169, 68, 66);
            font-size: 1.2vh;
        }
        .lockUnlock{
                height: 1rem;
        }
        .lockunlockoutline{
            padding: 0.99vh 1.3vh;
        }
  }

  @mixin fade {
    .fade {
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none!important;
    }
  }
  @mixin modalContent {
        width: 98%;
        position: fixed;
        bottom: 0;
        left: 1%;
        .modal-header{
            display: none;
        }
  }

  @mixin deviceLiveView {
        .table-area{
            border: 1px solid #d2d2d2
        }
  }
  
.virtual-device-mngmnt{
    .position-center{
        position: absolute;
        z-index: 9;
        transform: translate(-50%, 0%);
        left: 50%;
    }
    .action-and-search-section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .search-section{
            width: 16%;
        }
    }

    .device-main-table{
        min-height:30vh;
        border: 1px solid #ced4da;
    }

    .disabled-icon{
        cursor: default!important;
        opacity: 0.35!important;
    }
    
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .checkboxList{
        top: 0!important;
    }

    .add-virtual-device{
        padding: '0 2vh 2vh 1vh 2vh';
        overflow-x: hidden;

        .add-edit-device-container{
            height: auto;
            .word-break-all {
                word-break: break-all;
            }
            .content-top{
                background: rgb(237, 237, 237);
                padding: 1.2vh 1.6vh;
                text-align: center;
                border: 1px solid rgb(189, 187, 187);
                border-bottom: none;
            }
            .section-content-body{
                border: 1px solid rgb(189, 187, 187);
                height: 93%;
                .vd-tree-view{
                    height: 41.5rem;
                    display: grid;
                }
            }
        }
        
        .single-select-dropdown {
            .dropdown {
              button {
               &:disabled{
                background-color: #e9ecef!important;
               }
              }
            }
          }

        .device-count-section{
            font-size: 1.3vh;
            font-weight: bold;
            background: rgb(237, 237, 237);
            border: 1px solid rgb(189, 187, 187);
            padding: 1.2vh 2vh;
        }

        .ReactTable{
            padding-bottom: 1.2vh;
            .rt-thead{
              .rt-th{
                &:nth-child(3){
                  width: 11.6vw!important;
                }
              }
            }
            .rt-tbody{
                .rt-tr-group{
                    .rt-td{
                        padding: 0.6vh!important;
                        &:nth-child(3){
                        width: 11.6vw!important;
                        }
                    }
                }
            }
          }
          .error-color{
            color: #a94442;
            font-size: 1.4vh;
          }
          .point-count-section{
            padding: 1.6vh 1.6vh 0 1.6vh;
          }
          .point-info-msg{
            background-color: #fff3cd;
            padding: .6vh 1vh;
            border-radius: 0.5vh;
            font-weight: normal;
            color: #664d03;
            border: 1px solid #ffecb5;
            margin-top: 1vh;
        }
    }
    .ellips-view{
        width:40px; 
        font-weight: bold;
         padding:0.4vh
    }
    .lockUnlock{
        height: 1.2rem;
    }

    .custom-file-inputs{
        overflow: hidden;
        position: relative;

        input[type=file] {
            opacity: 0;
            position: absolute;
            right: 0;
            height: 28px;
            display: inline-block;
            width: 100% !important;
            font-size: 5px !important;
            cursor: pointer !important;
            bottom: 0;
        }
      }
    .disabled-file-inputs{
        overflow: hidden;
        position: relative;
        input[type=file] {
            opacity: 0;
            position: absolute;
            right: 0;
            height: 1px!important;
            display: inline-block;
            width: 100% !important;
            font-size: 0 !important;
            bottom: 0;
        }
      }

      .disabled-input-icon-color{
        color: #c3c3c3;
      }
}

.modal{
    .custom-v-device-dialog {
        width: 95vw;
        max-width: 95vw;
        .add-edit-device-container{
            height: auto;
            .checkboxList{
                top: 0!important;
            }
            .word-break-all {
                word-break: break-all;
            }
            .content-top{
                background: rgb(237, 237, 237);
                padding: 1.2vh 2vh;
                text-align: center;
                border: 1px solid rgb(189, 187, 187);
                border-bottom: none;
            }
            .section-content-body{
                border: 1px solid rgb(189, 187, 187);
                height: 93%;
            }
        }
        .device-count-section{
            font-size: 1.3vh;
            font-weight: bold;
            background: rgb(237, 237, 237);
            border: 1px solid rgb(189, 187, 187);
            padding: 1.2vh 2vh;
        }
        .point-count-section{
            padding: 1.6vh
          }
      }
      .delete-dnpc-modal{
        .modal-content{
            min-height: 2vh!important;
        }
      }
      hr{
        border-width: 0.2vh!important;
      }

      .live-view-dnpc{
        @include mainModalTop;
        @include fade;

        .pagination-bottom{
            margin-bottom: 0!important;
            .custom-pagination{
                margin-bottom: 0!important;
            }
        }

        .position-center{
            position: absolute;
            z-index: 9;
            transform: translate(-50%, 0%);
            left: 50%;
        }

        .modal-content{
            @include modalContent;
            .modal-body{
                .device-live-view{
                    @include deviceLiveView;
                    .ReactTable{
                        .rt-tbody{
                            overflow-x: hidden!important;
                        }
                    }
                    .compress-view{
                        .ReactTable{
                            .rt-thead{
                                .rt-th{
                                  &:nth-child(12){
                                    min-width: 8vw!important;
                                 }
                                }
                              }
                            .rt-tbody{
                                overflow-y: auto!important;
                                max-height: 26vh;
                                padding-bottom: 0.1vh;
                                .rt-tr-group{
                                    .rt-td{
                                        &:nth-child(12){
                                            min-width: 8vw!important;
                                         }
                                    }
                                }
                            }
                        }
                    }
                    .expand-view{
                        .ReactTable{
                            .rt-thead{
                                .rt-th{
                                  &:nth-child(9){
                                    min-width: 10vw!important;
                                  }
                                  &:nth-child(12){
                                    min-width: 8vw!important;
                                 }
                                }
                              }
                              
                            .rt-tbody{
                                overflow-y: scroll!important;
                                max-height: 75vh;
                                padding-bottom: 0.1vh;
                                .rt-tr-group{
                                    .rt-td{
                                        &:nth-child(9){
                                            min-width: 10vw!important;
                                        }
                                        &:nth-child(12){
                                            min-width: 8vw!important;
                                         }
                                    }
                                }
                            }
                        }
                    }
                }
                .lock-loader-position{
                    position: relative;
                    left:0;
                }
            }
        }
      }

      .live-view-dnpc-window{
        @include mainModalTop;
        @include fade;
        
        .pagination-bottom{
            margin-bottom: 0!important;
            .custom-pagination{
                margin-bottom: 0!important;
            }
        }

        .modal-content{
            @include modalContent;
            .modal-body{
                .device-live-view{
                    @include deviceLiveView;
                    .ReactTable{
                        .rt-tbody{
                            overflow-x: hidden!important;
                            @include customScrolbar;
                        }
                    }
                    .compress-view{
                        .ReactTable{
                            .rt-thead{
                                .rt-th{
                                  &:nth-child(12){
                                    min-width: 8vw!important;
                                 }
                                 &:nth-last-child(1){
                                    width: 7.29%!important;
                                  }
                                }
                              }
                            .rt-tbody{
                                overflow-y: auto!important;
                                max-height: 26vh;
                                padding-bottom: 0.1vh;
                                .rt-tr-group{
                                    .rt-td{
                                        &:nth-child(12){
                                            min-width: 8vw!important;
                                         }
                                         &:nth-last-child(1){
                                            width: 7%!important;
                                          }
                                    }
                                }
                            }
                        }
                    }
                    .expand-view{
                        .ReactTable{
                            .rt-thead{
                                .rt-th{
                                  &:nth-child(9){
                                    min-width: 10vw!important;
                                  }
                                  &:nth-child(12){
                                    min-width: 8vw!important;
                                 }
                                 &:nth-last-child(1){
                                    width: 7.29%!important;
                                  }
                                }
                              }
                              
                            .rt-tbody{
                                overflow-y: scroll!important;
                                max-height: 75vh;
                                padding-bottom: 0.1vh;
                                .rt-tr-group{
                                    .rt-td{
                                        &:nth-child(9){
                                            min-width: 10vw!important;
                                        }
                                        &:nth-child(12){
                                            min-width: 8vw!important;
                                         }
                                         &:nth-last-child(1){
                                            width: 7%!important;
                                          }
                                    }
                                }
                            }
                        }
                    }
                }
                .lock-loader-position{
                    position: relative;
                    left:0;
                }
            }
        }
      }
}