.sites{
    .loader-wrap{
      margin-top: 3.3vh;
    }
    .actionDiv{
        margin-top: 4vh;
        .btn{
            min-width: 2vw !important;
            min-height: 3vh !important;
        }
        .btn-outline-secondary {
            color: #333 !important;
            border-color: #ccc !important;
            background: #fff !important;
            &:focus{
              box-shadow: none !important;
              background: none !important;
            }
            &.disabled{
              color: #a5aaaf;
              border-color: #ccc;
              pointer-events: none;
            }
            &:hover{
              background: #e6e6e6 !important;
              color: #333 !important;
              border-color: #ccc !important;
            }
        }
    }
    .siteList{
        padding-top: 1.6vh !important;
        .ReactTable{
            .rt-thead{
                .rt-th{
                  &:nth-last-child(2){
                    min-width: 13vw!important;
                    max-width: 13vw!important;
                  }
                }
            }
            .rt-tbody{
                overflow: hidden;
                .rt-td{
                    white-space:normal !important;
                    &:nth-last-child(2){
                    min-width: 13vw!important;
                    max-width: 13vw!important;
                  }
                }
            }
        }
    }  
    .curptr{
        cursor: pointer;
    }
   
  .badge-notify{
    background-color: #C53E3E;
    border-radius: 50% !important;
    width: 1.3rem;
    height: 1.3rem;
    top: -.6rem;
    left: -2rem;
    z-index: 1;   
  }
}
.siteContainerModal{
  .modal-body{
    height: 15vh;
  }
  .modal-content {
    min-width: 35vw  !important;
  }
}
