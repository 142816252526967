.manage-details .content-right {
  margin-top: 3.2vh !important;
}
.manage-details .content-right .single-select-dropdown .dropdown button {
  height: 3.8vh;
}
.manage-details .content-right .deviceList .ReactTable {
  border: none !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-thead {
  background-color: #E5E5E5;
  font-weight: bold;
  font-size: 12px;
  width: 99.99%;
}
.manage-details .content-right .deviceList .ReactTable .rt-thead .rt-resizable-header-content {
  word-break: break-word;
  white-space: normal;
}
.manage-details .content-right .deviceList .ReactTable .rt-thead .rt-th {
  padding: 1.2vh !important;
  border: 1px solid #bdbbbb !important;
  border-right: none !important;
  text-align: center;
  vertical-align: bottom;
}
.manage-details .content-right .deviceList .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  border-right: 1px solid #bdbbbb !important;
  min-width: 8vw !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-thead .rt-th:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-tbody {
  text-align: center;
  font-size: 12px !important;
  overflow: unset !important;
  width: 99.99%;
}
.manage-details .content-right .deviceList .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0px !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-td {
  border: 1px solid #bdbbbb !important;
  border-top: none !important;
  border-right: none !important;
  padding: 1.1vh !important;
  vertical-align: top;
  text-align: center;
  font-size: 12px;
  text-overflow: unset;
  white-space: normal;
  word-break: break-word;
}
.manage-details .content-right .deviceList .ReactTable .rt-td:nth-last-child(1) {
  border-right: 1px solid #bdbbbb !important;
  white-space: nowrap !important;
  min-width: 8vw !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-td:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}
.manage-details .content-right .deviceList .ReactTable .rt-td .fa-times-circle {
  color: #C53E3E;
  font-size: 1.9vh;
}
.manage-details .content-right .deviceList .ReactTable .rt-td .fa-check-circle {
  color: #75C53E;
  font-size: 1.9vh;
}
.manage-details .content-right .deviceList .ReactTable .rt-td .fa-minus-circle {
  color: #4F8EF7;
  font-size: 1.9vh;
}
.manage-details .content-right .deviceList .ReactTable .rt-td .fa-exclamation-triangle {
  color: #f0c000;
  font-size: 1.9vh;
}
.manage-details .content-right .deviceList .ReactTable .rt-td .fa-exchange-alt {
  color: #FFDB00;
  font-size: 1.9vh;
}
.manage-details .content-right .deviceList .ReactTable .pagination-bottom .-btn {
  width: 10vh !important;
  color: #D98C15 !important;
  background: none !important;
  font-size: 1.5vh !important;
  font-weight: 400;
}
.manage-details .content-right .deviceList .ReactTable .pagination-bottom .-btn:hover {
  color: #BF7400 !important;
  text-decoration: underline;
}
.manage-details .content-right .deviceList .ReactTable .pagination-bottom .-btn:disabled {
  pointer-events: none;
  color: #6C6A6A !important;
}
.manage-details .content-right .deviceList .ReactTable .pagination-bottom .-previous .-btn {
  float: right;
  margin-right: -87vh;
  position: relative;
}
.manage-details .content-right .deviceList .ReactTable .pagination-bottom .-next .-btn {
  margin-left: 22vh;
}
.manage-details .loader-wrap {
  background-color: #000;
  opacity: 15%;
}
.manage-details .alert-dismissible {
  display: flex;
  align-items: center;
}
.manage-details .alert-dismissible .close {
  top: auto;
}

