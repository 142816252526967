.manage-details {
    .content-right {
      margin-top: 3.2vh !important;
      .single-select-dropdown {
        .dropdown {
          button {
            height: 3.8vh;
          }
        }
      }      
      .deviceList {
        .ReactTable {
          border: none !important;
          .rt-thead{
            background-color: #E5E5E5;
            font-weight: bold;
            font-size: 12px;
            width: 99.99%;
            .rt-resizable-header-content{
              word-break: break-word;
              white-space: normal;
            }
            .rt-th {
              padding: 1.2vh !important;
              border: 1px solid #bdbbbb !important;
              border-right: none !important;
              text-align: center;
              vertical-align: bottom;
              &:nth-last-child(1){
                border-right: 1px solid #bdbbbb !important;
                min-width:8vw !important;
              }
              &:first-child {
                min-width: 4vw!important;
                max-width: 4vw!important;
              }
            }
          }
          .rt-tbody {
            text-align: center;
            font-size: 12px !important;
            overflow: unset !important;
            width: 99.99%;
            .rt-tr-group{
              border-bottom: 0px !important;
            }
          }
          .rt-td {
            border: 1px solid #bdbbbb !important;
            border-top:none !important;
            border-right: none !important;
            padding: 1.1vh !important;
            vertical-align: top;
            text-align: center;
            font-size: 12px;
            text-overflow: unset;
            white-space: normal;
            word-break: break-word;
            &:nth-last-child(1){
              border-right: 1px solid #bdbbbb !important;
              white-space: nowrap !important;
              min-width:8vw !important;
            }
            &:first-child {
              min-width: 4vw!important;
              max-width: 4vw!important;
            }
            .fa-times-circle {
              color: #C53E3E;
              font-size: 1.9vh;
            }
            .fa-check-circle {
              color: #75C53E;
              font-size: 1.9vh;
            }
            .fa-minus-circle {
              color: #4F8EF7;
              font-size: 1.9vh;
            }
            .fa-exclamation-triangle {
              color: #f0c000;
              font-size: 1.9vh;
            }
            .fa-exchange-alt {
              color: #FFDB00;
              font-size: 1.9vh;
            }
          }
          .pagination-bottom{
            .-btn {
              width: 10vh !important;
              color: #D98C15 !important;
              background: none !important;
              font-size: 1.5vh !important;
              font-weight: 400;
              &:hover {
                color: #BF7400 !important;
                text-decoration: underline;
              }
              &:disabled {
                pointer-events: none;
                color: #6C6A6A !important;
              }
            }
            .-previous{
              .-btn {
                float: right;
                margin-right: -87vh;
                position: relative;
              }
            }
            .-next{
              .-btn {
                margin-left: 22vh;
              }
            }
          }
        }
      }
    }
    .loader-wrap{
      background-color: #000;
      opacity: 15%;
    }
    .alert-dismissible{
      display: flex;
      align-items: center;
      .close{ 
        top: auto;
      }
    }
  }