.e-profile .ep-name {
  white-space: unset !important;
  color: rgb(217, 140, 21) !important;
}
.e-profile .ep-name:hover {
  text-decoration: underline;
}
.e-profile .blankPageborder {
  border: 1px solid #E5E5E5;
}
.e-profile .ep-table-section {
  width: 100%;
  min-height: 30vh;
  position: relative;
}
.e-profile .ep-table-section .ReactTable .rt-table {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.e-profile .ep-table-section .ReactTable .rt-thead .rt-th {
  white-space: unset !important;
}
.e-profile .ep-table-section .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  border-right: 1px solid #ccc !important;
}
.e-profile .ep-table-section .ReactTable .rt-tbody {
  max-height: 30vh;
}
.e-profile .ep-table-section .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
  border-right: 0px !important;
  border-top: 0px !important;
}
.e-profile .ep-table-section .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  border-right: 1px solid #ccc !important;
}
.e-profile .ep-table-section .pagination-bottom {
  margin-bottom: 1vh !important;
}
.e-profile .cprofile-table .ReactTable .rt-tbody {
  max-height: 20vh !important;
}
.e-profile .e-profile-table-top {
  margin-bottom: 1.5vh;
}
.e-profile .e-profile-table-top .p-0-m-0 {
  margin: 0;
  padding: 0;
}
.e-profile .e-profile-table-top .e-profile-btn-disabled {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 1.5vh;
  padding: 0vh 1.2vh 0.1vh !important;
  border: 0.1vh solid #e5e5e5;
  background: #fff;
  cursor: no-drop;
  color: #c3c3c3;
  margin-left: 0.5vh;
}
.e-profile .e-profile-table-top .e-profile-btn-disabled img {
  opacity: 0.3;
}
.e-profile .e-profile-table-top .action-padding-btm {
  padding-bottom: 0.6vh !important;
}
.e-profile .e-profile-table-top .e-profile-btn-enabled {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 1.5vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  margin-left: 0.5vh;
}
.e-profile .e-profile-table-top .e-profile-btn-enabled:hover {
  background: #e6e6e6;
}
.e-profile .e-profile-table-top .e-profile-btn-enabled-2 {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 1.5vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  padding: 0vh 1.2vh 0.1vh !important;
  margin-left: 0.5vh;
  cursor: pointer;
}
.e-profile .e-profile-table-top .e-profile-btn-enabled-2:hover {
  background: #e6e6e6;
}
.e-profile .e-profile-table-top .filter-icon-btn {
  color: rgb(51, 51, 51);
  border-radius: 0.5vh;
  border: 0.1vh solid rgb(204, 204, 204);
  cursor: pointer;
  padding: 0.8vh 1vh 0.6vh 1vh;
}
.e-profile .e-profile-table-top .filter-icon-btn:hover {
  background: #e6e6e6;
}
.e-profile .e-profile-table-top .delete-icon-btn {
  color: rgb(51, 51, 51);
  border-radius: 0.5vh;
  border: 0.1vh solid rgb(204, 204, 204);
  cursor: pointer;
  padding: 0.9vh 1vh 0.8vh 1vh;
}
.e-profile .e-profile-table-top .delete-icon-btn:hover {
  background: #e6e6e6;
}
.e-profile .e-profile-table-top .dropdown-toggle {
  background: #fff !important;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}
.e-profile .e-profile-table-top .dropdown-toggle:focus {
  outline: none !important;
  border: none !important;
  background-color: #fff !important;
  background: #fff !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.e-profile .e-profile-table-top .dropdown-toggle::before {
  display: none;
}
.e-profile .e-profile-table-top .dropdown-menu {
  background: #EDEDED !important;
  width: 22vh;
  padding: 10px;
  line-height: 3vh;
  top: 0.6vh !important;
}
.e-profile .modal-close {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 1.5vh;
  border: 0.1vh solid #ccc;
  display: inline-block;
  padding: 0.4vh 1vh;
  border-radius: 0.5vh;
  margin-left: 0.5vh;
  cursor: pointer;
  height: 2rem;
  margin-top: -0.2vh;
}
.e-profile .modal-close:hover {
  background: #e6e6e6;
}
.e-profile .nav-tabs a {
  color: #BF7400;
}
.e-profile .nav-tabs a:focus {
  outline: none;
}
.e-profile .flex-content {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}
.e-profile .tooltiptext-center {
  left: -4vh !important;
}
.e-profile .tooltiptext-filter {
  left: -3.5vh !important;
  margin-bottom: 0.8vh !important;
}
.e-profile .tooltiptext-addnetwork {
  min-width: 16vh;
  left: -6vh !important;
  padding: 0.4vh 1vh;
  border-radius: 0.4vh;
}
.e-profile .tooltiptext-close {
  left: -1vh !important;
  padding: 0.4vh 1vh;
  border-radius: 0.4vh;
}
.e-profile .customTooltip {
  display: inline-block !important;
}

.add-edit-profile .formatDate {
  border-radius: 0.3vh 0 0 0.3vh;
  width: 80% !important;
  padding: 0.6vh;
  border: 0.1vh solid #ccc;
  cursor: not-allowed;
  background-color: #eee;
}
.add-edit-profile .date-icon {
  border: 0.1vh solid rgb(204, 204, 204);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0px 0.3vh 0.3vh 0px;
  padding: 0.7vh 0;
}
.add-edit-profile .date-icon:hover {
  background: #DDDDDD;
}
.add-edit-profile .icon-width {
  width: 20% !important;
}
.add-edit-profile .date-input-field {
  width: 80% !important;
}
.add-edit-profile .date-input-field input[type=text] {
  border-radius: 0.3vh 0 0 0.3vh !important;
}

