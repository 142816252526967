.events-summary {
  .with-breadcrumb-events{
    margin-top: 1.9rem;
  }
  
    .content-height {
      .help-texts {
        .alink {
          color: #3b73af;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    .dropdown-disable-autohide {
      background: #fff !important;
    }
    .dropdown-filters {
      width: 18vw !important;
      margin-top: 0.2vh !important;
    }
    .groupByLabel {
      .maxCountTxtbox {
        height: 3.5vh;
      }
    }
    .dropdown-badge {
      position: relative;
      .badge-position {
        position: absolute;
        background: #c53e3e;
        top: -6px;
        width: 1.9vh;
        height: 1.9vh;
        padding: 0.15rem 0.45rem 0.15rem 0.45rem;
        left: -1vh;
        border-radius:50%;
        color: #fff;
        z-index: 999;
        font-size: 1.3vh;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-default{
        border-color: #ccc;
        &:hover{
            background-color: #e6e6e6;
            border-color: #adadad;
        }
        &:disabled{
            border-color: #ccc;
            border-radius: .25rem!important;
            padding: .375rem .75rem!important;
            &:hover{
                background-color: #fff!important
            }
          }
    }
    }
    .clm-style {
      color: #3b73af !important;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .dropdown-filters .picky {
      width: 100% !important;
      white-space: nowrap;
      .picky__input{
        text-overflow: ellipsis;
      }
    }
    .loader-wraps{
      min-height: 65vh!important;
    }
  }
  