@mixin btn{
    background: #fff;
    padding: 0.5vh 1.5vh;
    border: 1px solid #ccc;
    color: #333;
    outline: none;
}
.profiles{
    .title-div1{
        padding-left: 1vh;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        background-color: #F0EFEF;
        border: solid 1px #DDDDDD;
        .new{
            @include btn;
            margin:0.5vh;
            border-radius: 0.4vw;
            // background: hsl(0, 0%, 93%);
            cursor: pointer;
            border-color: #ccc;
        }
        .new:hover{
            background: #ececec;
        }
    }
    .title-view{
        display: inline-block;
        background-color: #F0EFEF;
        border: solid 1px #DDDDDD;
        .padding-10{
            padding: 1.03vh;
        }
        .btns{
            @include btn;
            margin:0.5vh;
            border-radius: 0.4vw;
            // background: hsl(0, 0%, 93%);
            cursor: pointer;
            border-color: #ccc;
        }
    }
    .device-type-div{
        padding-top:0.5vh;
        margin: 0.5vh 0;
    }
    .profile-list-div{
        flex: 1 0 50%;
        border: 1px solid #ddd;
        height: 74vh;
        overflow: auto;
    }
    .lsNone{
        list-style: none;
        .lsNoneli{
            margin: 0.2vh;
            padding: 1.03vh;
            cursor: pointer !important;
            word-break: break-all;
        }
    }
    .active-profile{
        box-shadow: 0px 0px 3px 2px #A8A6A6;
    }
    .padding-l-0{
        padding-left: 0;
    }
    .padding-r-0{
        padding-right: 0;
    }
    .tab-content {
        padding-top: 2vh;
        border: 0.1vh solid #dee2e6;
        height: 75vh;
        border-top: none;
        overflow: auto;
    }
    .nav-tabs {
        a {
            color: #BF7400;
            // font-size: 2vh;
            &:focus {
                outline: none;
            }
        }
    }
    .inline-error{color: #ff0000 !important;}
    .inline-success{color: #00A259 !important;}
    .form-section{
        padding-top: 0.5vh;
        padding-left: 20%;
        padding-right: 20%;
        padding-bottom: 0.5vh;
        .form-section-content-l{
            width:50%;
            display: inline-block;
        }
        .form-section-content-r{
            width:50%;
            display: inline-block;
        }
        .form-section-content-l-70{
            width:50%;
            display: inline-block;
        }
        .form-section-content-l-50{
            width:50%;
            display: inline-block;
        }
        .form-section-content-r-50{
            width:50%;
            display: inline-block;
            .dispInline{
                display:inline;
                .sz-49{
                    width: 49%;
                    float: left;
                }
                .ml-2p{
                    margin-left: 2%;
                }
            }
            .rangeCls{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .sz-49{
                    width: 49%;
                }
            }
        }
        .form-section-content-r-30{
            width:30%;
            display: inline-block;
            .dispInline{
                display:inline;
                .sz-49{
                    width: 49%;
                    float: left;
                }
                .ml-2p{
                    margin-left: 2%;
                }
            }
            .rangeCls{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .m0{
                    margin: 0 !important;
                }
            }
        }
        .form-header{
            font-weight: bold;
            &.LogIThreshold{
                font-size: 1vw;
            }
        }
        .form-check {
            padding: 1rem 1.25rem;
            .form-check-label span {
                font-size: 0.8vw;
            }
        }
    }
    .form-err-border-red{
        border: 1px solid red!important
      }
    .bg-grey-clr{
        background-color: #E5E5E5;
        padding: 0.8vh !important;
      }
      .p-t-5{
          padding-top: 1vh;
      }
}