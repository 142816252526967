.monitor {
  .loader-wrap{
    margin-top: 3vh;
  }
    .content-right {
      .picky {
        .picky__dropdown {
          left: 0px !important;
          right: auto !important;
        }
      }
        .site-View {
            position: relative;
            height: 46vh;
            .row {
              height: 42vh;
            }
            .property-view {
              height: 41.7vh;
            }
            .no-disp-text {
                line-height: 45vh;
            }
            .onhvr-expnd{
                cursor: pointer;
                padding: .7vh;
                display: inline-block;
                background-color: #DDDDDD;
                border: 0.1vh solid #c3c3c3;
                position: absolute;
                top: 45%;
                z-index: 999;
            }
            .onhvr-expnd-left{
              cursor: pointer;
                padding: .7vh;
                display: inline-block;
                background-color: #DDDDDD;
                border: 0.1vh solid #c3c3c3;
                position: absolute;
                top: 51%;
                z-index: 999;
          }
            .left-0 {
                left: 0;
            }
            .right-0 {
                right: 0;
            }

            .property-table {
                text-align: center;
                font-size: 0.8em;
                border: 1px solid #bdbbbb;
                width: 100%;
                display: block;
                max-height: 32vh;
                overflow-y: auto;
                thead, tbody, tr{
                  display: table;
                  width: 100%;
                  table-layout: fixed;
                }
                thead{
                  background: #e5e5e5;
                  position: sticky;
                  top: 0;
                  th{
                    border: 1px solid #bdbbbb;
                  };
                  th:nth-child(1){
                    width: 6vw;
                  }
                }
                tbody {
                  td {
                    border: 1px solid #bdbbbb;
                  };
                  td:nth-child(1){
                    width: 6vw;
                  }
                }
                position: relative;
            }   
        }
        .float-left.col-form-label{
           .btn.btnStyling.date-icon.icon-width.float-right{
             padding-top: 0.55rem !important;
             padding-bottom: 0.636rem !important;
             padding-left: 1rem !important;
             padding-right: 1rem !important;
             border-radius: 0 .3vw .3vw 0;
           }
        }   
    }
}