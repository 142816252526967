.insights {
  overflow: hidden;
  min-height: 30vh;
}
.insights.secondary-navbar .navbar-nav {
  margin-left: 32.7vw;
}
.insights .event-precursor .export-content .dropdown-menu .dropdown-item:first-child {
  border-bottom: transparent !important;
}
.insights .event-precursor .date-icon {
  padding-top: 0.55rem !important;
  padding-bottom: 0.636rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0 0.3vw 0.3vw 0;
}
.insights .event-precursor .vh-45 {
  min-height: 45vh;
}
.insights .event-precursor .picky {
  width: 100% !important;
}
.insights .event-precursor .badge-position {
  position: absolute;
  background: #C53E3E;
  top: 0;
  width: 1.9vh;
  height: 1.9vh;
  padding: 0.12rem 0.38rem 0.12rem 0.38rem;
  right: 3vw;
  border-radius: 50%;
  color: #fff;
  font-size: 1.3vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.insights .event-precursor .chartBtn {
  top: "-15px";
}
.insights .event-precursor .chartBtn span {
  cursor: pointer;
  color: #d98c14;
}
.insights .event-precursor .chartBtn span:hover {
  text-decoration: underline;
}
.insights .event-precursor .no-disp-text {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 45vh !important;
  line-height: 0 !important;
}
.insights .event-precursor .btn-outline-secondary {
  color: #000 !important;
  border: 1px solid #ccc !important;
}
.insights .event-precursor .btn-outline-secondary:hover {
  background: #ccc !important;
}
.insights .event-precursor .fa-plus-circle, .insights .event-precursor .fa-eye {
  cursor: pointer;
}
.insights .msg-succ div {
  margin-bottom: -25px !important;
}

.modal .event-precursor-modal {
  width: 80vw;
  max-width: 80vw;
}
.modal .event-precursor-modal .modal-body {
  min-height: 30vh;
  max-height: 30vh;
  padding: 0.8vh 2.5vh 0;
}
.modal .event-precursor-modal .modal-body .event-precursor-table .no-disp-text {
  line-height: 25vh !important;
}
.modal .event-precursor-modal .modal-body .rt-table {
  border: 0.1px solid #bdbbbb;
}
.modal .event-precursor-modal .modal-body .rt-table .rt-thead {
  position: sticky;
  top: 0;
}
.modal .event-precursor-modal .modal-body .rt-table .rt-tbody {
  max-height: 22vh;
}
.modal .site-event-precursor-modal {
  width: 80vw;
  max-width: 80vw;
}
.modal .site-event-precursor-modal .h-80 {
  height: 80vh !important;
}
.modal .site-event-precursor-modal .gm-style-iw-chr {
  height: 10px !important;
}
.modal .site-event-precursor-modal .gm-style-iw-d {
  margin: 15px !important;
}
.modal .site-event-precursor-modal .ep-map-tooltip-title {
  margin: 10px 0px;
  font-weight: bold;
  font-size: 14px;
}
.modal .site-event-precursor-modal .ep-map-table {
  border: 1px solid #bdbbbb;
  font-size: 12px;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.modal .site-event-precursor-modal .ep-map-table th, .modal .site-event-precursor-modal .ep-map-table td {
  text-align: center;
  border: 1px solid #bdbbbb !important;
  padding: 5px;
}
.modal .site-event-precursor-modal .ep-map-table th {
  background-color: #E5E5E5;
}
.modal .site-event-precursor-modal .ep-map-link {
  color: #D98C15;
  cursor: pointer;
  margin: 2px 0px;
  padding: 0 9px;
}
.modal .site-event-precursor-modal .tooltip-links {
  color: #D98C15;
  margin: 2px 0px;
  padding: 0 9px;
  border-right: 1px solid grey;
}
.modal .site-event-precursor-modal .txt-color {
  color: #D98C15;
  cursor: pointer;
}
.modal .site-event-precursor-modal .txt-color:hover {
  text-decoration: underline;
}
.modal .addEventPrecrsorModal .date-icon {
  padding: 4px 6px;
  border: 1px solid #ccc;
}
.modal .addEventPrecrsorModal .date-icon:hover {
  background-color: #ccc;
}

#popover-positioned-event-precursor-helptext {
  max-width: 40vw !important;
}
#popover-positioned-event-precursor-helptext svg {
  position: relative;
  top: -1px;
}

