.virtual-device-mngmnt .position-center {
  position: absolute;
  z-index: 9;
  transform: translate(-50%, 0%);
  left: 50%;
}
.virtual-device-mngmnt .action-and-search-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.virtual-device-mngmnt .action-and-search-section .search-section {
  width: 16%;
}
.virtual-device-mngmnt .device-main-table {
  min-height: 30vh;
  border: 1px solid #ced4da;
}
.virtual-device-mngmnt .disabled-icon {
  cursor: default !important;
  opacity: 0.35 !important;
}
.virtual-device-mngmnt input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.virtual-device-mngmnt .checkboxList {
  top: 0 !important;
}
.virtual-device-mngmnt .add-virtual-device {
  padding: "0 2vh 2vh 1vh 2vh";
  overflow-x: hidden;
}
.virtual-device-mngmnt .add-virtual-device .add-edit-device-container {
  height: auto;
}
.virtual-device-mngmnt .add-virtual-device .add-edit-device-container .word-break-all {
  word-break: break-all;
}
.virtual-device-mngmnt .add-virtual-device .add-edit-device-container .content-top {
  background: rgb(237, 237, 237);
  padding: 1.2vh 1.6vh;
  text-align: center;
  border: 1px solid rgb(189, 187, 187);
  border-bottom: none;
}
.virtual-device-mngmnt .add-virtual-device .add-edit-device-container .section-content-body {
  border: 1px solid rgb(189, 187, 187);
  height: 93%;
}
.virtual-device-mngmnt .add-virtual-device .add-edit-device-container .section-content-body .vd-tree-view {
  height: 41.5rem;
  display: grid;
}
.virtual-device-mngmnt .add-virtual-device .single-select-dropdown .dropdown button:disabled {
  background-color: #e9ecef !important;
}
.virtual-device-mngmnt .add-virtual-device .device-count-section {
  font-size: 1.3vh;
  font-weight: bold;
  background: rgb(237, 237, 237);
  border: 1px solid rgb(189, 187, 187);
  padding: 1.2vh 2vh;
}
.virtual-device-mngmnt .add-virtual-device .ReactTable {
  padding-bottom: 1.2vh;
}
.virtual-device-mngmnt .add-virtual-device .ReactTable .rt-thead .rt-th:nth-child(3) {
  width: 11.6vw !important;
}
.virtual-device-mngmnt .add-virtual-device .ReactTable .rt-tbody .rt-tr-group .rt-td {
  padding: 0.6vh !important;
}
.virtual-device-mngmnt .add-virtual-device .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(3) {
  width: 11.6vw !important;
}
.virtual-device-mngmnt .add-virtual-device .error-color {
  color: #a94442;
  font-size: 1.4vh;
}
.virtual-device-mngmnt .add-virtual-device .point-count-section {
  padding: 1.6vh 1.6vh 0 1.6vh;
}
.virtual-device-mngmnt .add-virtual-device .point-info-msg {
  background-color: #fff3cd;
  padding: 0.6vh 1vh;
  border-radius: 0.5vh;
  font-weight: normal;
  color: #664d03;
  border: 1px solid #ffecb5;
  margin-top: 1vh;
}
.virtual-device-mngmnt .ellips-view {
  width: 40px;
  font-weight: bold;
  padding: 0.4vh;
}
.virtual-device-mngmnt .lockUnlock {
  height: 1.2rem;
}
.virtual-device-mngmnt .custom-file-inputs {
  overflow: hidden;
  position: relative;
}
.virtual-device-mngmnt .custom-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  height: 28px;
  display: inline-block;
  width: 100% !important;
  font-size: 5px !important;
  cursor: pointer !important;
  bottom: 0;
}
.virtual-device-mngmnt .disabled-file-inputs {
  overflow: hidden;
  position: relative;
}
.virtual-device-mngmnt .disabled-file-inputs input[type=file] {
  opacity: 0;
  position: absolute;
  right: 0;
  height: 1px !important;
  display: inline-block;
  width: 100% !important;
  font-size: 0 !important;
  bottom: 0;
}
.virtual-device-mngmnt .disabled-input-icon-color {
  color: #c3c3c3;
}

.modal .custom-v-device-dialog {
  width: 95vw;
  max-width: 95vw;
}
.modal .custom-v-device-dialog .add-edit-device-container {
  height: auto;
}
.modal .custom-v-device-dialog .add-edit-device-container .checkboxList {
  top: 0 !important;
}
.modal .custom-v-device-dialog .add-edit-device-container .word-break-all {
  word-break: break-all;
}
.modal .custom-v-device-dialog .add-edit-device-container .content-top {
  background: rgb(237, 237, 237);
  padding: 1.2vh 2vh;
  text-align: center;
  border: 1px solid rgb(189, 187, 187);
  border-bottom: none;
}
.modal .custom-v-device-dialog .add-edit-device-container .section-content-body {
  border: 1px solid rgb(189, 187, 187);
  height: 93%;
}
.modal .custom-v-device-dialog .device-count-section {
  font-size: 1.3vh;
  font-weight: bold;
  background: rgb(237, 237, 237);
  border: 1px solid rgb(189, 187, 187);
  padding: 1.2vh 2vh;
}
.modal .custom-v-device-dialog .point-count-section {
  padding: 1.6vh;
}
.modal .delete-dnpc-modal .modal-content {
  min-height: 2vh !important;
}
.modal hr {
  border-width: 0.2vh !important;
}
.modal .live-view-dnpc {
  width: 100vw;
  max-width: 100vw;
  transition: transform 0s ease-out !important;
}
.modal .live-view-dnpc .live-view-error {
  color: rgb(169, 68, 66);
  font-size: 1.2vh;
}
.modal .live-view-dnpc .lockUnlock {
  height: 1rem;
}
.modal .live-view-dnpc .lockunlockoutline {
  padding: 0.99vh 1.3vh;
}
.modal .live-view-dnpc .fade {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none !important;
}
.modal .live-view-dnpc .pagination-bottom {
  margin-bottom: 0 !important;
}
.modal .live-view-dnpc .pagination-bottom .custom-pagination {
  margin-bottom: 0 !important;
}
.modal .live-view-dnpc .position-center {
  position: absolute;
  z-index: 9;
  transform: translate(-50%, 0%);
  left: 50%;
}
.modal .live-view-dnpc .modal-content {
  width: 98%;
  position: fixed;
  bottom: 0;
  left: 1%;
}
.modal .live-view-dnpc .modal-content .modal-header {
  display: none;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .table-area {
  border: 1px solid #d2d2d2;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-thead .rt-th:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-tbody {
  overflow-y: auto !important;
  max-height: 26vh;
  padding-bottom: 0.1vh;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-thead .rt-th:nth-child(9) {
  min-width: 10vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-thead .rt-th:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody {
  overflow-y: scroll !important;
  max-height: 75vh;
  padding-bottom: 0.1vh;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(9) {
  min-width: 10vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc .modal-content .modal-body .lock-loader-position {
  position: relative;
  left: 0;
}
.modal .live-view-dnpc-window {
  width: 100vw;
  max-width: 100vw;
  transition: transform 0s ease-out !important;
}
.modal .live-view-dnpc-window .live-view-error {
  color: rgb(169, 68, 66);
  font-size: 1.2vh;
}
.modal .live-view-dnpc-window .lockUnlock {
  height: 1rem;
}
.modal .live-view-dnpc-window .lockunlockoutline {
  padding: 0.99vh 1.3vh;
}
.modal .live-view-dnpc-window .fade {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none !important;
}
.modal .live-view-dnpc-window .pagination-bottom {
  margin-bottom: 0 !important;
}
.modal .live-view-dnpc-window .pagination-bottom .custom-pagination {
  margin-bottom: 0 !important;
}
.modal .live-view-dnpc-window .modal-content {
  width: 98%;
  position: fixed;
  bottom: 0;
  left: 1%;
}
.modal .live-view-dnpc-window .modal-content .modal-header {
  display: none;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .table-area {
  border: 1px solid #d2d2d2;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-thead .rt-th:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  width: 7.29% !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-tbody {
  overflow-y: auto !important;
  max-height: 26vh;
  padding-bottom: 0.1vh;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .compress-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-last-child(1) {
  width: 7% !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-thead .rt-th:nth-child(9) {
  min-width: 10vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-thead .rt-th:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  width: 7.29% !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody {
  overflow-y: scroll !important;
  max-height: 75vh;
  padding-bottom: 0.1vh;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(9) {
  min-width: 10vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-child(12) {
  min-width: 8vw !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .device-live-view .expand-view .ReactTable .rt-tbody .rt-tr-group .rt-td:nth-last-child(1) {
  width: 7% !important;
}
.modal .live-view-dnpc-window .modal-content .modal-body .lock-loader-position {
  position: relative;
  left: 0;
}

