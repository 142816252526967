// @mixin fileInputArea{
//       overflow: hidden;
//       position: relative;
//       margin-bottom: 1vh;
//       border-radius: 3px;
//       display: inline-block;
//       padding: 0.8vh 1.5vh;
// }


.audit-trail{
  // margin-top: -3vh!important;
  .at-top-section{
    border: 0.1vh solid #DDDDDD;
    margin-top: 1vh;
    padding: 1.5vh 0;
    .each-row-gap{
      margin-bottom: 2vh;
    }
    .picky__input {
      border: 0.1vh solid #ccc !important;
      height: 3.8vh !important;
      &::after{
        right: 10px!important;
        top: 13px!important;
      }
      &:focus{
        outline: none!important;
      }
      &:hover{
        background: #eee;
      }
    }

    .picky__dropdown{
      width: unset !important;
      /* bottom: 28px !important; */
      top: unset!important;
      left: 0px !important;
      right: 0px !important;
      overflow-y: auto !important;
      background: #e5e5e5 !important;
      max-height: 50vh !important;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        li {
          background: #e5e5e5 !important;
          border-bottom : 0px !important;
        }
    }


    input[type=text]{
      border-radius: 0.3vh;
      width: 100% !important;
      padding: 0.6vh;
      border: 0.1vh solid #ccc;
    }
  
    .formatDate{
      border-radius: 0.3vh 0 0 0.3vh;
      width: 100% !important;
      padding: 0.6vh;
      border: 0.1vh solid #ccc;
      cursor: not-allowed;
      background-color: #eee;
    }

    .date-icon{
      border: 0.1vh solid rgb(204, 204, 204);
      border-left: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 0px 0.3vh 0.3vh 0px;
      padding: 0.75vh 0;
      &:hover{
        background: #DDDDDD;
      }
    }
    .icon-width{
      width: 20%!important;
    }
    .date-input-field{
      width: 80%!important;
      input[type=text]{
        border-radius: 0.3vh 0 0 0.3vh!important;
      }
    }
    .apply-btn-area{
      display: flex;
      align-items: center;
      justify-content: center;
      .apply-btn{
        padding: 0.6vh 1.5vh;
        font-size: 1.5vh;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 0.5vh;
        outline: none;
        &:hover{
          background: #ddd;
          border: 0.1vh solid #ccc;
        }
      }
    }
    .top-label{
      margin-top: 0.8vh;
    }
  }

  .ReactTable{
    margin-bottom: 4vh!important;
    .rt-th{
      color: #333;
    }
    .rt-tbody{
      .rt-td{
        white-space: unset!important;
        color: #333;
      }
    }
  }
  .at-blankpage{
    height: 60vh;
  }


  // .daterangepicker{
  //   // min-height: 37vh!important;
  //   // min-width: 37vh!important;
  //   .drp-calendar{
  //     // min-height: 37vh!important;
  //     // min-width: 37vh!important;
  //     .active{
  //       background: #D98C15;
  //       color: #333;
  //       // border-radius: 0!important;
  //       &:hover{
  //         background: #D98C15;
  //         color: #333;
  //         // border-radius: 0!important;
  //       }
  //     }
  //   }
  //   .drp-buttons{
  //     width: 100%;
  //     float: left;
  //     padding: 1vh 0;
  //   }
  //   // .cancelBtn{
  //   //   float: right!important;
  //   //   border: 0.1vh solid #ccc;
  //   //   font-weight: normal!important;
  //   //   background: #fff;
  //   //   color: #333;
  //   //   &:hover{
  //   //     background: #ddd;
  //   //     color: #333;
  //   //     border: 0.1vh solid #ccc;
  //   //   }
  //   // }
  //   // .applyBtn{
  //   //   float: left!important;
  //   //   background: #fff;
  //   //   color: #333;
  //   //   font-weight: normal!important;
  //   //   border: 0.1vh solid #ccc;
  //   //   margin-left:0;
  //   //   &:hover{
  //   //     background: #ddd;
  //   //     color: #333;
  //   //     border: 0.1vh solid #ccc;
  //   //   }
  //   // }
  //   .ranges{
  //     position: absolute!important;
  //     left: 0;
  //     bottom: 0 !important;
  //     z-index: 99999 !important;
  //     width: 100% !important;
  //     ul{
  //       li {
  //         display: inline-block;
  //         padding: 0.5vh 1vh!important;
  //         background-color: #fff!important;
  //         color: #333!important;
  //         border: 0.1vh solid #ddd;
  //         margin: 1vh 2vh!important;
  //         &:hover{
  //           background: #ddd!important;
  //         }
  //         &:active{
  //           background-color: #fff!important;
  //           margin: 1vh 2vh!important;
  //         }
  //         &:focus{
  //           background-color: #fff!important;
  //           margin: 1vh 2vh!important;
  //         }
  //       }
  //     }
  //   }
  // }
}

