@mixin customScrolbar{
    &::-webkit-scrollbar {
      width: 0.8vh!important;
      background: #e5e5e5;
     
    }
    &::-webkit-scrollbar-thumb {
      background:#9e9e9e; 
      border-radius: 20px;
    }
    &::-webkit-scrollbar-button {
      &:single-button {
        height: 0.5vh;
      }
    }
  }
 
.email-subscription-modal{
    max-width:50vw!important;
    width: 100%!important;
    .modal-body{
        .alert-message{
            height: 20vh;
        }
        .bottom-hints{
            padding: 3vh 1vh 1vh!important;
            font-size: 1.6vh;
        }
        .notification-main-title{
            margin-bottom: 0.8vh;
            font-weight: bold;
        }
        input[type="checkbox"]{
            width: 2.2vh;
            height: 2.2vh;
        }
        .region-substation-container{
            .region-substation-tree{
                height: 40vh;
                border: 0.1vh solid rgb(218, 216, 216);
                display: grid!important;
                .rstcustom__row{
                    width: 100%;
                    padding: 1.4vh 0vh;
                    .rstcustom__rowLabel{
                        width: 100%;
                        span {
                            cursor: pointer;
                        }
                        }
                    .plusMinusIcon{
                        font-size: 1.7vh;
                        color: rgb(51, 51, 51);
                    }
                    .typeIcon{
                        padding-left: 1.2vh
                    }
                    .tree-checkbox{
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
                .rstcustom__rowToolbar {
                    .tree-wth-checkbox {
                        cursor: pointer;
                    }
                }
                .WcheckBox{
                    padding-right: 2vh;
                }
                .ReactVirtualized__List{
                    padding-right: 1vh;
                    @include customScrolbar;
                }
            }
        }
    }
    
    .notification-type-container{
        .notification{
            border: 1px solid rgb(218, 216, 216);
            padding: 0 1.4vh;
            height: 40vh;
            overflow-y: scroll;
            @include customScrolbar;
            .notification-title{
                padding-top: 1vh;
                font-weight: bold;
            }
            .data-row{
                padding: 0.4vh 0 0.6vh 1.6vh;
            }
            .star{
                padding-right: 1vh;
                line-height: 1.4vh;
                margin-top: 1vh;
                font-size: 2.5vh;
            }
        }
    }
    .modal-footer{
        .cancel-btn {
            padding: 0.5vh 1vw;
            color: #333;
            border: 1px solid #ccc;
            border-radius: 0.5vh;
            background: #e9ecef;
            outline: none;
            font-size: 2vh;
            &:hover{
                background: #d2d2d2;
            }
        }
        
          .create-btn {
            padding: 0.6vh 1vw;
            color: #333;
            border: none;
            border-radius: 0.5vh;
            background: #d98c15;
            outline: none;
            font-size: 2vh;
            &:hover{
                background: #BF7400;
            }
        }
    }
}