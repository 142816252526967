@import '../resources/css/variables.scss';
@import '../resources/css/mixins.scss';

.form-control, .btn {
    line-height: 1 !important;
}
.logo{
    img {
        margin-bottom: 1vh;
    }
}
.login-view{
    width: 100%;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    display: table;
    max-width:100%;
}
.login-content{
    padding: 0;
    vertical-align: middle;
    display: table-cell;
    margin: 0;
}
.login-wrap{
    width: 22vw;
    max-width: 25vw;
    min-height: 40vh;
    margin: 0 auto;
    padding: 2.5vh;
    @include border-radius(6px);
    border: 10px;
    @include box-shadow(2px 2px 2px 3px rgba(42,42,42,0.7));
    .login-title{
        color:$titletextcolor;
        font-family: $fontfamily !important;
        font-size: 1.6vw;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2vh;
        // margin-top: 12vh;
    }
    .form-horizontal {
        margin-bottom: 15px;
        margin-top: 88px;
    }
    .input-with-icon {
        position: relative;
        .account-textbox {
            padding-right: 2vw;
        }
        .account-help {
            position: absolute;
            right: 0;
            top: 0.6vh;
            width: 1vw;
        }
    }
    .keeplogin{
        font-family: $fontfamily !important;
        display: inline-block;
        font-size: 10px;
        color: #aaaaaa;
    }
    .keeplogin input#loginkeeping{
        margin-right: 5px;
        margin-bottom: 5px;
    }			
    .forgot-password{
        font-family: $fontfamily !important;
        margin-bottom: 3px;
        font-size: 13px;
        font-style: bold;
        a:hover{
        text-decoration: underline;
        }
    }
    .alert-dismissible {
        margin-bottom: 2vh !important;
    }
    .login-btn{
        background: $buttoncolor !important;
        color: $buttontextcolor !important;
        @include border-radius(3px);
    }
    .btn-link, .btn-link:hover {
        a{
            color: #BF7400 !important;
        }
        color: #BF7400 !important;
        padding: 0;
    }
    .btn-warning {
        color: #333;
        background-color: #D98C15 !important;
        border-color: #D98C15 !important;
        padding: 1vh !important;
        &:hover{
            border-color: #adadad !important;
        }
        &:disabled{
            opacity: 0.5;
        }
    }
    .margin-top-4vh{
        margin-top: 4vh;
    }
    .roundedLabel {
        border: 1px solid #dadce0;
        border-radius: 1vw;
        padding: 0.5vh 0.5vw;
        cursor: pointer;
    }
    .dspnone {
        display: none !important;
    }
}
.login-footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    margin: auto;
    padding-right: 15px;
}
.login-error-color {
	color: #a94442;
}
.login-error-bordercolor{
    border-color: #a94442 !important;
}
.ample-error-message{
    color: #a94442;
    text-align: right;
}
.orgView {
    width: 100%;
    height: 100%;
    display: table;

    .orgContent {
        vertical-align: middle;
        display: table-cell;

        .single-select-dropdown .dropdown button {
            height: 5vh;
            font-weight: bold;
        }
        .orgWrap {
            width: 50vw;
            margin: 0 auto;

            .org-btn {
                width: 10vw;
                padding: 1.5vh;
                margin: auto;
                font-size: 2vh;
                font-weight: bold;
                border-radius: 1vh;
                float: right;
            }
        }
    }
    
}