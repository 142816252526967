@import '../resources/css/variables.scss';
@import '../resources/css/mixins.scss';

.navbar {
	height: 4.25vh;
	min-height: 4.25vh;
	background: $main-nav-bg;
	border: transparent;
	padding: .5vh .5vw !important;

	.nav-item {
		cursor: pointer;
		.nav-link {
			padding: .85vh 1vw !important;
			color: #D98C15 !important;
			font-weight: bold;
			font-size: .85vw !important;
			line-height: 2.6vh;
			&:hover {
				background: $main-nav-active-bg !important;
				color: $main-nav-active-font !important;
			}
		}
		&:focus {
			background: $main-nav-active-bg !important;
			color: $main-nav-active-font !important;
		}
		
		.activeClass {
			background: $main-nav-active-bg !important;
			color: $main-nav-active-font !important;
			pointer-events: none;
		}
		.disabled {
			opacity: 0.5 !important;
			pointer-events: none;
			background: none !important;
			box-shadow: unset !important;
		}
		.main-menu-disabled {
			pointer-events: unset;
			cursor: not-allowed !important;
		}
	}
	.navbar-brand {
		width: 2.5vw;

		.logo {
			background-image: url($imagesPath + "logo.png");
			width: 2.4rem;
			height: 2rem;
			max-height: 4vh;
			max-width: 4.4vh;
			background-size: 100% 100%;
			background-position: 0,0;
		}	
	}
	.dropdown-menu {
		background: $main-nav-bg;
		min-width: max-content !important;
		border: none;
		border-radius: 0 0 0.5vh 0.5vh;

		a {
			line-height: 1vh !important;
			font-size: .8vw !important;
			font-weight: normal !important;
		}

		.dropdown-item {
			color: $main-nav-font;
			font-family: $fontfamily !important;
			
			&:hover, &:focus {
				background: $main-nav-active-bg !important;
				color: $main-nav-active-font !important;
			}
		}
		.dropdown-item.active {
			background: $main-nav-active-bg !important;
			color: $main-nav-active-font !important;
		}

	}
	&.secondary-navbar {
		top: 4.25vh;
		z-index: 999;
		min-height: 2.8vh !important;
		height: 2.8vh !important;
		background: $sub-nav-bg;
		border: transparent;
		white-space: nowrap;
		.navbar {
			background: transparent;
		}

		.navbar-nav {
			margin-left: 14vw;
		}
		.nav-item {
			margin-right: 0.5vw;
			.nav-link {
				padding: .3vh .7vw !important;
				color: $sub-nav-font !important;
				font-size: .75vw !important;
				line-height: 2vh;
				&:hover, &.active {
					background: transparent !important;
					box-shadow: 0 0.5px 3px 1px $sub-nav-active-bg !important;
				}
				&.active{
					pointer-events: none;
				}
			}
		}
	}
	.rightSideMenu {
		.nav-item {
			.nav-link {
				&:hover {
					background: transparent !important;
					color: $main-nav-font !important;
				}
			}
		}
		.nav-dropdown-menu.show {
			background: $settings-more-opt-active-bg;
			.nav-item a{
				&.disabledClass{
					opacity: 0.5;
				}
			}
			.nav-item:hover a{
					background: $main-nav-active-bg !important;
					color: $main-nav-active-font !important;
					&.disabledClass{
						pointer-events: none;
					}
				} 
			.dropdown-menu-right {
				.nav-item {
					.nav-link {
						padding: 1vh 2vh !important;
						font-size: .8vw !important;   
						&:hover {
							background: $main-nav-active-bg !important;
							color: $main-nav-active-font !important;
						}
					}
					a {
						padding: 0 !important;
					}
					.activeClass {
						background: transparent !important;
						color: $main-nav-font !important
					}
				}
				a {
					padding: 0 !important;
					font-size: .8vw !important;
				}
				.noNavigationItemLink{
					padding: 1vh 2vh !important;
				}
			}
		}
		.update-profile-menu {
			.dropdown-menu.show {
				a {
					//padding: 1vh 2vh !important;
					padding:0 !important;
					font-size: .8vw !important;
					.noNavigationItemLink{
						padding: 1vh 2vh !important;
					}
				}
				.customlink{
					padding: 1vh 2vh !important;
				}
			}
		}
	}
}
.bg-dark {
	background-color: $main-nav-bg !important;
}
.hr-color {
	background-color: #D98C15;
}
