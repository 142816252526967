.dgw-container {
  overflow-x: hidden;
}
.dgw-container .ceritifcate-view-btn {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  border: 0.1vh solid #000;
  cursor: pointer;
  padding: 0.3vh 0.6vh !important;
  font-size: 1.8vh !important;
}
.dgw-container .certificate-section {
  margin-bottom: 0vh;
  margin-top: 1vh;
}
.dgw-container .certificate-section .certificate-all {
  padding: 1vh;
  border: 1px solid rgb(229, 229, 229);
  overflow-y: auto;
}
.dgw-container .certificate-section .certificate-all .certificate-all-border {
  border: 1px solid #e5e5e5;
}
.dgw-container .certificate-section .certificate-all .certificate-all-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 1vh;
  margin-bottom: 1vh;
}
.dgw-container .certificate-section .certificate-all .certificate-all-header .status-color {
  color: #D98C15;
}
.dgw-container .certificate-section .certificate-all .certificate-all-body {
  padding: 1vh;
  height: 80vh;
  overflow-y: auto;
  word-wrap: break-word;
}
.dgw-container .sg-name {
  white-space: unset !important;
  color: rgb(217, 140, 21) !important;
}
.dgw-container .sg-name:hover {
  text-decoration: underline;
}
.dgw-container .sg-table-section {
  width: 100%;
  min-height: 15vh;
}
.dgw-container .sg-table-section .ReactTable .rt-thead .rt-th:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}
.dgw-container .sg-table-section .ReactTable .rt-tbody .rt-td:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}
.dgw-container .ReactTable {
  border: 0.1vh solid #fff !important;
}
.dgw-container .ReactTable .rt-thead .rt-th {
  white-space: unset !important;
}
.dgw-container .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  border-right: 1px solid #ccc !important;
}
.dgw-container .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
  border-right: 0px !important;
  border-top: 0px !important;
}
.dgw-container .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  border-right: 1px solid #ccc !important;
}
.dgw-container .sensor-getway-table-top {
  margin-bottom: 1.5vh;
}
.dgw-container .sensor-getway-table-top .p-0-m-0 {
  margin: 0;
  padding: 0;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-disabled {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  padding: 0vh 1.2vh 0.1vh !important;
  border: 0.1vh solid #e5e5e5;
  background: #fff;
  cursor: no-drop;
  color: #c3c3c3;
  margin-left: 0.5vh;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-disabled img {
  opacity: 0.3;
}
.dgw-container .sensor-getway-table-top .action-padding-btm {
  padding-bottom: 0.6vh !important;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-enabled {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  margin-left: 0.5vh;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-enabled:hover {
  background: #e6e6e6;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-enabled-2 {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  padding: 0vh 1.2vh 0.1vh !important;
  margin-left: 0.5vh;
  cursor: pointer;
}
.dgw-container .sensor-getway-table-top .sensor-getway-btn-enabled-2:hover {
  background: #e6e6e6;
}
.dgw-container .sensor-getway-table-top .filter-icon-btn {
  color: rgb(51, 51, 51);
  border-radius: 0.5vh;
  border: 0.1vh solid rgb(204, 204, 204);
  cursor: pointer;
  padding: 0.8vh 1vh 0.6vh 1vh;
}
.dgw-container .sensor-getway-table-top .filter-icon-btn:hover {
  background: #e6e6e6;
}
.dgw-container .sensor-getway-table-top .cert-btn-enable {
  border: 0.1vh solid rgb(204, 204, 204);
  width: 2vh;
  height: 2vh;
  border-radius: 0.5vh;
  cursor: pointer;
  padding: 0.8vh 0.4vh;
}
.dgw-container .sensor-getway-table-top .cert-btn-enable:hover {
  background: #e6e6e6;
}
.dgw-container .sensor-getway-table-top .cert-btn-disable {
  border: 0.1vh solid rgb(204, 204, 204);
  width: 2vh;
  height: 2vh;
  border-radius: 0.5vh;
  padding: 0.8vh 0.4vh;
  opacity: 0.5;
  cursor: not-allowed;
}
.dgw-container .sensor-getway-table-top .dropdown-toggle {
  background: #fff !important;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}
.dgw-container .sensor-getway-table-top .dropdown-toggle:focus {
  outline: none !important;
  border: none !important;
  background-color: #fff !important;
  background: #fff !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.dgw-container .sensor-getway-table-top .dropdown-toggle::before {
  display: none;
}
.dgw-container .sensor-getway-table-top .dropdown-menu {
  background: #EDEDED !important;
  width: 22vh;
  padding: 10px;
  line-height: 3vh;
  top: 0.6vh !important;
}
.dgw-container .modal-close {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  padding: 0.2vh 1vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  margin-left: 0.5vh;
  font-size: 2vh;
  cursor: pointer;
}
.dgw-container .modal-close:hover {
  background: #e6e6e6;
}
.dgw-container .sg-name-modal {
  background: rgb(255, 255, 255);
  position: fixed;
  right: 2.5vh;
  width: 52vw;
  top: 6vh;
  z-index: 10;
  box-shadow: grey 0px 3px 9px 4px;
  height: 93%;
}
.dgw-container .sg-name-modal .network-group-top-section {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: 1.5vh 0px;
}
.dgw-container .sg-name-modal .network-group-top-section .ntwrk-top-section-btn {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  padding: 0.4vh 1vh;
  border: 0.1vh solid #ccc;
  color: #333;
  outline: none !important;
  margin-left: 0.5vh;
  font-size: 2vh;
  cursor: pointer;
}
.dgw-container .sg-name-modal .network-group-top-section .ntwrk-top-section-btn:hover {
  background: #e6e6e6;
}
.dgw-container .sg-name-modal .network-group-top-section .ntwrk-top-section-btn-disabled {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  padding: 0.4vh 0.8vh;
  border: 0.1vh solid #ccc;
  font-size: 2vh;
  cursor: no-drop;
  color: #c3c3c3;
}
.dgw-container .sg-name-modal .sg-name-modal-body {
  padding: 1vh 0;
  height: 90vh;
  overflow: auto;
}
.dgw-container .sg-name-modal .sg-name-modal-body::-webkit-scrollbar {
  width: 1.2vh !important;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section {
  margin-bottom: 1vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .delete-section {
  padding: 5vh 1vh;
  border: 1px solid rgb(229, 229, 229);
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section {
  padding: 1vh;
  border: 1px solid rgb(229, 229, 229);
  height: 38vh;
  overflow-y: auto;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-title {
  font-weight: 600 !important;
  font-size: 1.6vh;
  padding: 1vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-title .dropdown-icon {
  cursor: pointer;
  color: #6b6a6a;
  margin-left: 1vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row {
  margin-bottom: 1.3vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-left {
  width: 70%;
  display: inline-block;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-left .margin-left-5 {
  margin-left: 5vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-left-50 {
  width: 50%;
  display: inline-block;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-left-50 .margin-left-5 {
  margin-left: 5vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-right-50 {
  width: 46%;
  display: inline-block;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-right {
  width: 25%;
  display: inline-block;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .form-row-right .sequre-title {
  font-size: 1.5vh;
  margin-left: 1vh;
  position: absolute;
  top: 0.3vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .dropdown .dropdown-toggle {
  text-align: left;
  position: relative;
  width: 100%;
  background: #fff;
  color: #333;
  border: 0.1vh solid #ccc;
  padding: 1vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .dropdown .dropdown-toggle:focus {
  box-shadow: none !important;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .dropdown .dropdown-toggle::before {
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 1vh;
  top: 45%;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .dropdown .dropdown-menu {
  width: 100%;
  line-height: 4vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .form-section .form-row .dropdown .dropdown-menu .dropdown-item {
  padding: 0 1.5vh;
  line-height: 3.5vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn {
  padding: 1vh 0;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn .button-add {
  background-color: #BF7400;
  border: none;
  border: 0.1vh solid #BF7400;
  padding: 0.3vh 1vh;
  outline: none;
  border-radius: 0.4vh;
  cursor: pointer;
  margin-left: 0.4vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn .button-add:hover {
  background: #a96600;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn .disable-button-add {
  background-color: #BF7400;
  border: none;
  border: 0.1vh solid #BF7400;
  padding: 0.3vh 1vh;
  outline: none;
  border-radius: 0.4vh;
  opacity: 0.5;
  cursor: auto;
  margin-left: 0.4vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn .button-cancel {
  background-color: #e6e6e6;
  border: none;
  border: 0.1vh solid #adadad;
  padding: 0.3vh 1vh;
  outline: none;
  border-radius: 0.4vh;
  cursor: pointer;
}
.dgw-container .sg-name-modal .sg-name-modal-body .add-network-group-section .add-network-action-btn .button-cancel:hover {
  background: #bfbfbf;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section {
  margin-bottom: 1vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .inactive-icon {
  color: #b9b9b9;
  cursor: no-drop;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .active-icon {
  color: #212529;
  cursor: pointer;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section {
  padding: 1vh;
  border: 1px solid #e5e5e5;
  min-height: 36vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section .ReactTable {
  max-height: 34vh;
  overflow: hidden;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section .ReactTable .rt-tbody .rt-tr .rt-td:last-child {
  width: 95px !important;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section-full {
  padding: 1vh;
  border: 1px solid #e5e5e5;
  min-height: 36vh;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section-full .ReactTable {
  max-height: 34vh;
  overflow: hidden;
}
.dgw-container .sg-name-modal .sg-name-modal-body .network-groups-table-section .table-section-full .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.dgw-container .sg-name-modal .sg-name-modal-body .accordion-bar {
  padding: 1vh;
  cursor: pointer;
  background: rgb(229, 229, 229);
  border: 0.1vh solid rgb(204, 204, 204);
  font-size: 1.6vhvh;
  font-weight: bold;
}
.dgw-container .sg-name-modal .sg-name-modal-body .accordion-bar .accordion-icon {
  font-size: 3vh;
  display: flex;
  color: #888888;
}
.dgw-container .sg-upload-certificates .sensor-getway-btn {
  padding: 0.4vh 1.5vh 0.4vh;
  border-radius: 0.5vh;
  font-size: 2.5vh;
  border: 0.1vh solid #D98C15;
  background: #D98C15;
  cursor: pointer;
  position: fixed;
  left: 3vh;
  bottom: 4vh;
  padding: 0.3vh 1.1vh !important;
  font-size: 1.8vh !important;
}
.dgw-container .sg-upload-certificates .sensor-getway-btn:hover {
  background: #BF7400;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal {
  border: 0.1vh solid #bdbbbb;
  width: calc(100% - 3vh);
  position: fixed;
  bottom: 0;
  height: 35%;
  background-color: #fbfbfb;
  -webkit-box-shadow: 0px 3px 9px 4px grey;
  box-shadow: 0px 3px 9px 4px grey;
  left: 4vh;
  padding: 2vh 0;
  z-index: 9;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .action-button {
  position: absolute;
  z-index: 9;
  right: 2.2vh;
  display: flex;
  top: -0.7vh !important;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .nav-tabs {
  position: relative;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .tab-content {
  border: 0.1vh solid #dee2e6;
  border-top: none;
  padding-top: 2vh;
  height: 27vh;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .tab-content .certificate-table .ReactTable {
  max-height: 25vh;
  overflow: hidden;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .tab-content .certificate-table .ReactTable .rt-thead {
  position: sticky;
  top: 0;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .tab-content .certificate-table-full .ReactTable {
  max-height: 25vh;
  overflow: hidden;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .tab-content .certificate-table-full .ReactTable .rt-tbody {
  overflow-x: hidden !important;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .cert-close-icon {
  display: block;
  padding: 0.2vh 1vh 0.2vh;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal input[type=file] {
  display: none;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .custom-file-upload {
  border: 0.1vh solid #ccc;
  display: inline-block;
  padding: 0.6vh 1vh;
  border-radius: 0.5vh;
  cursor: pointer;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .custom-file-upload:hover {
  background: #eaeaea;
}
.dgw-container .sg-upload-certificates .sg-upload-cert-modal .custom-file-upload-disabled {
  border: 0.1vh solid #ccc;
  display: inline-block;
  padding: 0.6vh 1vh;
  border-radius: 0.5vh;
  color: #ccc;
  cursor: no-drop;
}
.dgw-container .nav-tabs a {
  color: #BF7400;
}
.dgw-container .nav-tabs a:focus {
  outline: none;
}
.dgw-container .flex-content {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}
.dgw-container .tooltiptext-center {
  left: -4vh !important;
}
.dgw-container .tooltiptext-filter {
  left: -3.5vh !important;
  margin-bottom: 0.8vh !important;
}
.dgw-container .tooltiptext-addnetwork {
  min-width: 16vh;
  left: -6vh !important;
  padding: 0.4vh 1vh;
  border-radius: 0.4vh;
}
.dgw-container .tooltiptext-close {
  left: -1vh !important;
  padding: 0.4vh 1vh;
  border-radius: 0.4vh;
}
.dgw-container .customTooltip {
  display: inline-block !important;
}
.dgw-container .pkey-modal .modal-content .modal-body {
  height: 20vh !important;
}
.dgw-container .pkey-modal .modal-content .modal-footer .cancel-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 0.5vh;
  background: #e9ecef;
  outline: none;
  font-size: 2vh;
}
.dgw-container .pkey-modal .modal-content .modal-footer .cancel-btn:hover {
  background: #d2d2d2;
}
.dgw-container .pkey-modal .modal-content .modal-footer .create-btn {
  padding: 0.6vh 1vw;
  color: #333;
  border: none;
  border-radius: 0.5vh;
  background: #d98c15;
  outline: none;
  font-size: 2vh;
}
.dgw-container .pkey-modal .modal-content .modal-footer .create-btn:hover {
  background: #BF7400;
}
.dgw-container .push-cert-modal {
  color: #333333 !important;
}
.dgw-container .push-cert-modal .modal-content .modal-body {
  padding: 1vh 4vh;
}
.dgw-container .push-cert-modal .modal-content .modal-body .section-title {
  padding-bottom: 1vh;
}
.dgw-container .push-cert-modal .modal-content .modal-body .margin-top {
  margin-top: 2vh;
}
.dgw-container .push-cert-modal .modal-content .modal-body .section-area {
  border: 1px solid rgb(208, 208, 208);
  height: 30vh;
  overflow-y: auto;
  overflow-wrap: break-word;
}
.dgw-container .push-cert-modal .modal-content .modal-body .section-area .padding-1-0 {
  padding: 1vh 0;
}
.dgw-container .push-cert-modal .modal-content .modal-footer .cancel-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 0.5vh;
  background: #e9ecef;
  outline: none;
  font-size: 2vh;
}
.dgw-container .push-cert-modal .modal-content .modal-footer .cancel-btn:hover {
  background: #d2d2d2;
}
.dgw-container .push-cert-modal .modal-content .modal-footer .create-btn {
  padding: 0.6vh 1vw;
  color: #333;
  border: none;
  border-radius: 0.5vh;
  background: #d98c15;
  outline: none;
  font-size: 2vh;
}
.dgw-container .push-cert-modal .modal-content .modal-footer .create-btn:hover {
  background: #BF7400;
}
.dgw-container .dgwDataTbl {
  min-height: 50vh;
  border: 1px solid #aeaeae;
}

