.events-details {
    padding: 0;
   .register-header {
        border: 1px solid #DDDDDD;
        margin-bottom:10px;
        padding: 0.7vh 0 .5vh 0;
        
        .time-filter {
            margin: 0 10px;
        }
        .event-filter {
            position: relative;
            display: inline-block;
        }
        .register-filter {
            .slide-switch {
                top:3px;
            }
        }
        .show-filter{
            position: absolute;
            width: 350px;
            top: 33px;
            right: 16px;
            background: #fff;
        } 
        .picky{
            width: 100%!important;
            white-space: nowrap;
            .picky__input{
                text-overflow: ellipsis;
            }
        }
        .mintomax{
            position: absolute;
            top: 50%;
            right: -0.5vh;
            font-size: 2vh;
            transform: translate(-50%, -50%);
        } 
        .dropdown-badge{
            .dropdown-menu{
                background: #fff!important;
                padding: 1.5vh!important;
            }
            .badge-position{ 
                position: absolute;
                background: #C53E3E;    
                top: -6px;
                width: 1.9vh;
                height: 1.9vh;
                padding: 0.12rem 0.38rem 0.12rem 0.38rem;
                right: 24px;
                border-radius: 50%;
                color: #fff;
                font-size: 1.3vh;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn-outline-secondary {
                color: #333 !important;
                border-color: #ccc !important;
                background: #fff !important;
                padding: 0.75vh 1vh;
                &:focus{
                  box-shadow: none !important;
                  background: none !important;
                }
                &:disabled{
                  color: #a5aaaf;
                  border-color: #ccc;
                }
                &:hover{
                  background: #e6e6e6 !important;
                  color: #333 !important;
                  border-color: #ccc !important;
                }
            }
            .btn-default{
                border-color: #ccc;
                &:hover{
                    background-color: #e6e6e6;
                    border-color: #adadad;
                }
                &:disabled{
                    border-color: #ccc;
                    border-radius: .25rem!important;
                    padding: .375rem .75rem;
                    &:hover{
                        background-color: #fff!important;
                    }
                  }
            }
        }
    }  
}
.events-table {    
    min-height: 80vh;
    max-height: 80vh;
    overflow: auto;
    .alink{
		color: #3b73af;
		&:hover{
			cursor:pointer;
			text-decoration: underline;
		}
    } 
    .ReactTable {
        .rt-thead .rt-th, 
        .rt-tbody .rt-td {
                border: none !important;
                text-align: left !important;
                color: #333;
                padding: 1.1vh !important;
            }
        .-striped .rt-tr.-odd {
            background: #F0EFEF;
        }
    }
}
