.site-order-modal{
    max-width: 90vw !important;
    .modal-header{
        .popup-title {
            position: relative;
        }
        .edit-lateral-name {
            display: inline-block;
            .lateral-name-text-wrapper {
                display: inline-block;
                .err-border-red {
                    border: 1px solid #a94442 !important;
                }
            }
            .lateral-name-error {
                color: #a94442;
                display: table-cell;
                font-weight: normal;
                font-size: 0.7vw;
            }
        }
        .fa-pencil-alt {
            cursor: pointer;
        }
        .fa-times-circle {
            cursor: pointer;
            margin-left: 10px;
            height: 1.2em;
            width: 1.2em;
        }
        .fa-check-circle {
            cursor: pointer;
            color: #bf7400;
            margin-left: 10px;
            height: 1.2em;
            width: 1.2em;
            &.disable {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
    .modal-body {
        min-height: 20vh;
        max-height: 70vh;
        overflow: hidden;
        overflow-y: auto;
        .site-list {
            padding: 5px;
            width: 100%;
            .site-row{
                background-color: #ffffff;
                border: none;
                margin: 0;
                padding: 7px 10px;
                &:nth-child(odd) {
                    background-color: rgba(0,0,0,0.03);
                }
                &.normal-open {
                    background-color: #00a877;
                }
                .site-name {
                    padding-top: 5px;
                    width: 15%;
                }
                .section-id {
                    input {
                        width: 75%;
                    }
                    width: 12%;
                }
                .current-direction {
                    width: 7%;
                }
                .cable-profile {
                    .dropdown {
                        width: 70%;
                    }
                    width: 10%;
                    .single-select-dropdown {  
                        .dropdown {
                            height: 1.9vw;
                            .dropdown-toggle{
                                height: 100%;
                                &[disabled]{
                                    color: black !important;
                                }
                            }
                        }
                    }
                }
                .cable-length {
                    input {
                        width: 75%;
                    }
                    .site-error-message {
                        padding-right: 2vw;
                    }
                    width: 11%;
                }
                .resistance-impedance {
                    input {
                        width: 85%;
                    }
                    width: 9.5%;
                }
                .reactance {
                    input {
                        width: 90%;
                    }
                    margin-left: 1.4vw;
                    width: 9%;
                }
                .no-of-customers {
                    input {
                        width: 100%;
                    }
                    margin-left: 1.8vw;
                    width: 18%;
                }
                input {
                    padding-left: 10px;
                }
                .site-error-message {
                    font-size: 0.7vw;
                }
        
                .fa-grip-vertical {
                    margin: 5px 10px 0 0;
                }
                .row-item {
                    &.disabled {
                        visibility: hidden;
                    }
                    &.err-border-red{
                        .dropdown-toggle, input {
                            border: 1px solid #a94442 !important;
        
                        }
                    }
                }
                .svg-node{
                    height:17px;
                    margin: 0.25vw 0.2vw 0 1vw;
                    width:17px;
                }
                .drag-icon-wrapper{
                    margin-left: -5px;
                    margin-right: 15px;
                    position: relative;
                    .drag-icon {
                        position: absolute;
                        &.fa-caret-up {
                            left: 5px;
                            width: 6px;
                        }
                        &.fa-grip-lines {
                            margin-top: 5px;
                            width: 17px;
                        }
                        &.fa-caret-down {
                            margin-top: 16px;
                            left: 5px;
                            width: 6px;
        
                        }
                    }
                }
            }
        }
        .col-item{
            font-weight: bold;
            &.site-name {
                margin-left: 2.7vw;
                width: 12%;
            }
            &.current-direction {
                width: 10.7%;
            }
            &.section-id {
                width: 11.7%;
            }
            &.cable-profile {
                width: 9.5%;
            }
            &.cable-length {
                margin-left: 5px;
                width: 8%;
            }
            &.phase-impedance {
                width: 20%;
            }
            &.resistance-impedance {
                margin-left: 2.5vw;
                width: 11%;
            }
            &.reactance {
                width: 10.8%;
            }
            &.no-of-customers {
                width: 17%;
            }
        }
    }
    
    .error-class {
        border: 1px solid red;
        border-radius: 2px;
    }
}
