.user-mngmnt .top-header {
  background: rgb(228, 227, 227);
  padding: 0.5vh 1vh;
  border: 0.1vh solid rgb(174, 174, 174);
}
.user-mngmnt .top-header .edit-btn {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0.4vw 0 0 0.4vw;
  border-right: none;
}
.user-mngmnt .top-header .edit-btn-disable {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0.4vw 0 0 0.4vw;
  border-right: none;
  background: #ececec;
  color: #8c8c8c;
  cursor: not-allowed !important;
}
.user-mngmnt .top-header .dlt-btn {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0 0.4vw 0.4vw 0;
}
.user-mngmnt .top-header .dlt-btn-disable {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0 0.4vw 0.4vw 0;
  background: #ececec;
  color: #8c8c8c;
  cursor: not-allowed !important;
}
.user-mngmnt .top-header .add-btn {
  background: #fff;
  padding: 0.5vh 1.5vh;
  border: 1px solid #ccc;
  color: #333;
  outline: none;
  border-radius: 0.4vw;
  margin-left: 0.2vw;
}
.user-mngmnt .top-header .add-btn:hover {
  background: #e6e6e6;
}
.user-mngmnt .top-header input[type=checkbox], .user-mngmnt .top-header input[type=radio] {
  margin: 0 0.5vw;
}
.user-mngmnt .top-header .dropdown-toggle {
  background: none !important;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}
.user-mngmnt .top-header .dropdown-toggle:focus {
  outline: none !important;
  border: none !important;
  background-color: none !important;
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.user-mngmnt .top-header .dropdown-toggle::before {
  display: none;
}
.user-mngmnt .top-header .btn-primary {
  background: none;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}
.user-mngmnt .top-header .btn-primary:focus {
  outline: none !important;
  border: none !important;
  background-color: none !important;
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.user-mngmnt .top-header .dropdown-menu {
  background: #EDEDED !important;
  width: 18vh;
  line-height: 3vh;
  top: 0.6vh !important;
}
.user-mngmnt .ReactTable {
  border: none !important;
}
.user-mngmnt .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  border-right: 1px solid #bdbbbb !important;
  max-width: 18vw !important;
  min-width: 9vw !important;
}
.user-mngmnt .ReactTable .rt-thead .rt-th:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}
.user-mngmnt .ReactTable .rt-tbody {
  overflow: hidden;
}
.user-mngmnt .ReactTable .rt-tbody .rt-td {
  white-space: unset !important;
  border-right: 0px solid #bdbbbb !important;
  border-top: 0px solid #bdbbbb !important;
}
.user-mngmnt .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  border-right: 1px solid #bdbbbb !important;
  max-width: 18vw !important;
  min-width: 9vw !important;
}
.user-mngmnt .ReactTable .rt-tbody .rt-td:first-child {
  min-width: 4vw !important;
  max-width: 4vw !important;
}

.modal .userModal {
  width: 35vw;
  max-width: 35vw;
}
.modal .userModal .modal-title {
  font-size: 2.5vh;
}
.modal .userModal .modal-content .modal-body {
  min-height: 30vh !important;
}
.modal .userModal .modal-content .modal-body .alert-gap {
  margin-bottom: 1.5vh;
}
.modal .userModal .modal-content .modal-body .tab-content {
  border: 0.1vh solid #dee2e6;
  border-top: none;
  padding-top: 2vh;
  min-height: 35.3vh;
  font-weight: bold;
}
.modal .userModal .modal-content .modal-body .tab-content input[type=text] {
  margin-bottom: 2vh;
  font-size: 1.5vh;
}
.modal .userModal .modal-content .modal-body .tab-content input[type=text]::placeholder {
  color: rgb(179, 179, 179);
}
.modal .userModal .modal-content .modal-body .tab-content input[type=password] {
  margin-bottom: 2vh;
  font-size: 1.5vh;
}
.modal .userModal .modal-content .modal-body .tab-content input[type=password]::placeholder {
  color: rgb(179, 179, 179);
}
.modal .userModal .modal-content .modal-body .tab-content .single-select-dropdown {
  margin-bottom: 2vh;
}
.modal .userModal .modal-content .modal-body .tab-content .form-right {
  margin-left: 2vw;
}
.modal .userModal .modal-content .modal-body .tab-content .form-row {
  display: -webkit-box;
}
.modal .userModal .modal-content .modal-footer .cancel-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: none;
  border-radius: 1vh;
  outline: none;
  font-size: 2vh;
  background: #fff;
}
.modal .userModal .modal-content .modal-footer .cancel-btn:hover {
  background: #d2d2d2;
}
.modal .userModal .modal-content .modal-footer .create-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 1vh;
  background: #d98c15;
  outline: none;
  font-size: 2vh;
}
.modal .userModal .modal-content .modal-footer .create-btn:hover {
  background: #BF7400;
}
.modal .changePasswordModal .modal-content .modal-body {
  min-height: 24vh !important;
}
.modal .nav-tabs {
  flex-direction: row !important;
}
.modal .nav-tabs a {
  color: #BF7400;
  padding: 1.2vh;
}
.modal .nav-tabs a:focus {
  outline: none;
}
.modal .aboutModal .modal-body {
  height: 18vh !important;
}
.modal .aboutModal .modal-body .title {
  font-size: 2vh;
  padding: 1vh 0px;
}

