.email-subscription-modal {
  max-width: 50vw !important;
  width: 100% !important;
}
.email-subscription-modal .modal-body .alert-message {
  height: 20vh;
}
.email-subscription-modal .modal-body .bottom-hints {
  padding: 3vh 1vh 1vh !important;
  font-size: 1.6vh;
}
.email-subscription-modal .modal-body .notification-main-title {
  margin-bottom: 0.8vh;
  font-weight: bold;
}
.email-subscription-modal .modal-body input[type=checkbox] {
  width: 2.2vh;
  height: 2.2vh;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree {
  height: 40vh;
  border: 0.1vh solid rgb(218, 216, 216);
  display: grid !important;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row {
  width: 100%;
  padding: 1.4vh 0vh;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row .rstcustom__rowLabel {
  width: 100%;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row .rstcustom__rowLabel span {
  cursor: pointer;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row .plusMinusIcon {
  font-size: 1.7vh;
  color: rgb(51, 51, 51);
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row .typeIcon {
  padding-left: 1.2vh;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__row .tree-checkbox {
  position: absolute;
  right: 0;
  top: 0;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .rstcustom__rowToolbar .tree-wth-checkbox {
  cursor: pointer;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .WcheckBox {
  padding-right: 2vh;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .ReactVirtualized__List {
  padding-right: 1vh;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .ReactVirtualized__List::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #e5e5e5;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .ReactVirtualized__List::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.email-subscription-modal .modal-body .region-substation-container .region-substation-tree .ReactVirtualized__List::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.email-subscription-modal .notification-type-container .notification {
  border: 1px solid rgb(218, 216, 216);
  padding: 0 1.4vh;
  height: 40vh;
  overflow-y: scroll;
}
.email-subscription-modal .notification-type-container .notification::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #e5e5e5;
}
.email-subscription-modal .notification-type-container .notification::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.email-subscription-modal .notification-type-container .notification::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.email-subscription-modal .notification-type-container .notification .notification-title {
  padding-top: 1vh;
  font-weight: bold;
}
.email-subscription-modal .notification-type-container .notification .data-row {
  padding: 0.4vh 0 0.6vh 1.6vh;
}
.email-subscription-modal .notification-type-container .notification .star {
  padding-right: 1vh;
  line-height: 1.4vh;
  margin-top: 1vh;
  font-size: 2.5vh;
}
.email-subscription-modal .modal-footer .cancel-btn {
  padding: 0.5vh 1vw;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 0.5vh;
  background: #e9ecef;
  outline: none;
  font-size: 2vh;
}
.email-subscription-modal .modal-footer .cancel-btn:hover {
  background: #d2d2d2;
}
.email-subscription-modal .modal-footer .create-btn {
  padding: 0.6vh 1vw;
  color: #333;
  border: none;
  border-radius: 0.5vh;
  background: #d98c15;
  outline: none;
  font-size: 2vh;
}
.email-subscription-modal .modal-footer .create-btn:hover {
  background: #BF7400;
}

