@mixin fileInputArea{
      overflow: hidden;
      // position: relative;
      margin-bottom: 1vh;
      border-radius: 3px;
      display: inline-block;
      padding: 0.5vh 1vh;
}
@mixin inputTypeFile{
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.5vh 1vh 0vh;
        width: 100%;
        height : 80% ;
        font-size: 0!important;
}
.lic-accord.card-header:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  right: 2vh;
  top: 2vh;
}

.license-management{
  // margin-top: -3vh!important;
  .custom-file-inputs{
    @include fileInputArea;
    border: 0.1vh solid #c5c5c5;
    cursor: pointer!important;
      &:hover{
        background: #eaeaea;
      }
      
      input[type=file] {
        @include inputTypeFile;
        cursor: pointer!important;
      }
  }
  .disabled-file-inputs{
      @include fileInputArea;
      border: 0.1vh solid #e9e9e9;
      cursor: not-allowed;

      input[type=file] {
        @include inputTypeFile;
        cursor: not-allowed;
      }
  }
  
  .tooltip-btn{
    padding-top: 1vh;
  }
  .input-icon-color{
    color: #636363;
  }
  .disabled-input-icon-color{
    color: #c3c3c3;
  }

  .reload-text-color{
    color: #BF7400;
  }
  .licenceMgntTbl{
    min-height: 15vh;
    border: 1px solid #aeaeae;
  }
  .tooltiptext-center{
    width: 12vh;
    left: -8vh!important;
    padding: 0.3vh;
  }
  .tooltiptext{
    right: 0vh!important;
    bottom: 120%;
    &::after{
      left: 80%;
    }
  }
  .colorRed {
    color: red;
  }
  .colorBlue {
    color: blue;
  }
  .colorGreen {
    color: green;
  }
    .ReactTable{
      border: none!important;
      .rt-thead{
          .rt-th{
              border-right: 0.1vh solid #bdbbbb!important;
              &:nth-last-child(1){
                // border-right: 0px solid #bdbbbb!important;
            }
          }
      }
      .rt-tbody{
          .rt-td{
              border-right: 0px solid #bdbbbb!important;
              border-top: 0px solid #bdbbbb!important;
              &:nth-last-child(1){
                border-right: 0.1vh solid #bdbbbb!important;
            }
          }
      }
  }
}