.dashboard .watchlist-table-height {
  height: 4.8vh !important;
}
.dashboard .breadcrumb-background {
  background: #f0efef;
  border: 0.1vh solid #E7E4E4;
  font-size: 1.75vh;
  height: 6.19vh;
}
.dashboard .breadcrumb-background .pad-left-top {
  padding-left: 1vh !important;
  padding-top: 0.2vh;
}
.dashboard .breadcrumb-background .bubble-chart-bredcrumb {
  font-size: 1.44vh;
  color: #777;
  line-height: 1.5vh;
}
.dashboard .breadcrumb-background .justify-content-right {
  justify-content: flex-end;
}
.dashboard .breadcrumb-background .dashboard-chart-timeFilter {
  text-align: right;
  margin-right: -1.2vh !important;
}
.dashboard .breadcrumb-background .dashboard-chart-timeFilter .time-filter-btn {
  padding: 0.3vh 0.8vh !important;
}
.dashboard .breadcrumb-background .dashboard-chart-timeFilter .single-select {
  padding: 0.9vh !important;
}
.dashboard .breadcrumb-background .tooltiptext-center {
  width: max-content;
  font-size: 1.3vh !important;
  left: -4.8vh !important;
  padding: 0.4vh 0.95vh;
}
.dashboard .breadcrumb-background .setting-icon {
  margin-right: 1vh;
}
.dashboard .chart-Section {
  border: 0.2vh solid #E7E4E4;
  height: 47vh;
  position: relative;
}
.dashboard .chart-Section .highcharts-container {
  height: 46.66vh !important;
}
.dashboard .chart-Section .set-width-height {
  width: 100%;
  height: 100%;
  position: absolute;
}
.dashboard .top-button {
  padding: 5px 10px;
  border-radius: 5px;
  background: #fff;
}
.dashboard .top-button:hover {
  background-color: #d98c14;
  border: 0.1vh solid #bf7400;
}
.dashboard .top-btn-treemap {
  position: absolute;
  top: 7%;
  right: 3%;
  z-index: 9;
}
.dashboard .ReactTable {
  width: 100% !important;
}
.dashboard .ReactTable .rt-thead {
  width: 99.99%;
}
.dashboard .ReactTable .rt-tbody {
  max-height: 26vh;
  overflow-x: hidden !important;
  width: 99.99%;
}
.dashboard .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 0.8vh !important;
  background: #ccc;
}
.dashboard .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 20px;
}
.dashboard .ReactTable .rt-tbody::-webkit-scrollbar-button:single-button {
  height: 0.5vh;
}
.dashboard .ReactTable .rt-tbody .rt-td {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard .ReactTable .clm-style {
  color: #BF7400;
  cursor: pointer;
}
.dashboard .manageWatchlist {
  min-height: 35vh;
  border: 0.1vh solid #E7E4E4;
}
.dashboard .manageWatchlist .center-title {
  display: flex;
  align-items: center;
}
.dashboard .manageWatchlist .table-section .ReactTable .rt-thead .rt-th:nth-last-child(1) {
  width: 10.3% !important;
}
.dashboard .manageWatchlist .table-section .ReactTable .rt-tbody .rt-td:nth-last-child(1) {
  width: 10% !important;
}
.dashboard .eventlogTable .ReactTable .rt-thead .rt-th:nth-child(4) {
  max-width: 11.4vw !important;
}
.dashboard .eventlogTable .ReactTable .rt-tbody .rt-td:nth-child(4) {
  max-width: 11.6vw !important;
}
.dashboard .eventlogChild .ReactTable .rt-tbody .rt-td:nth-child(4) {
  max-width: 10.5vw !important;
}
.dashboard .add-feeders {
  padding: 0.1vh 2vh;
  color: #555;
  background-color: #eee;
  font-size: 2.2vh;
  border-radius: 0.8vh;
  border: 1px solid #ccc;
  outline: none;
  opacity: 0.9;
  width: auto;
}
.dashboard .add-feeders:hover {
  border: 0.1vh solid #aeaeae;
  background-color: #e4e4e4;
}
.dashboard .option-list .dropdown-menu {
  width: auto !important;
}

.modal .modal-dialog {
  margin: 1.7vh auto !important;
}
.modal .custom-dialog-watchlist-modal {
  width: 35vw;
  max-width: 42vw;
}
.modal .custom-dialog {
  width: 98vw;
  max-width: 98vw;
}
.modal .custom-dialog .modal-content {
  min-height: 40vh !important;
}
.modal .header-title {
  font-size: 1.85vh;
}
.modal .popup-bread-crumb {
  color: #777;
}
.modal .popup-chart {
  position: relative;
  min-height: 20vh;
}
.modal .watchlist-data-area {
  padding: 0 12vh;
}
.modal .watchlist-data-area .manage-watchlist {
  padding-top: 1vh;
  border: 0.1vh solid rgb(218, 218, 218);
  height: 35vh;
  display: grid;
}
.modal .watchlist-data-area .manage-watchlist .checkbox-tree .rstcustom__rowToolbar .watchListItemIcon {
  cursor: pointer;
}
.modal .watchlist-data-area .manage-watchlist .checkbox-tree .rstcustom__rowLabel span {
  cursor: pointer;
}
.modal .cancel-btn {
  padding: 0.5vh 1.5vh !important;
  border-radius: 5px !important;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 1.44vh !important;
  margin-right: 10px;
}
.modal .cancel-btn:hover {
  background: #ccc;
}
.modal .save-btn {
  padding: 0.5vh 1.5vh;
  border-radius: 5px;
  background: #d98c14;
  border: 1px solid #ccc;
}
.modal .save-btn:hover {
  background: #bf7400;
}
.modal hr {
  border-width: 0.76vh;
}

