.fault-analysis {
    overflow: hidden;
    min-height: 30vh;
    &.secondary-navbar {
        .navbar-nav {
            margin-left: 25vw;
        }
    }
    .events-details {
        .blank-page {
            span {
                left: 50%;
            }
        }
    }
    .load-more {
        background-color: #efefef;
        border: none;
        color: #3b73af;
        font-size: 12px;
        padding: 18px;
        &:hover {
            text-decoration: underline;
        }
    }
}