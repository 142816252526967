.manage-classAssignment {
  margin-top: 1vh;
}
.manage-classAssignment .nav-tabs a {
  color: #bf7400 !important;
}
.manage-classAssignment .nav-tabs a:hover {
  color: #bf7400 !important;
  background-color: #eee !important;
}
.manage-classAssignment .nav-tabs .nav-link.active {
  color: #555 !important;
  cursor: default !important;
  background-color: #fff !important;
  border-bottom-color: transparent !important;
}
.manage-classAssignment .tab-content {
  padding-top: 1.5vh;
  border: 0.1vh solid #dee2e6;
  border-top: none;
  padding-bottom: 2vh;
  height: 42vh;
}
.manage-classAssignment .tab-content .txtarea-wid {
  resize: none;
  cursor: text;
  line-height: 2vh !important;
}
.manage-classAssignment .tab-content .txtarea-wid:disabled {
  background-color: #f9f9f9;
}
.manage-classAssignment .tab-content .modalCancelBtn:disabled {
  pointer-events: none;
}
.manage-classAssignment .tab-content .loader-wrap {
  min-height: 50vh;
}
.manage-classAssignment .align-alert-center {
  position: absolute !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
}
.manage-classAssignment .no-disp-text {
  line-height: 40vh !important;
}

