.notification-templates-modal{
  min-width: 58vw!important;
  max-width: 58vw!important;
  .modal-content{
    .modal-body{
      .nt-template-area{
        margin-top: 1.5vh;
        .template-action{
          display: flex;
          background: rgb(240, 239, 239);
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0.5vh 1vh;
          .each-btn-gap{
            margin-left: 0.3vh;
          }
          .word-break-all{
            word-break: break-all;
          }
        }
        .template-form-area{
          padding: 1vh 15vh;
          .template-form-each-row-gap{
            margin-bottom: 1vh;
          }
          .tag-variable-container{
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: rgba(0, 0, 0, 0.176) 0px 0.9vh 1.8vh;
            position: absolute;
            z-index: 99999;
            background: rgb(255, 255, 255);
            width: 93%;
          }
        }
      }

    .option-list{
        .dropdown-menu {
          min-width: fit-content!important;
        }
      }

    .flex-center{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .border-color{
      border: 0.1vh solid #F0EFEF;
    }
    .disabledBtn{
      background: #f7f7f7!important;
      color: #333!important;
      border: 1px solid #cccccc!important;
      cursor: not-allowed!important;
    }
    textarea{
      line-height: 2.3vh!important;
    }
    .modalCancelBtn{
      padding: 0.7vh 0.8vh;
    }

    .single-select-dropdown{
      .dropdown{
        .dropdown-menu{
          .dropdown-item{
            display: flex;
            align-items: center;
            span:nth-child(2){
              white-space: initial;
            }
            .float-right{
              margin-left: auto !important;
            }
          }
        }
      }
    }
    }
  }
  .fa-check-circle {
    color: #75C53E;
    font-size: 1.9vh;
  }
   .form-control:disabled {
     opacity: 0.7 !important;
   }
}

.notification-validate-modal{
  .modal-body{
    min-height: 18vh!important;
  }
}