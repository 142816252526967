.disturbances {
  .content-right {
      .date-input-field{
          width: 80%!important;
        }
      .disturbance-filter{
          .formatDate{
              border-radius: 0.3vh 0 0 0.3vh;
              width: 100% !important;
              padding: 0.4vh;
              border: 0.1vh solid #ccc;
              cursor: not-allowed;
              background-color: #eee;
              height: 3.4vh;
            }
        
            .date-icon{
              border: 0.1vh solid rgb(204, 204, 204);
              border-left: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 0 0.3vh 0.3vh 0;
              padding: 0.55vh 0;
              height: 3.4vh;
              &:hover{
                background: #DDDDDD;
              }
            }
            .icon-width{
              width: 20%!important;
            }

            .apply-btn-area{
              display: flex;
              // align-items: center;
              justify-content: flex-end;
              .apply-btn{
                padding: 0vh 1.5vh;
                height: 3.4vh;
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 0.5vh;
                outline: none;
                &:hover{
                  background: #ddd;
                  border: 0.1vh solid #ccc;
                }
              }
            }
      }
      .option-list{
          .single-select {
            width: auto!important;
          }
          .dropdown-menu {
              width: auto!important;
            }
        }
        .phase-size{
          width: 1.4vh;
          height: 1.4vh;
          display: inline-flex;
          margin: 0 0.5vh;
        }

        .tab-content{
          border: 0.1vh solid #ddd;
          border-top: none;
          padding: 1.2vh;
          .counters-view-height{
            min-height: 8vh;
            .loader-wrap {
              position: relative;
              min-height: 25vh;
            }
          }
          .ReactTable {
            .rt-th {
              &:first-child {
                min-width: none !important;
                max-width: none !important;
              }
            }
            .rt-td {
              &:first-child {
                min-width: none !important;
                max-width: none !important;
              }
            }
          }
          .column-center-align {
            display: flex;
            align-items: center;
            justify-content: center;
          }
      }
      .nav-tabs {
        flex-direction: row!important;
        a {
            color: #BF7400!important;
            &:focus {
                outline: none;
            }
        }
        .active{
          color: #495057!important;
        }
    }
    .center-alighn-text{
      display: flex;
      align-items: center;
    }
  }
  .highcharts-column-series{
    opacity: 1 !important;
  }
  .inline-block{
    display: inline-block;
  }
  .bar-chart-tooltip {
    table {
      width: 100%;

      tr td {
        padding: 3px 5px;
        border: solid 1px #bdbbbb;
      }
    }
  }  
  .chart-stack-Label-top{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-transform: rotate(270deg) translateX(50%) !important;
    -webkit-transform: rotate(270deg) translateX(50%) !important;
    transform: rotate(270deg) translateX(50%) !important;
    color: #5b3d3d;
    font-size: 1.4vh !important;
  }
  .disturbance-chart {
    .highcharts-container{
      height: 55vh !important;
      width: 100% !important;
    }
    .set-width-height{
      width: 100%;
      height: 100%;
    }
  }
}