@mixin btn{
        background: #fff;
        padding: 0.5vh 1.5vh;
        border: 1px solid #ccc;
        color: #333;
        outline: none;
  }

.user-mngmnt{
    // margin-top: -3vh!important;
    .top-header{
        background: rgb(228, 227, 227);
        padding: 0.5vh 1vh;
        border: 0.1vh solid rgb(174, 174, 174);
        .edit-btn{
            @include btn;
            border-radius: 0.4vw 0 0 0.4vw;
            border-right: none;
        }
        .edit-btn-disable{
            @include btn;
            border-radius: 0.4vw 0 0 0.4vw;
            border-right: none;
            background: #ececec;
            color: #8c8c8c;
            cursor: not-allowed!important;
        }
        .dlt-btn{
            @include btn;
            border-radius: 0 0.4vw 0.4vw 0;
        }
        .dlt-btn-disable{
            @include btn;
            border-radius: 0 0.4vw 0.4vw 0;
            // border-right: none;
            background: #ececec;
            color: #8c8c8c;
            cursor: not-allowed!important;
        }
        .add-btn{
            @include btn;
            border-radius:0.4vw;
            margin-left: 0.2vw;
            &:hover{
                background: #e6e6e6;
            }
        }
        input[type=checkbox], input[type=radio] {
            margin: 0 .5vw;
        }
        //for dropdown menu customization css start
        .dropdown-toggle{ 
            background :none !important;
            padding: 0!important;
            outline: none!important;
            border: none!important;
            &:focus{
                outline: none!important;
                border: none!important;
                background-color: none!important;
                background: none!important;
                padding: 0!important;
                box-shadow: none!important;
            }
            &::before{
                display: none;
            }
        }
        .btn-primary{
            background :none;
            padding: 0!important;
            outline: none!important;
            border: none!important;
            &:focus{
                outline: none!important;
                border: none!important;
                background-color: none!important;
                background: none!important;
                padding: 0!important;
                box-shadow: none!important;
            }
        }
        .dropdown-menu{
            background: #EDEDED!important;
            width: 18vh;
            // padding: 10px;
            // font-size: 2vh;
            line-height: 3vh;
            top: 0.6vh!important;
        }
        //for dropdown menu customization css end
    }
    .ReactTable{
        border: none!important;
        
        .rt-thead{
            .rt-th{
                // border-right: 1px solid #bdbbbb!important;
                &:nth-last-child(1){
                    border-right: 1px solid #bdbbbb!important;
                    max-width: 18vw!important;
                    min-width: 9vw!important;
                }
                &:first-child{
                    min-width: 4vw!important;
                    max-width: 4vw!important;
                  }
            }
        }
        .rt-tbody{
            .rt-td{
                white-space: unset!important;
                border-right: 0px solid #bdbbbb!important;
                border-top: 0px solid #bdbbbb!important;
                &:nth-last-child(1){
                    border-right: 1px solid #bdbbbb!important;
                    max-width: 18vw!important;
                    min-width: 9vw!important;
                }
                &:first-child{
                    min-width: 4vw!important;
                    max-width: 4vw!important;
                  }
            }
            overflow: hidden;
        }
    }
    // .sort-number{
    //     height: 2.5vh;
    //     width: 2.5vh;
    //     display: inline-block;
    //     border-radius: 50%;
    //     background: #b1b1b1;
    //     color: rgb(255, 255, 255);
    //     font-size: 1.7vh;
    //     padding: 0.3vh;
    //     // font-weight: normal;
    // }
}
.modal{
    .userModal{
        width: 35vw;
        max-width: 35vw;
        .modal-title{
            font-size: 2.5vh;
        }
        .modal-content{
            .modal-body{
                min-height: 30vh!important;
                .alert-gap{
                    margin-bottom: 1.5vh;
                }
                .tab-content{
                    // font-size: 1.7vh;
                    border: 0.1vh solid #dee2e6;
                    border-top: none;
                    padding-top: 2vh;
                    min-height: 35.3vh;
                    font-weight: bold;
                    & input[type=text]{
                        margin-bottom: 2vh;
                        font-size: 1.5vh;
                        &::placeholder{
                            color: rgb(179, 179, 179);
                        }
                    }
                    & input[type=password]{
                        margin-bottom: 2vh;
                        font-size: 1.5vh;   
                        &::placeholder{
                            color: rgb(179, 179, 179);
                        }
                    }
                    .single-select-dropdown {
                        margin-bottom: 2vh;
                    }
                    & select{
                        // font-size: 2vh;
                    }
                    .form-right{
                        margin-left: 2vw;
                    }
                    .form-row{
                        display: -webkit-box;
                    }
                }
            }
            .modal-footer{
                .cancel-btn{
                    padding: 0.5vh 1vw;
                    color: #333;
                    border: none;
                    border-radius: 1vh;
                    outline: none;
                    font-size: 2vh;
                    background: #fff;
                    &:hover{
                        background: #d2d2d2;
                    }
                }
                .create-btn{
                    padding: 0.5vh 1vw;
                    color: #333;
                    border: 1px solid #ccc;
                    border-radius: 1vh;
                    background: #d98c15;
                    outline: none;
                    font-size: 2vh;
                    &:hover{
                        background: #BF7400;
                    }
                }
            }
        }
    } 
    .changePasswordModal{
        .modal-content{
            .modal-body{
                min-height: 24vh!important;
            }
        }
    }
    
    .nav-tabs {
        flex-direction: row!important;
        a {
            color: #BF7400;
            padding: 1.2vh;
            &:focus {
                outline: none;
            }
        }
    }
    .aboutModal{
        .modal-body {
            height: 18vh!important;
            .title{
                font-size: 2vh;
                padding: 1vh 0px;
            }
        }
    }
}
