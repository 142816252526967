@mixin customScrolbar{
  &::-webkit-scrollbar {
    width: 0.8vh!important;
    background: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    background:#9e9e9e; 
    border-radius: 20px;
  }
  &::-webkit-scrollbar-button {
    &:single-button {
      height: 0.5vh;
    }
  }
}
@mixin watchlistTable{
  &:first-child, &:nth-child(2), &:nth-child(3){
    max-width: 10vw!important;
  }
  &:nth-child(4), &:nth-child(5){
    max-width: 20vw!important;
  }
  &:nth-child(6), &:nth-child(7), &:nth-child(8){
    max-width: 10vw!important;
  }
}
.dashboard {
  .watchlist-table-height {
    height: 4.8vh !important;
  }
  .breadcrumb-background{
    background: #f0efef;
    border: 0.1vh solid #E7E4E4;
    font-size: 1.75vh;
    height: 6.19vh;

    .pad-left-top {
      padding-left: 1vh !important;
      padding-top: 0.2vh;
    }

    .bubble-chart-bredcrumb{
      font-size: 1.44vh;
      color: #777;
      line-height: 1.5vh;
    }

    .justify-content-right{
      justify-content: flex-end;
    }

    .dashboard-chart-timeFilter{
      text-align: right;
      margin-right: -1.2vh!important;
      .time-filter-btn{
        padding: 0.3vh 0.8vh!important;
      }
      .single-select{
        padding: 0.9vh !important;
      }
    }
    .tooltiptext-center{
        width: max-content;
        font-size: 1.3vh !important;
        left: -4.8vh !important;
        padding: 0.4vh 0.95vh;
    }
    .setting-icon{
      margin-right: 1vh;
    }
  }
  .chart-Section{
    border: 0.2vh solid #E7E4E4;
    height: 47vh;
    position: relative;
    .highcharts-container{
      height: 46.66vh!important
    }
    .set-width-height{
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .top-button{
    padding: 5px 10px;
    border-radius: 5px;
    background: #fff;
  }

  .top-button:hover{
    background-color: #d98c14;
    border: 0.1vh solid #bf7400;
  }

  .top-btn-treemap{
    position: absolute; top: 7%; right: 3%; z-index: 9;
  }

  // .eventLogTable{
  //   border: 1px solid #e5e5e5;
  // }
  
  .ReactTable{
    width: 100%!important;
    .rt-thead{
      width: 99.99%;
    }
    .rt-tbody{
      max-height: 26vh;
      @include customScrolbar;
      overflow-x: hidden !important;
      width: 99.99%;
      .rt-td{
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .clm-style{
      color: #BF7400;
      cursor: pointer
    }
  }

  //for watchlist container react table
  .manageWatchlist{
      min-height: 35vh;
      border: 0.1vh solid #E7E4E4;
    .center-title{
      display: flex;
      align-items: center;
    }
    .table-section {
      .ReactTable{
        .rt-thead{
          .rt-th{
            &:nth-last-child(1){
              width: 10.3%!important;
            }
          }
        }
        .rt-tbody{
          .rt-td{
            &:nth-last-child(1){
              width: 10%!important;
            }
          }
        }
      }
    }
  }

  .eventlogTable{
    .ReactTable{
      .rt-thead{
        .rt-th{
          &:nth-child(4){
            max-width: 11.4vw!important;
          }
        }
      }
      .rt-tbody{
        .rt-td{
          &:nth-child(4){
            max-width: 11.6vw!important;
          }
        }
      }
    }
  }
  .eventlogChild{
    .ReactTable{
      .rt-tbody{
        .rt-td{
          &:nth-child(4){
            max-width: 10.5vw!important;
          }
        }
      }
    }
  }
  .add-feeders{
    padding: 0.1vh 2vh;
    color: #555;
    background-color: #eee;
    font-size: 2.2vh;
    border-radius: 0.8vh;
    border: 1px solid #ccc;
    outline: none;
    opacity: 0.9;
    width: auto;
    &:hover{
      border: 0.1vh solid #aeaeae;
      background-color: #e4e4e4;
    }
  }
  .option-list{
    .dropdown-menu {
      width: auto!important;
    }
  }
}



// modal
.modal{
  .modal-dialog{
    margin: 1.7vh auto !important;
  }
  .custom-dialog-watchlist-modal{
    width: 35vw; 
    max-width: 42vw; 
  }
  .custom-dialog {
    width: 98vw; 
    max-width: 98vw;
    .modal-content{
        min-height: 40vh !important;
      }
  }
  .header-title{
    font-size: 1.85vh;
  }
  .popup-bread-crumb{
    color: #777;
  }
  .popup-chart{
    position: relative;
    min-height: 20vh;
  }
  .watchlist-data-area{
    padding: 0 12vh;
    .manage-watchlist{
      padding-top: 1vh;
      border: 0.1vh solid rgb(218, 218, 218);
      height: 35vh;
      display: grid;
      .checkbox-tree {
        .rstcustom__rowToolbar .watchListItemIcon{
          cursor: pointer;
        }
        .rstcustom__rowLabel span{
          cursor: pointer;
        }
      }
    }
  }
  .cancel-btn{
    padding: .5vh 1.5vh !important;
    border-radius: 5px !important;
    background: #fff;
    border: 1px solid #ccc;
    font-size: 1.44vh !important;
    margin-right: 10px;
    &:hover{
      background: #ccc;
    }
  }
  .save-btn{
    padding: .5vh 1.5vh;
    border-radius: 5px;
    background: #d98c14;
    border: 1px solid #ccc;
    &:hover{
      background: #bf7400;
    }
  }
  hr {
    border-width: 0.76vh;
  }

  
}